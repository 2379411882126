import mainApi from "./mainApi";
import baseConfigApi from "./baseConfigApi";
import userConfigApi from "./userConfigApi";
import deviceConfig from "./deviceConfig";

export default [
  ...mainApi,
  ...baseConfigApi,
  ...userConfigApi,
  ...deviceConfig,
];
