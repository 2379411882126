<template>
  <div class="ml-tabs">
    <ul class="ml-tabs__box">
      <ml-button
        v-for="(item, index) in showOptions"
        :key="'tab' + index"
        @handleClick="changeItem(item)"
        :type="`${value === item[useProps.valueKey] ? 'primary' : 'default'}`"
        :disabled="useDisabled"
        :class="{ 'is-active': value === item[useProps.valueKey] }"
      >
        {{ item[useProps.labelKey] }}
      </ml-button>
    </ul>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref, watch, computed } from "vue";

const emits = defineEmits([
  "update:modelValue",
  "update:showLabel",
  "change",
  "changeBefore",
  "callTabsOptions",
]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean],
    default: "",
  },
  // 标签名
  showLabel: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
  props: {
    type: Object,
    default: () => ({
      labelKey: "label",
      valueKey: "value",
    }),
  },
  // 字典 动态类型
  activeType: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

const showOptions = ref(props.options);

const useProps = ref(props.props);

const value = ref(props.modelValue);
// 当前使用的 类型
const nowUseType = computed(() => props.activeType);
// 使用的禁用状态
const useDisabled = computed(() => props.disabled);

/* 当前页面路由名 */
const currentRouteName = computed(() => proxy.$route.name);
/* 当前页面下的 选项卡缓存名称 */
const changeTabStoreName = computed(
  () => `${currentRouteName.value}__changeTab`
);

watch(
  () => props.options,
  (nVal) => {
    showOptions.value = nVal;
  }
);
watch(
  () => props.modelValue,
  (nVal) => {
    // console.log(props.options);
    // console.log(nVal);
    // console.log(useProps.value.valueKey);
    value.value = nVal;
  }
);
watch(
  () => value.value,
  (nVal) => {
    const label = proxy.$getOptsItem(value.value, showOptions.value, {
      valueKey: useProps.value.valueKey,
      returnKey: useProps.value.labelKey,
    });
    // console.log(label);
    emits("update:modelValue", nVal);
    emits("update:showLabel", label);
  }
);

// 选择
const changeItem = (item) => {
  // 选项值更改之前
  emits("changeBefore", value.value);

  const tabValue = item[useProps.value.valueKey];
  value.value = tabValue;

  // 选择的选项卡 缓存到本地
  // 数据类型
  const type = typeof tabValue;
  // 缓存数据
  const storeData = {
    type,
    value: tabValue,
  };
  const json_data = JSON.stringify(storeData);
  proxy.$setItem(changeTabStoreName.value, json_data);

  setTimeout(() => {
    emits("change", value.value);
  }, 150);
};

// 查询 字典列表
const getActiveTypeNameLists = async () => {
  try {
    showOptions.value = await proxy.$getActiveTypeNameLists(nowUseType.value);

    if (!value.value) {
      // 赋值初始
      const [first] = showOptions.value;
      if (first) {
        value.value = first[useProps.value.valueKey];
      }
      emits("callTabsOptions", showOptions.value);
    }
  } catch (error) {}
};

/* 初始获取本地缓存 选项卡数据 */
const getStoreageChangeTabData = () => {
  const getStoreageData = proxy.$getItem(changeTabStoreName.value);
  //  赋值 本地缓存值
  if (getStoreageData) {
    const data = JSON.parse(getStoreageData);
    const { type } = data;

    let result = data.value;
    if (type == "number") {
      result = Number(data.value);
    }

    value.value = result;
  } else {
    const [first] = showOptions.value;
    value.value = first[useProps.value.valueKey];
  }
};

// 初始
const init = async () => {
  await getActiveTypeNameLists();
};

onMounted(() => {
  // if (props.activeType) {
  //   init();
  // }
  getStoreageChangeTabData();
});
</script>

<style lang="scss">
.ml-tabs {
  width: 100%;
  // padding: 0 15px;
  box-sizing: border-box;
  padding-left: 4px;
  padding-top: 4px;
  &__box {
    width: 100%;
    display: flex;

    .is-active {
      box-shadow: 0 0 5px 0px #3874ff;
    }

    .el-button {
      width: 100px;
    }

    // &-item {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   padding: 9px 20px;
    //   width: fit-content;
    //   border-radius: 5px;
    //   background-color: white;
    //   font-size: 16px;
    //   cursor: pointer;
    //   transition: 300ms;
    //   user-select: none;
    //   margin-left: 10px;
    //   &:first-child {
    //     margin-left: 0;
    //   }

    //   &:hover {
    //     opacity: 0.8;
    //   }

    //   &.is-active {
    //     background-color: var(--el-color-primary);
    //     color: white;
    //   }
    // }
  }
}
</style>
