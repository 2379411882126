<template>
  <div ref="ofRef" class="of" v-if="showCurrentTemplate">
    <el-dropdown
      ref="dropdownRef"
      trigger="click"
      popper-class="btn-popper"
      :hide-on-click="true"
      @command="onCommand"
    >
      <auth-button type="primary">更多</auth-button>

      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>
            <div class="el-dropdown-item-box">
              <slot />
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, computed, nextTick } from "vue";
import { usePageFunctionAuthModule } from "@/utils/mixin";

const { proxy } = getCurrentInstance();

const ofRef = ref();
const dropdownRef = ref();
const props = defineProps({});

// 显示当前组件
const showCurrentTemplate = computed(() => {
  const slots = proxy.$slots["default"]();

  // 禁用组
  const isDisableds = slots.map((d) => {
    if (d.props) {
      const { isDisabled } = usePageFunctionAuthModule(d.props);
      return isDisabled.value;
    } else {
      return false;
    }
  });

  // 全部为false
  const isAllHaveAuth = !isDisableds.filter((d) => d).length;

  return isAllHaveAuth;
});

// 点击菜单项触发的事件回调
const onCommand = (e) => {
  nextTick(() => {
    handleClose();
  });
};

// 关闭下拉
const handleClose = () => {
  // console.log("回调 ");
  dropdownRef.value.handleClose();
};

defineExpose({
  onCommand,
});
</script>

<style lang="scss" scoped>
.of {
  display: flex;
  .el-dropdown {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}

.el-dropdown-menu {
  position: relative;
  top: 0;
  left: 0;
  z-index: var(--el-dropdown-menu-index);
  padding: 10px 15px;
  margin: 0;
  background-color: var(--el-bg-color-overlay);
  border: none;
  border-radius: var(--el-border-radius-base);
  box-shadow: none;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  :deep(.el-dropdown-menu__item) {
    display: flex;
    align-items: center;
    margin: 6px 0;
    white-space: nowrap;
    list-style: none;
    line-height: 22px;
    padding: 5px 16px;
    margin: 0;
    font-size: var(--el-font-size-base);
    color: var(--el-text-color-regular);
    cursor: pointer;
    outline: 0;
    justify-content: center;
    // &.is-disabled {
    //   cursor: not-allowed;
    //   color: var(--el-text-color-disabled);
    // }
  }
}
</style>

<style lang="scss">
.btn-popper {
  // z-index: 0 !important;
  .auth-button {
    margin-left: 0px !important;
    margin: 5px 0;
  }

  .el-dropdown-menu__item {
    background: white !important;

    .el-dropdown-item-box {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
