<template>
  <div
    :class="`ml-input ${size} ${mlClassName} ${
      hasSlot('append') ? 'is-has-append' : ''
    } ${hasSlot('prepend') ? 'is-has-prepend' : ''} ${
      hasSlot('el-prepend') ? 'is-has-before' : ''
    } ${isNowFocus ? 'is-now-focus' : ''}`"
  >
    <!-- 前追加 -->
    <div class="ml-input__prepend" v-if="hasSlot('prepend')">
      <slot name="prepend"> </slot>
    </div>
    <el-input
      v-model="value"
      :value="value"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      @keydown="onKeydom"
      :class="className"
      :type="inputType"
      :placeholder="usePlaceholder"
      :maxlength="maxlength"
      :disabled="useDisabled"
      :clearable="clearable"
      :size="size"
      :show-word-limit="showWordLimit"
      :autosize="autosize"
      :rows="rows"
      :show-password="isPassword"
      :autofocus="autofocus"
    >
      <template v-if="hasSlot('el-suffix')" #suffix>
        <slot name="el-suffix"></slot>
      </template>

      <template v-if="hasSlot('el-prefix')" #prefix>
        <slot name="el-prefix"></slot>
      </template>

      <template v-if="hasSlot('el-prepend')" #prepend>
        <slot name="el-prepend"></slot>
      </template>
    </el-input>

    <!-- 尾后追加 -->
    <div class="ml-input__append" v-if="hasSlot('append')">
      <slot name="append"> </slot>
    </div>

    <!-- 下拉选择窗口 -->
    <div
      class="warpper-down-area hide-scrollbar"
      :style="warpperAreaState.warpperStyle"
      v-if="warpperAreaState.show"
    >
      <slot name="warpper-down-area"></slot>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
  reactive,
  nextTick,
} from "vue";
import {
  testPositiveNumberFloat,
  testNormalIntegerNumber,
  testPositiveInteger,
} from "@/utils/regExp";

const emits = defineEmits([
  "input",
  "update:modelValue",
  "blur",
  "focus",
  "keydom",
]);
const props = defineProps({
  // 输入类型
  // text|number|numberFloat|integerNumber|password|textarea
  type: {
    type: String,
    default: "text",
  },
  className: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number, Array],
    default: "",
  },
  value: {
    type: [String, Number, Array],
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  // 缺省占位符
  emptyPlaceholder: {
    type: String,
    default: "暂无",
  },
  maxlength: {
    type: [String, Number],
    default: -1,
  },
  maxValue: {
    type: [String, Number],
    default: -1,
  },
  minValue: {
    type: [String, Number],
    default: -1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // 小数点后几位
  floatToFixed: {
    type: Number,
    default: 2,
  },
  // 输入框大小 small||default||large
  size: {
    type: String,
    default: "",
  },
  // 是否显示统计字数, 只在 type 为 'text' 或 'textarea' 的时候生效
  showWordLimit: {
    type: Boolean,
    default: false,
  },
  /**
   * textarea 高度是否自适应，
   * 仅 type 为 'textarea' 时生效。
   * 可以接受一个对象，比如: { minRows: 2, maxRows: 6 }
   */
  autosize: {
    type: Boolean,
    default: false,
  },
  // 输入框行数，仅 type 为 'textarea' 时有效
  rows: {
    type: Number,
    default: 5,
  },
  // 格式
  formatter: {
    type: Function,
    default: () => {},
  },
  parser: {
    type: Function,
    default: () => {},
  },
  clearable: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

// 组件className
const mlClassName = computed(() =>
  props.type === "textarea" ? "textarea" : "input"
);

// 占位符显示
const usePlaceholder = computed(() => {
  const { placeholder, disabled, emptyPlaceholder } = props;
  const val = value.value;

  // 显示缺省占位符
  const showEmptyPlaceholder = disabled && proxy.$isEmpty(val);
  return showEmptyPlaceholder ? emptyPlaceholder : placeholder;
});

// 使用禁用状态
const useDisabled = computed(() => props.disabled);

// 当前最大值
const currMaxValue = computed(() => props.maxValue);
// 当前最小值
const currMinValue = computed(() => props.minValue);

// 输入类型
const inputType = ref("");

// 密码模式
const isPassword = computed(() => inputType.value === "password");

// 基础
const baseMaxlength = ref(props.maxlength);
// 会变化
const inputMaxlength = ref(props.maxlength);

// 有效值
const before_value = ref("");

// 数字校验
const isTestNumberRules = ["numberFloat", "integerNumber", "number"];

// 是否是常规数字类型
const isNormalNumber = computed(() => props.type == "number");

const value = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    value.value = nVal;
  }
);
watch(
  () => value.value,
  (nVal, oVal) => {
    // console.log(nVal);
    let res = "";
    if (isTestNumberRules.includes(props.type)) {
      if (proxy.$isEmpty(nVal)) {
        res = nVal;
      } else {
        // 不是常规数字
        if (!isNormalNumber.value) {
          res = Number(nVal);
        } else {
          res = nVal;
        }
      }
    } else {
      res = nVal;
    }
    // before_value.value = nVal;
    // console.log(res);
    emits("update:modelValue", res);
  }
);

// 显示 展示值
watch(
  () => props.value,
  (nVal) => {
    // 赋值时 v-model 和 value 二选一
    // 必须有值 避免和 modelValue冲突
    if (!proxy.$isEmpty(nVal)) {
      value.value = nVal;
    }
  },
  {
    immediate: true,
  }
);

// 根据类型校验
const getValueRegTest = (val) => {
  const type = props.type;

  const regExps = {
    numberFloat: testPositiveNumberFloat,
    integerNumber: testPositiveInteger,
    number: testNormalIntegerNumber,
  };

  return regExps[type](val);
};

const onInput = (input_value) => {
  const type = props.type;

  // 数字
  if (isTestNumberRules.includes(type)) {
    inputMaxlength.value = baseMaxlength.value;

    // console.log(proxy.$isEmpty(input_value));
    if (proxy.$isEmpty(input_value)) {
      value.value = "";
      return;
    }

    // 数字类型
    const number_value = Number(input_value);
    // 校验
    const is_number = getValueRegTest(number_value);

    // console.log(is_number);
    // 非正常值
    if (!is_number) {
      const result = input_value
        .toString()
        .split("")
        .map((d) => Number(d))
        .filter((d) => !isNaN(d))
        .join("");

      value.value = result;

      if (!isNormalNumber.value) {
        value.value = Number(value.value);
      } else {
        value.value = value.value;
      }
      // console.log("非正常值");
      // console.log(value.value);
      return;
    }

    // 值是否类似 x.xx
    // 小数点
    if (input_value.indexOf(".") !== -1 && type != "integerNumber") {
      const [firstNum, floatNum] = input_value.split(".");
      value.value = input_value;
      value.value = `${firstNum}.${floatNum.slice(0, props.floatToFixed)}`;
      // console.log("小数点");
    } else {
      // 数值长度 不否相对 && 不是常规数字
      if (
        input_value.length != number_value.toString().length &&
        !isNormalNumber.value
      ) {
        // 排除 类似 000 数值
        value.value = input_value;
        value.value = value.value.slice(0, 1);
        // inputMaxlength.value = 2;
        // console.log("数值长度 不否相对");
        return;
      } else {
        value.value = input_value;
      }
    }

    // console.log(value.value);
    // console.log(currMaxValue.value);
    // console.log(currMaxValue.value != -1);
    // console.log(currMinValue.value != -1);
  } else {
    value.value = input_value;
  }

  // 最大长度
  if (props.maxlength != -1) {
    if (typeof value.value == "string") {
      value.value = value.value.slice(0, props.maxlength);
    } else {
      const valueStr = value.value.toString();
      value.value = Number(valueStr.slice(0, props.maxlength));
    }
  }

  // console.log(value.value);
  emits("input", input_value);
};

/* 底部下拉显示窗口 */
// 打开窗口样式
const openWarpperStyle = () => ({
  opacity: 1,
  minHeight: "200px",
  height: "auto",
});
// 关闭窗口样式
const closeWarpperStyle = () => ({
  opacity: 0,
  minHeight: 0,
  height: "0%",
});
// 窗口状态
const warpperAreaState = reactive({
  // 窗口显示
  show: false,
  // 显示样式
  warpperStyle: closeWarpperStyle(),
  // 延迟时间ID
  delayTimeId: null,
});
// 重置计时
const resetWarpperTime = () => {
  clearTimeout(warpperAreaState.delayTimeId);
  warpperAreaState.delayTimeId = null;
};

// 打开底部窗口
const openWarpperArea = () => {
  if (warpperAreaState.show) {
    return;
  }

  warpperAreaState.show = true;
  resetWarpperTime();
  warpperAreaState.delayTimeId = setTimeout(() => {
    warpperAreaState.warpperStyle = openWarpperStyle();
  }, 100);
};
// 关闭底部窗口
const closeWarpperArea = () => {
  if (!warpperAreaState.show) {
    return;
  }

  warpperAreaState.warpperStyle = closeWarpperStyle();
  warpperAreaState.delayTimeId = setTimeout(() => {
    warpperAreaState.show = false;
    resetWarpperTime();
  }, 300);
};

// 是否存在该插槽
const hasSlot = (name) => {
  return !!proxy.$slots[name];
};

// 监听输入框 失去焦点
const onBlur = (res) => {
  isNowFocus.value = false;

  // 判断最大值
  if (currMaxValue.value != -1) {
    const maxVal = currMaxValue.value;
    const number_value = Number(value.value);

    // 有效值
    if (!proxy.$isEmpty(number_value) && !proxy.$isEmpty(maxVal)) {
      console.log(number_value > maxVal);
      if (number_value > maxVal) {
        value.value = maxVal;
        value.value = value.value.toString().slice(0, maxVal.length);
      } else {
        if (value.value < 0) {
          value.value = "";
        }
      }
    }

    // console.log("判断最大值");
  }

  // 判断最小值
  if (currMinValue.value != -1) {
    const minVal = currMinValue.value;
    const number_value = Number(value.value);

    // 有效值
    if (!proxy.$isEmpty(number_value) && !proxy.$isEmpty(minVal)) {
      if (number_value < minVal) {
        value.value = minVal;
        value.value = value.value.toString().slice(0, minVal.length);
      } else {
        if (value.value < 0) {
          value.value = "";
        }
      }
    }
  }

  emits("blur", res);
};

// 是否正在焦点中
const isNowFocus = ref(false);

// 监听输入框 获取焦点
const onFocus = (res) => {
  isNowFocus.value = true;
  emits("focus", res);
};

// 监听按键
const onKeydom = (res) => {
  emits("keydom", res);
};

// 是否自动获取焦点
const autofocus = ref(false);
const isFocus = () => {
  autofocus.value = true;
};

onMounted(() => {
  const type = props.type;

  // 判断 使用类型
  const isUse = ["password", "textarea"];
  if (isUse.includes(type)) {
    inputType.value = type;
  } else {
    inputType.value = "text";
  }
});

defineExpose({
  openWarpperArea,
  closeWarpperArea,
  isFocus,
});
</script>

<style lang="scss">
.el-form-item {
  &.is-error {
    .ml-input {
      .el-input__wrapper {
        border: 1px solid var(--el-color-danger);
        transition: border 300ms;

        &:hover {
          border: 1px solid var(--el-input-border-color);
        }
      }
    }
  }
}

.ml-input {
  position: relative;

  &.is-now-focus {
    .el-input {
      .el-input__wrapper {
        border-color: var(--el-color-primary);
      }
    }
  }

  &.is-has-before {
    ::v-deep(.el-input-group__prepend) {
      padding: 0 11px !important;
    }

    ::v-deep(.el-input__wrapper) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left: none !important;
    }
  }

  // 前
  &.is-has-prepend {
    display: grid;
    grid-template-columns: auto 1fr;
    .el-input {
      .el-input__wrapper {
        box-shadow: none;
        border: 1px solid var(--el-border-color);
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        box-sizing: border-box;
        padding: 0 11px;
        border-left: none !important;
      }
    }
    .ml-input__prepend {
      height: 100%;
      display: flex;
      align-items: center;
      background-color: white;
      border: 1px solid var(--el-border-color);
      box-sizing: border-box;
      // padding-right: 30px;
      border-right: none;
      border-top-left-radius: var(
        --el-input-border-radius,
        var(--el-border-radius-base)
      );
      border-bottom-left-radius: var(
        --el-input-border-radius,
        var(--el-border-radius-base)
      );

      background-color: var(--el-fill-color-light);
      border-right: 1px solid var(--el-border-color);
      // padding-left: 30px;
      color: var(--el-color-info);
      display: flex;
      flex-direction: row;
      justify-content: center;
      min-width: 60px;
      padding: 0 10px;
    }
  }

  &.is-has-append {
    display: grid;
    grid-template-columns: 1fr auto;
    .el-input {
      .el-input__wrapper {
        box-shadow: none;
        border: 1px solid var(--el-border-color);
        border-right: none;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        box-sizing: border-box;
        padding: 0 11px;
      }
    }
    .ml-input__append {
      height: 100%;
      display: flex;
      align-items: center;
      background-color: white;
      border: 1px solid var(--el-border-color);
      box-sizing: border-box;
      // padding-right: 30px;
      border-left: none;
      border-top-right-radius: var(
        --el-input-border-radius,
        var(--el-border-radius-base)
      );
      border-bottom-right-radius: var(
        --el-input-border-radius,
        var(--el-border-radius-base)
      );

      background-color: var(--el-fill-color-light);
      border-left: 1px solid var(--el-border-color);
      // padding-left: 30px;
      color: var(--el-color-info);
      display: flex;
      flex-direction: row;
      justify-content: center;
      min-width: 60px;
      padding: 0 10px;
    }
  }

  .el-input {
    .el-input__wrapper {
      box-shadow: none;
      border: 1px solid var(--el-input-border-color);
      border-radius: 6px;
      transition: border-color 300ms;
    }

    &.is-disabled {
      .el-input__wrapper {
        input {
          color: transparent;
          text-shadow: 0 0 #000000;
        }
      }
    }
  }

  /* 输入框底部下拉选择窗 */
  .warpper-down-area {
    position: absolute;
    top: 40px;
    left: 3px;
    width: calc(100% - 6px);
    z-index: 99;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #00000014;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    max-height: 40vh;
    transition: 300ms;
    overflow-y: auto;
  }
}
</style>
