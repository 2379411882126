<!-- 状态显示 -->
<template>
  <div class="status-info">
    <div class="state success" v-if="status === successValue">
      <p class="title">{{ successText }}</p>
      <el-icon size="16"><Select /></el-icon>
    </div>
    <div class="state fail" v-if="status === failValue">
      <p class="title">{{ failText }}</p>
      <el-icon size="16"><Close /></el-icon>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  successText: {
    type: String,
    default: "启用",
  },
  failText: {
    type: String,
    default: "禁用",
  },
  result: {
    type: [String, Number, Boolean],
    default: "",
  },
  successValue: {
    type: [String, Number, Boolean],
    default: 1,
  },
  failValue: {
    type: [String, Number, Boolean],
    default: 0,
  },
});

// 值
const status = computed(() => props.result);
</script>

<style lang="scss" scoped>
.status-info {
  width: fit-content;

  .state {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: center;
    align-items: center;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 5px;
    line-height: 26px;
    &.success {
      color: #1c6c09;
      background-color: #d9fbd0;
    }
    &.fail {
      color: #bc3803;
      background-color: #ffefca;
    }
    .title {
      padding-right: 6px;
    }
  }
}
</style>

<style lang="scss">
.is-center {
  .status-info {
    margin: 0 auto;
  }
}
</style>
