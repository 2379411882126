import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  useRoute,
} from "vue-router";

// 页面路由数据
import pagesData from "./pages";

// 路由基础数据
export const routes = () => {
  return [
    {
      path: "/",
      name: "main",
      component: () => import("../views/main.vue"),
      children: [...pagesData],
    },

    {
      path: "/404",
      name: "404",
      component: () => import("../views/404.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/login.vue"),
    },
  ];
};

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes(),
});

// 添加路由
export const addRouters = (data) => {
  // 索引权限
  const adds = pagesData.filter((item) => {
    const path = item.path;
    return data.includes(path);
  });

  for (let i = 0; i < adds.length; i++) {
    router.addRoute("main", adds[i]);
  }
};

// 删除路由
export const reomveRoutes = () => {
  for (let i = 0; i < pagesData.length; i++) {
    router.removeRoute(pagesData[i].name);
  }
};

export default router;
