/* 使用的 页面操作功能 */

// 功能编号列表

// WEB端
export const WEB_MENU_CODE_LIST = [
  {
    title: "添加",
    menuCode: "normal_add",
  },
  {
    title: "删除",
    menuCode: "normal_delete",
  },
  {
    title: "修改",
    menuCode: "normal_update",
  },
  {
    title: "查看",
    menuCode: "normal_examine",
  },
  {
    title: "启用/禁用",
    menuCode: "enableDisable",
  },
  {
    title: "重置密码",
    menuCode: "normal_reset_password",
  },
  {
    title: "下载文件",
    menuCode: "download_attachments",
  },
  {
    title: "添加文件",
    menuCode: "add_attachments",
  },
  {
    title: "上传文件",
    menuCode: "upload_attachments",
  },
  {
    title: "上传版本",
    menuCode: "upload_version",
  },
  {
    title: "填写备注",
    menuCode: "fill_remarks",
  },
];
