import { storeResponse } from "@/utils/module";

// 用户管理

export default {
  actions: {
    // ********************************************************************************
    // 存储桶
    // ********************************************************************************
    // 添加存储桶
    async fetchAddStorageBucket({ getters }, params) {
      const { addStorageBucket } = getters.getHttps;

      const result = await addStorageBucket(params);

      return storeResponse(result);
    },
    // 获取存储桶分页列表
    async fetchGetStorageBucketPageList({ getters }, params) {
      const { getStorageBucketPageList } = getters.getHttps;

      const result = await getStorageBucketPageList(params);

      return storeResponse(result);
    },
    // 获取存储桶列表
    async fetchGetStorageBucketList({ getters }, params) {
      const { getStorageBucketList } = getters.getHttps;

      const result = await getStorageBucketList(params);

      return storeResponse(result);
    },
    // 获取存储桶详情
    async fetchGetStorageBucketDetails({ getters }, params) {
      const { getStorageBucketDetails } = getters.getHttps;

      const result = await getStorageBucketDetails(params);

      return storeResponse(result);
    },
    // 修改存储桶
    async fetchModifyStorageBucket({ getters }, params) {
      const { modifyStorageBucket } = getters.getHttps;

      const result = await modifyStorageBucket(params);

      return storeResponse(result);
    },
    // 删除存储桶
    async fetchRemoveStorageBucket({ getters }, params) {
      const { removeStorageBucket } = getters.getHttps;

      const result = await removeStorageBucket(params);

      return storeResponse(result);
    },

    // ********************************************************************************
    // 存储目录
    // ********************************************************************************
    // 添加存储目录
    async fetchAddStorageDir({ getters }, params) {
      const { addStorageDir } = getters.getHttps;

      const result = await addStorageDir(params);

      return storeResponse(result);
    },
    // 获取存储目录分页列表
    async fetchGetStorageDirPageList({ getters }, params) {
      const { getStorageDirPageList } = getters.getHttps;

      const result = await getStorageDirPageList(params);

      return storeResponse(result);
    },
    // 获取存储目录列表
    async fetchGetStorageDirList({ getters }, params) {
      const { getStorageDirList } = getters.getHttps;

      const result = await getStorageDirList(params);

      return storeResponse(result);
    },
    // 获取存储目录详情
    async fetchGetStorageDirDetails({ getters }, params) {
      const { getStorageDirDetails } = getters.getHttps;

      const result = await getStorageDirDetails(params);

      return storeResponse(result);
    },
    // 修改存储目录
    async fetchModifyStorageDir({ getters }, params) {
      const { modifyStorageDir } = getters.getHttps;

      const result = await modifyStorageDir(params);

      return storeResponse(result);
    },
    // 删除存储目录
    async fetchRemoveStorageDir({ getters }, params) {
      const { removeStorageDir } = getters.getHttps;

      const result = await removeStorageDir(params);

      return storeResponse(result);
    },
    // 获取存储目录选择器
    async fetchGetStorageDirChooser({ getters }, params) {
      const { getStorageDirChooser } = getters.getHttps;

      const result = await getStorageDirChooser(params);

      return storeResponse(result);
    },

    // ********************************************************************************
    // 服务文件
    // ********************************************************************************
    // 获取存储文件分页列表
    async fetchGetStorageFilePageList({ getters }, params) {
      const { getStorageFilePageList } = getters.getHttps;

      const result = await getStorageFilePageList(params);

      return storeResponse(result);
    },
    // 文件下载
    async fetchDownload({ getters }, params) {
      const { download } = getters.getHttps;

      const result = await download(params, {
        custom: {
          isPathVariable: true,
          isBlob: true,
          showTips: false,
        },
      });

      return result;
    },
    // 获取存储文件目录列表
    async fetchGetStorageFileDirList({ getters }, params) {
      const { getStorageFileDirList } = getters.getHttps;

      const result = await getStorageFileDirList(params);

      return storeResponse(result);
    },
    // 获取存储文件分页列表
    async getStorageFilePageList({ getters }, params) {
      const { getStorageFilePageList } = getters.getHttps;

      const result = await getStorageFilePageList(params);

      return storeResponse(result);
    },
    // 获取存储文件详情
    async fetchGetStorageFileDetails({ getters }, params) {
      const { getStorageFileDetails } = getters.getHttps;

      const result = await getStorageFileDetails(params);

      return storeResponse(result);
    },
    // 删除存储文件
    async fetchBatchRemoveStorageFile({ getters }, params) {
      const { batchRemoveStorageFile } = getters.getHttps;

      const result = await batchRemoveStorageFile(params);

      return storeResponse(result);
    },
    // 修改存储文件状态
    async fetchModifyAccessRightsType({ getters }, params) {
      const { modifyAccessRightsType } = getters.getHttps;

      const result = await modifyAccessRightsType(params);

      return storeResponse(result);
    },
    // 恢复存储文件
    async fetchRecoveryStorageFile({ getters }, params) {
      const { recoveryStorageFile } = getters.getHttps;

      const result = await recoveryStorageFile(params);

      return storeResponse(result);
    },
    // 删除回收站文件
    async fetchBatchDeleteRecycleBinFile({ getters }, params) {
      const { batchDeleteRecycleBinFile } = getters.getHttps;

      const result = await batchDeleteRecycleBinFile(params);

      return storeResponse(result);
    },
    // 清空回收站文件
    async fetchCleanRecycleBinFile({ getters }, params) {
      const { cleanRecycleBinFile } = getters.getHttps;

      const result = await cleanRecycleBinFile(params);

      return storeResponse(result);
    },

    // ********************************************************************************
    // 统计概览
    // ********************************************************************************
    // 获取系统磁盘容量
    async fetchGetSystemDiskCapacity({ getters }, params) {
      const { getSystemDiskCapacity } = getters.getHttps;

      const result = await getSystemDiskCapacity(params);

      return storeResponse(result);
    },
    // 获取存储文件使用大小
    async fetchGetStorageFileUsedSpace({ getters }, params) {
      const { getStorageFileUsedSpace } = getters.getHttps;

      const result = await getStorageFileUsedSpace(params);

      return storeResponse(result);
    },

    // ********************************************************************************
    // 统计概览
    // ********************************************************************************
    // 添加存储文件图标
    async fetchAddStorageFileIcon({ getters }, params) {
      const { addStorageFileIcon } = getters.getHttps;

      const result = await addStorageFileIcon(params);

      return storeResponse(result);
    },
    // 获取存储文件标签列表
    async fetchGetStorageFileIconLabelList({ getters }, params) {
      const { getStorageFileIconLabelList } = getters.getHttps;

      const result = await getStorageFileIconLabelList(params);

      return storeResponse(result);
    },
    // 获取存储文件图标列表
    async fetchGetStorageFileIconList({ getters }, params) {
      const { getStorageFileIconList } = getters.getHttps;

      const result = await getStorageFileIconList(params);

      return storeResponse(result);
    },
    // 获取存储文件图标详情
    async fetchGetStorageFileIconDetails({ getters }, params) {
      const { getStorageFileIconDetails } = getters.getHttps;

      const result = await getStorageFileIconDetails(params);

      return storeResponse(result);
    },
    // 修改存储文件图标
    async fetchModifyStorageFileIcon({ getters }, params) {
      const { modifyStorageFileIcon } = getters.getHttps;

      const result = await modifyStorageFileIcon(params);

      return storeResponse(result);
    },
    // 删除存储文件图标
    async fetchRemoveStorageFileIcon({ getters }, params) {
      const { removeStorageFileIcon } = getters.getHttps;

      const result = await removeStorageFileIcon(params);

      return storeResponse(result);
    },
  },
};
