import { defineStore } from "pinia";

import { updateParams, clearStoreAgeToken } from "@/utils/module";

// 当前本地状态默认数据
const currStateDefaults = () => ({
  // 用户信息
  userInfo: {},

  // 用户菜单数据
  userMenuData: [],

  // 左侧菜单预览数据
  leftMenuData: [],

  // 用户菜单路径数据
  menuHrefs: [],

  // 路由按钮权限列表
  routeBtnAuths: [],

  //是否 路由菜单初始重定向
  initRedirect: false,

  // 是否 有tool-dialog弹窗打开
  dialogShow: false,

  // http axios的cancel source
  httpSource: null,

  // 自定义layout标题
  customLayOutTitle: "",
});

export default defineStore({
  id: "main",
  state: () => currStateDefaults(),
  actions: {
    // 本地静态数据清除操作
    clearLocalData() {
      // 清除
      clearStoreAgeToken();

      // console.log(this)
      // console.log(currStateDefaults())
      // 重置
      updateParams(this, currStateDefaults(), {
        updateOriginDataType: "data",
      });
    },
  },
  getters: {
    getHttps: (state) => state.https,
    getUserInfo: (state) => state.userInfo,
    getUserMenuData: (state) => state.userMenuData,
  },
});
