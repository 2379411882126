/* 数据字典 */

/* 菜单 */
// 场景值
// WEB端
export const SCENETYPE_WEB = "MST_WEB";
// 作用域
// 分组
export const ACTIONSCOPE_GROUP = 0;
// 页面
export const ACTIONSCOPE_PAGE = 1;
// 按钮
export const ACTIONSCOPE_BUTTON = 2;

// 文件类型
export const GLAFT_TYPE = "GLAFT";

// *********************************************************************
// 特殊

/* 文件库 */
export const FLS_TYPE = "FLS";
// 头像
export const FLS_TX_TYPE = "FLS_TX";
export const FLS_TX_DESCRIBE = "头像";

/* 关联-附件 */
// 文件类型
export const GLAFT_OPTIONS = () => [
  {
    label: "图片文件",
    value: "GLAFT_IMAGE",
  },
  {
    label: "视频文件",
    value: "GLAFT_VIDEO",
  },
  {
    label: "PDF文件",
    value: "GLAFT_PDF",
  },
  {
    label: "Word文档",
    value: "GLAFT_WORD",
  },
  {
    label: "Excel文档",
    value: "GLAFT_EXCEL",
  },
  {
    label: "PPT文档",
    value: "GLAFT_PPT",
  },
  {
    label: "文本文件",
    value: "GLAFT_TXT",
  },
  {
    label: "其他文件",
    value: "GLAFT_OTHER",
  },
];

/* 短信发送与校验 */
export const SHORT_MESSAGE_TYPE = "LOGIN_SYSTEM_CODE";

/* 日报发送与校验 */
export const DAILY_TURNOVER_TYPE = "DAILY_TURNOVER_CODE";

/* 月报发送与校验 */
export const MONTH_TURNOVER_TYPE = "MONTH_TURNOVER_CODE";

/* 营业额统计发送与校验 */
export const TURNOVER_STATISTICS_TYPE = "TURNOVER_STATISTICS_CODE";
