<template>
  <!-- 头像 -->
  <div
    @click="handleClick"
    :class="`head-sculpture ${mode}`"
    :style="{
      width: width || useSize,
      height: height || useSize,
    }"
  >
    <el-image
      :src="showAvatar"
      :fit="fit"
      :style="{
        'border-radius': useRaiuds,
      }"
    >
      <template #error>
        <div class="image-slot" v-if="errorEmptyType == 'default'">
          <img :src="defaultAvatar" alt="" />
        </div>
        <div class="image-slot" v-if="errorEmptyType == 'image'">
          <el-icon><PictureFilled /></el-icon>
        </div>
        <div class="image-slot" v-if="errorEmptyType == 'custom'">
          <img class="custom-err-icon" :src="customErrIcon" alt="" />
        </div>
      </template>
    </el-image>

    <!-- 在线状态 -->
    <div class="online-status on" v-if="showOnlineStatus"></div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";
import { PictureFilled } from "@element-plus/icons-vue";

const emits = defineEmits(["handleClick"]);

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  fit: {
    type: String,
    default: "cover",
  },
  className: {
    type: String,
    default: "",
  },
  size: {
    type: [Number, String],
    default: 36,
  },
  width: {
    type: String,
    default: "",
  },
  height: {
    type: String,
    default: "",
  },
  // 显示模式 normal||circle
  mode: {
    type: String,
    default: "circle",
  },
  // 是否 使用地址前缀
  isUsePreviewUrl: {
    type: Boolean,
    default: true,
  },
  // 弧度
  raiuds: {
    type: [Number, String],
    default: 50,
  },
  // 显示在线状态
  showOnlineStatus: {
    type: Boolean,
    default: false,
  },
  // 错误图片展示类型
  errorEmptyType: {
    type: String,
    default: "default",
  },
  // 自定义错误图片
  customErrIcon: {
    type: String,
    default: "",
  },
});

const { proxy } = getCurrentInstance();

/* 属性信息 */
const useSize = computed(() => {
  const { size } = props;
  return typeof size === "number" ? size + "px" : size;
});
const useRaiuds = computed(() => {
  const { raiuds } = props;
  return typeof raiuds === "number" ? raiuds + "px" : raiuds;
});

// 展示头像
const showAvatar = computed(() => {
  const isUse = props.isUsePreviewUrl;
  const src = (isUse ? proxy.$previewFileUrl : "") + props.src;
  return src;
});

// 错误 默认头像
const defaultAvatar = require("@/assets/images/default-avatar.png");

// 点击
const handleClick = () => {
  emits("handleClick");
};
</script>

<style lang="scss" scoped>
.head-sculpture {
  width: 100%;
  height: 100%;
  position: relative;
  &.circle {
    border-radius: 50%;
    .el-image {
      border-radius: 50%;
    }
    ::v-deep(.el-image__inner) {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &.normal {
    border-radius: 6px;
    .el-image {
      border-radius: 6px;
    }
    ::v-deep(.el-image__inner) {
      border-radius: 6px;
      overflow: hidden;
    }

    .image-slot {
      .el-icon {
        font-size: 22px;
      }
    }
  }

  .image-slot {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
  }

  // 在线状态
  .online-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;

    &.on {
      background-color: #39f741;
    }
    &.off {
      background-color: red;
    }
  }
}
</style>
