<template>
  <div class="tree-business-category">
    <!-- 全部 -->
    <!-- <div class="checkbox-all-item">
      <el-checkbox
        label="全部"
        v-model="treeChangeAllStatus"
        :disabled="disabled"
      ></el-checkbox>
    </div> -->

    <normal-tree
      @check="checkTreeChange"
      @onNodeExpand="onNodeExpand"
      @onNodeCollapse="onNodeCollapse"
      ref="treeRef"
      :data="treeData"
      :nodeKey="nodeKey"
      :showCheckbox="showCheckbox"
      :props="treeProps"
      :checkStrictly="isCheckStrictly"
      :defaultExpandedKeys="defaultExpandedKeys"
      :disabled="disabled"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch } from "vue";
import { useTreeCheckAllStatusModule } from "@/utils/mixin";

/* 全部 状态已在本版本废弃，无需通过独立的勾选去处理了 */

const emits = defineEmits([
  "change",
  "update:checkAll",
  "update:changeStatus",
  "onNodeExpand",
  "onNodeCollapse",
]);

const props = defineProps({
  // 区域状态 （0:全部、1:自定义）
  changeStatus: {
    type: [String, Number, Boolean],
    default: "",
  },
  // 是否勾选全部状态
  checkAllStatus: {
    type: Boolean,
    default: false,
  },
  nodeKey: {
    type: String,
    default: "id",
  },
  // 显示勾选项
  showCheckbox: {
    type: Boolean,
    default: true,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

/* 属性 */
// 勾选全部状态
const treeChangeAllStatus = ref(props.checkAllStatus);

// 只监听一次 勾选全部状态
// 是否阻止
const isStopAllStatus = ref(false);
watch(
  () => props.checkAllStatus,
  (nVal) => {
    if (isStopAllStatus.value) {
      return;
    }
    isStopAllStatus.value = true;

    const status = proxy.$isEmpty(nVal) ? false : nVal;

    treeChangeAllStatus.value = status;
  }
);

/* 父子级是否断开关联 */
const isCheckStrictly = ref(true);

/* 默认展开数据 */
const defaultExpandedKeys = ref([]);

/* tree 树形结构操作 */
// ref
const treeRef = ref();
// 树形数据
const treeData = ref([]);
// 树形数据结构参数
const treeProps = {
  label: "name",
  children: "children",
};

/* 回调勾选数据 */
const callBackChange = (allKeys, checkNodes, status) => {
  emits("change", allKeys, status);
};


/* 处理 树形中 操作全部与子级关联 操作子级与全部不关联（取消子级选中会取消全部勾选） */
const { onTreeChangeAll, checkTreeChange, setNodesConfig } =
  useTreeCheckAllStatusModule(props, emits, callBackChange, {
    treeRef,
    treeData,
    treeProps,
    treeChangeAllStatus,
    isCheckStrictly,
  });

// 设置tree key选中
const setCheckedKeys = (ids) => {
  // 赋值选中
  treeRef.value.setCheckedKeys(ids);
};
// 获取
const getCheckedKeys = () => {
  return treeRef.value.getCheckedKeys();
};

/* 查询 */
// 查询品牌分类
const getBrandClassList = async () => {
  try {
    const res = await proxy.$storeDispatch("fetchGetAllOperateTypeChooser");
    const result = res.result;
    treeData.value = result;
    // console.log(treeData.value);

    setNodesConfig();
    // 状态值会先一步出现 等待树形数据
    onTreeChangeAll(treeChangeAllStatus.value);

    treeRef.value.hideLoading();
  } catch (error) {
    treeRef.value.hideLoading();
  }
};

/* 监听节点伸缩 */
// 展开
const onNodeExpand = (res) => {
  emits("onNodeExpand", res);
};
// 收起
const onNodeCollapse = (res) => {
  emits("onNodeCollapse", res);
};

// 初始
const init = () => {
  getBrandClassList();
};

defineExpose({
  init,
  setCheckedKeys,
  getCheckedKeys,
});
</script>

<style lang="scss" scoped>
.tree-business-category {
  width: 100%;
  height: 100%;
}
</style>
