/**
 * 表单校验 规则集合
 */

import { testPhone, testNormalCharacterMix, versionNumber } from "./regExp";

/* 常规手机号 规则 */
export const ruleNormalPhone = (opt) => {
  const { messageText = "手机号" } = opt || {};

  return [
    {
      trigger: "change",
      message: "请先输入" + messageText,
      required: true,
    },
    {
      trigger: "change",
      message: `填写的${messageText}不正确`,
      validator(rule, value, call) {
        const testRes = testPhone(value);
        const isAdmin = value == "admin" ? true : false;
        const isPass = testRes || isAdmin;
        isPass ? call() : call(false);
        return isPass;
      },
    },
  ];
};

/* 常规账户 规则 */
export const ruleNormalAccount = () => [
  {
    message: "请先输入账号",
    required: true,
  },
  {
    trigger: "change",
    message: "请输入正确的账户",
    validator(rule, value, call) {
      const testRes = testNormalCharacterMix(value);
      testRes ? call() : call(false);
      return testRes;
    },
  },
];

// 版本号校验
export const versionNumberRul = () => [
  {
    required: true,
    message: "请先输入版本号",
  },
  {
    trigger: "change",
    message: "只可输入数字与黑圆点，例：0.0.1、00.00.01 或 0.1",
    validator(rule, value, call) {
      const testRes = versionNumber(value);
      testRes ? call() : call(false);
      return testRes;
    },
  },
];
