<template>
  <div class="panel-action-container" v-loading="isLoading">
    <div class="panel-action-container__content">
      <slot name="title" v-if="showHeader">
        <detail-navbar
          :title="showPanelTitle"
          @refresh="refreshView"
        ></detail-navbar>
      </slot>

      <div class="container-box hide-scrollbar">
        <slot></slot>
      </div>

      <slot name="floor" v-if="showFloorBox">
        <!-- 添加 -->
        <div class="buttom-box" v-if="isActionAdd">
          <div class="btn" v-if="showAddBtn">
            <ml-button
              useLoading
              :disabled="useDisabledSubmit"
              @submit="onPanelSubmit"
              >添加</ml-button
            >
          </div>
          <div class="btn" v-if="showCancel">
            <ml-button type="default" @handleClick="goBack">取消</ml-button>
          </div>
        </div>

        <!-- 编辑 -->
        <div class="buttom-box" v-if="isActionEdit">
          <div class="btn" v-if="showEditBtn">
            <ml-button
              useLoading
              type="warning"
              :disabled="useDisabledSubmit"
              @submit="onPanelSubmit"
              >修改</ml-button
            >
          </div>
          <div class="btn" v-if="showCancel">
            <ml-button type="default" @handleClick="goBack">取消</ml-button>
          </div>
        </div>

        <!-- 详情 -->
        <div class="buttom-box" v-if="isActionExamine">
          <div class="btn" v-if="showCancel">
            <ml-button type="default" @handleClick="goBack">取消</ml-button>
          </div>
        </div>
      </slot>
    </div>
  </div>

  <!-- 询问弹出窗 -->
  <ask-confirm
    :type="askConfig.type"
    :title="askConfig.title"
    :confirmLoadingText="askConfig.confirmLoadingText"
    ref="askDialogRef"
    @confirm="askConfig.confirm"
    :askTips="askConfig.askTips"
    @dialogClose="onAskDialogClose"
  />
</template>

<script setup>
import { reactive, ref, getCurrentInstance, nextTick, computed } from "vue";

const { proxy } = getCurrentInstance();

const { dataStore } = proxy.$usePiniaModule();

const emits = defineEmits(["submit", "refresh"]);

const props = defineProps({
  panelTitle: {
    type: String,
    default: "",
  },
  afterPanelTitle: {
    type: String,
    default: "",
  },
  // 操作类型
  actionType: {
    type: String,
    default: "",
  },
  // 显示添加
  showAddBtn: {
    type: Boolean,
    default: true,
  },
  // 显示编辑
  showEditBtn: {
    type: Boolean,
    default: true,
  },
  // 显示取消
  showCancel: {
    type: Boolean,
    default: true,
  },
  // 显示底部floor
  showFloorBox: {
    type: Boolean,
    default: true,
  },
  // 显示头部
  showHeader: {
    type: Boolean,
    default: true,
  },
  // 禁用提交按钮
  disabledSubmit: {
    type: Boolean,
    default: false,
  },
});

/* 属性信息 */
const useActionType = computed(() => props.actionType);
// 添加
const isActionAdd = computed(() => useActionType.value == "add");
// 编辑
const isActionEdit = computed(() => useActionType.value == "edit");
// 详情
const isActionExamine = computed(() => useActionType.value == "examine");

// 禁用按钮
const useDisabledSubmit = computed(() => props.disabledSubmit);

// 展示的title
const showPanelTitle = computed(() => {
  const { panelTitle, afterPanelTitle } = props;
  const text = {
    add: "添加",
    edit: "修改",
    examine: "详情",
  };
  const tips = text[useActionType.value] || "";
  if (isActionExamine.value) {
    return `${panelTitle}${tips}${afterPanelTitle}`;
  } else {
    return `${tips}${panelTitle}${afterPanelTitle}`;
  }
});

// ******************************************************************************************
// 功能类 START
// ******************************************************************************************

/* 加载状态 */
const isLoading = ref(false);
// 显示加载
const showLoading = () => {
  isLoading.value = true;
};
// 隐藏加载
const hideLoading = () => {
  isLoading.value = false;
};

// 返回
const goBack = () => {
  proxy.$router.go(-1);
};

// 刷新routerView
// 监听菜单点击操作
const refreshView = () => {
  dataStore.globalViewRefresh = false;
  setTimeout(() => {
    dataStore.globalViewRefresh = true;
  }, 100);
};

// ******************************************************************************************
// 功能类 END
// ******************************************************************************************

// ******************************************************************************************
// 面板类 START
// ******************************************************************************************

/* 配置折叠面板 */
// ref
const collapsePanelRefs = ref({});
// 函数
const collapsePanelFunctions = ref({});
// 初始面板内的折叠组件
const initCollapsePanel = (config) => {
  const { refs = {} } = config || {};

  try {
    // 赋值
    collapsePanelRefs.value = refs;

    // console.log("折叠板已初始成功！");

    // 生成
    const functionResult = createCollapsePanelFunction();
    collapsePanelFunctions.value = functionResult;

    return {
      ...functionResult,
      unifyRefreshCollapseHeight,
    };
  } catch (error) {
    console.log("折叠板已初始失败！", error);
    return null;
  }
};
/* 刷新折叠面板的高度 */
const refreshCollapseHeight = (type, opt) => {
  nextTick(() => {
    setTimeout(() => {
      handleCollapseHeight(type, opt);
    }, 300);
  });
};
/* 操作面板高度 */
const handleCollapseHeight = (type, opt) => {
  const useRef = collapsePanelRefs.value[type];
  if (useRef) {
    useRef.init(opt);
  } else {
    console.error(`未找到${type}的面板实例!`);
  }
};
/* 生成操作折叠面板函数 */
const createCollapsePanelFunction = () => {
  const result = {};
  const names = Object.keys(collapsePanelRefs.value);
  for (let i = 0; i < names.length; i++) {
    const name = names[i];

    // 赋值
    result[name] = (opt) => {
      const { useAnimate = true } = opt || {};

      // 使用动画
      if (useAnimate) {
        refreshCollapseHeight(name, opt);
      } else {
        // 不使用动画
        handleCollapseHeight(name, opt);
      }
    };
  }

  // console.log(result);
  return result;
};
/* 统一刷新面板高度 */
const unifyRefreshCollapseHeight = (opt) => {
  for (let i in collapsePanelFunctions.value) {
    const fun = collapsePanelFunctions.value[i];
    if (!!fun) {
      fun(opt);
    }
  }
};

/* 提交 */
const onPanelSubmit = (closeLoading) => {
  emits("submit", closeLoading);
};

// ******************************************************************************************
// 面板类 END
// ******************************************************************************************

// ******************************************************************************************
// 弹窗类 START
// ******************************************************************************************

/* 询问弹出窗 */
// ref
const askDialogRef = ref();
// 默认
const dialogDefaultConfig = () => ({
  type: "",
  title: "",
  confirmLoadingText: "操作中",
  askTips: [],
});
// 配置
const askConfig = reactive(dialogDefaultConfig());
// 设置询问弹窗配置
const askDialog = (opts = {}) => {
  askDialogRef.value.openDialog();
  nextTick(() => {
    proxy.$updateParams(askConfig, opts);
    askConfig.confirm = (close) => {
      const call = () => {
        askDialogRef.value.closeDialog();
        close();
      };
      opts.confirm(call);
    };
  });
};
// 监听窗口的关闭
const onAskDialogClose = () => {
  nextTick(() => {
    proxy.$updateParams(askConfig, dialogDefaultConfig());
  });
};

// ******************************************************************************************
// 弹窗类 END
// ******************************************************************************************

defineExpose({
  /* 弹窗 */
  askDialog,

  /* 面板 */
  initCollapsePanel,
  refreshCollapseHeight,
  unifyRefreshCollapseHeight,

  /* 加载 */
  showLoading,
  hideLoading,
});
</script>

<style lang="scss">
.panel-action-container {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  overflow: hidden;

  &__content {
    background-color: white;
    border-radius: 5px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;

    .container-box {
      height: 100%;
      overflow-y: auto;
      padding: 15px 0;
      box-sizing: border-box;

      > .table-content {
        border-top: 1px solid #ebeef5;
      }
    }

    .buttom-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      box-sizing: border-box;
      border-top: 1px solid #eee;
      background-color: white;
      z-index: 5;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      .btn + .btn {
        margin-left: 40px;
      }
    }
  }

  .action-bg {
    width: 100%;
    min-width: 700px;
    max-width: 60%;
    padding: 0 20px 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    margin: 20px auto;
    background-color: white;
    height: calc(100% - 40px);
    border-radius: 10px;
    margin-bottom: 0;
    overflow-y: auto;
    box-shadow: 2px 2px 10px 2px #0000000a;
    position: relative;
  }

  .ml-collapse {
    .slot-content {
      // 表单
      > div {
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 18px;
        }
      }
    }
  }
}
</style>
