<template>
  <div class="multifunctional-image-box" :style="useLayoutStyle">
    <!-- 展示图片 -->
    <div
      class="show-image-item"
      v-for="(file, index) in previewFileList"
      :key="'file' + index"
      :style="{
        width: uploadAreaWidth || uploadShowConfig.uploadAreaWidth,
        height: uploadAreaHeight || uploadShowConfig.uploadAreaHeight,
        minHeight: uploadAreaMinHeight,
      }"
    >
      <!-- 图片 -->
      <el-image class="image" :src="file.url" fit="cover" alt="">
        <template #error>
          <div class="upload-error-empty">
            <el-icon color="#dedfe0" :size="40"><PictureFilled /></el-icon>
          </div>
        </template>
      </el-image>

      <!-- 触摸浮现操作 -->
      <div class="item-actions">
        <!-- 查看 -->
        <span class="preview" @click="handlePictureCardPreview(file)">
          <el-icon><zoom-in /></el-icon>
        </span>
        <!-- 删除 -->
        <span class="delete" @click="deleteFile(file)">
          <el-icon><Delete /></el-icon>
        </span>
      </div>

      <!-- 插槽 -->
      <slot name="image-slot" :item="file" :index="index"></slot>
    </div>

    <!-- 上传操作 -->
    <div
      class="upload-box"
      :class="{ 'is-active': isActive || isBragBar }"
      :style="{
        '--width': uploadAreaWidth || uploadShowConfig.uploadAreaWidth,
        '--height': uploadAreaHeight || uploadShowConfig.uploadAreaHeight,
        '--min-height': uploadAreaMinHeight,
      }"
      :id="id"
      @click="trigerPaste"
      v-loading="uploadingStatus"
      v-show="showUploadBtn"
    >
      <p class="upload-icon">
        <el-icon><UploadFilled /></el-icon>
      </p>
      <p class="tips">{{ uploadShowConfig.tips }}</p>
      <p class="tips-do" @click.stop="onUpload">点击上传{{ describe }}</p>
    </div>
  </div>

  <el-image-viewer
    v-if="showImageViewer"
    :hide-on-click-modal="true"
    :initial-index="previewIndex"
    @close="closeViewer"
    :url-list="previewImageList"
  ></el-image-viewer>
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
  nextTick,
} from "vue";

const emits = defineEmits(["update:modelValue", "deleteFile", "onUploaded"]);

const props = defineProps({
  // 尺寸模式 normal||rect
  sizeMode: {
    type: String,
    default: "normal",
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
  uploadAreaWidth: {
    type: String,
    default: "",
  },
  uploadAreaHeight: {
    type: String,
    default: "",
  },
  uploadAreaMinHeight: {
    type: String,
    default: "",
  },
  // 排版方式
  layoutMode: {
    type: String,
    default: "flex",
  },
  // 单行显示数量
  limitColNum: {
    type: [Number, String],
    default: 5,
  },
  // 唯一标识
  id: {
    type: String,
    default: "file1",
  },
  // 是否是多选
  multiple: {
    type: Boolean,
    default: false,
  },
  // 最多上传数量
  maxCount: {
    type: [Number, String],
    default: 9,
  },
  // 描述
  describe: {
    type: String,
    default: "图片",
  },
  // 上传
  isUpload: {
    type: Boolean,
    default: true,
  },
  styleData: {
    type: [Object, String],
    default: () => ({}),
  },
  folder: {
    type: String,
    default: "",
  },
});

const { proxy } = getCurrentInstance();

/* 属性 */

// 图片排版布局模式
const useLayoutMode = computed(() => props.layoutMode);
// 图片排版布局模式 样式
const useLayoutStyle = computed(() => {
  const { limitColNum } = props;
  const style_mode = {
    flex: {
      display: "flex",
      "flex-direction": "row",
      "flex-wrap": "wrap",
    },
    grid: {
      display: "grid",
      "grid-template-columns": `repeat(${limitColNum}, 1fr)`,
    },
  };

  return style_mode[useLayoutMode.value];
});

/* 上传预览配置 */
// 模式配置
const showConfigs = {
  normal: {
    tips: "将文件拖到此处或点击粘贴",
    uploadAreaWidth: "182px",
    uploadAreaHeight: "122px",
  },
  rect: {
    tips: "拖拽或点击粘贴",
    uploadAreaWidth: "140px",
    uploadAreaHeight: "120px",
  },
};
const uploadShowConfig = computed(() => {
  const { sizeMode } = props;
  return showConfigs[sizeMode];
});

// 上传文件附带参数
const attachedParams = () => ({
  isUpload: props.isUpload,
  folder: props.folder,
});

// 文件列表
const fileList = ref(props.modelValue);
// 预览数据
const previewFileList = computed(() => {
  const { isUpload } = props;
  return fileList.value.map((item) => {
    const url = isUpload ? proxy.$previewFileUrl + item.relUrl : item.relUrl;
    const relUrl = item.relUrl;
    return {
      url,
      relUrl,
    };
  });
});

// 多选文件
const useMultiple = computed(() => {
  const { multiple, maxCount } = props;

  return maxCount >= 1 ? true : multiple;
});

// 上传操作的加载状态
const uploadingStatus = ref(false);
// 打开加载
const openUploading = () => {
  uploadingStatus.value = true;
};
// 关闭加载
const closeUploading = () => {
  uploadingStatus.value = false;
};

/* 是否到达上限 */
const isUploadMax = computed(() => fileList.value.length >= props.maxCount);
/* 剩余上传数量 */
const residueCount = computed(() => props.maxCount - fileList.value.length);
// 拖拽标识
const isBragBar = ref(false);

/* 是否显示 上传按钮 */
const showUploadBtn = computed(() => !isUploadMax.value);

// 回显赋值
watch(
  () => fileList.value,
  (nVal) => {
    emits("update:modelValue", nVal);
  },
  {
    deep: true,
  }
);
watch(
  () => props.modelValue,
  (nVal) => {
    fileList.value = nVal;
  },
  {
    deep: true,
  }
);

// 接口数据 转换 图片数据
const getFileList = (list) => {
  return list.map((item) => ({
    relUrl: item.relativePath,
  }));
};

// 点击上传
const onUpload = proxy.$bypass(async () => {
  // 限制
  if (isUploadMax.value) {
    return;
  }
  if (uploadingStatus.value) {
    return;
  }

  // 打开加载
  openUploading();

  try {
    const { isUpload } = props;

    // 剩余上传数量
    const count = residueCount.value;
    const result = await proxy.$changeImageFile({
      multi: useMultiple.value,
      isUpload,
      count,
      ...attachedParams(),
    });
    // console.log(result);
    const list = getFileList(result);

    fileList.value.push(...list);
    closeUploading();
    // console.log(fileList.value);
    // console.log(result);
    emits("onUploaded", fileList.value);
  } catch (error) {
    console.log(error);
    closeUploading();
  }
});

// 复制粘贴图片文件
// 触发功能
const trigerPaste = () => {
  setTimeout(() => {
    // 限制
    if (isUploadMax.value) {
      return;
    }
    isActive.value = true;
    if (!isActive.value) {
      return;
    }

    bindPaste();
  });
};

// 粘贴
const pasteFun = async function (e) {
  if (!isActive.value) {
    return;
  }

  if (uploadingStatus.value) {
    return;
  }
  openUploading();

  try {
    const data = e.clipboardData || window.clipboardData || {};

    // 剩余上传数量
    const count = residueCount.value;

    // console.log(data);
    // console.log(data.items);
    // 获取文件
    const items = data.items;
    // console.log(items);

    // 图片文件
    const imageItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const f = item.getAsFile();
      if (f) {
        const isImg = f.type.indexOf("image") !== -1;
        if (isImg) {
          imageItems.push(f);
        }
      }
    }

    const result = await proxy.$uploadFile(
      imageItems.slice(0, count),
      attachedParams()
    );
    // console.log(result);
    closeUploading();
    const list = getFileList(result);
    cancelActive();
    fileList.value.push(...list);
    emits("onUploaded", fileList.value);
    const events = idEvent();
    events.removeEventListener("paste", () => {});
  } catch (error) {
    console.log(error);
    closeUploading();
  }
};

const idEvent = () => document.getElementById(props.id);
// 绑定粘贴上传功能
const bindPaste = () => {
  const events = idEvent();
  // console.log(events);
  document
    .getElementsByTagName("body")[0]
    .removeEventListener("paste", pasteFun);
  document.getElementsByTagName("body")[0].addEventListener("paste", pasteFun);
  // events.removeEventListener("paste", pasteFun,false);
  // events.addEventListener("paste", pasteFun,false);
};

// 拖拽上传
// 触发 拖拽
const dropOver = async (e) => {
  stopDefualtFun(e);

  // 限制
  if (isUploadMax.value) {
    return;
  }

  if (uploadingStatus.value) {
    return;
  }
  openUploading();

  // 剩余上传数量
  const count = residueCount.value;

  const items = e.dataTransfer.files || [];

  const imageList = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const isImg = item.type.indexOf("image") !== -1;
    if (isImg) {
      imageList.push(item);
    }
  }

  try {
    // 筛选 字典
    const result = await proxy.$uploadFile(
      imageList.slice(0, count),
      attachedParams()
    );
    // console.log(result);
    const list = getFileList(result);

    isBragBar.value = false;
    fileList.value.push(...list);
    emits("onUploaded", fileList.value);
    closeUploading();
  } catch (error) {
    console.log(error);
    closeUploading();
  }
};
// 禁用默认
const stopDefualtFun = function (e) {
  const dragType = e.type;

  // 应用于目标元素，当拖拽元素进入时调用
  if (dragType == "dragover") {
    isBragBar.value = true;
  } else if (dragType == "dragleave") {
    // 应用于目标元素，当鼠标离开目标元素时调用
    isBragBar.value = false;
  }

  e.stopPropagation();
  e.preventDefault();
};

// 操作栏

// file参数
// relUrl 名称

// 预览窗口显示
const showImageViewer = ref(false);

// 预览数据
const previewImageList = computed(() => {
  const { isUpload } = props;
  return fileList.value.map((item) =>
    isUpload ? proxy.$previewFileUrl + item.relUrl : item.relUrl
  );
});
const previewIndex = ref(0);
// 预览图片
const handlePictureCardPreview = (file) => {
  previewIndex.value = fileList.value.findIndex(
    (item) => item.relUrl == file.relUrl
  );
  // console.log(file);
  // console.log(previewIndex.value);
  showImageViewer.value = true;
};
// 关闭预览
const closeViewer = () => {
  showImageViewer.value = false;
};

// 删除图片
const deleteFile = (file) => {
  // console.log(file);
  const index = fileList.value.findIndex((item) => item.relUrl == file.relUrl);

  // console.log(index);
  fileList.value.splice(index, 1);

  nextTick(() => {
    emits("deleteFile", { index, result: fileList.value });
    emits("onUploaded", fileList.value);
  });
};

// 点击全局body 失去焦点
// 焦点
const isActive = ref(false);
const cancelActive = () => {
  // console.log(1);
  isActive.value = false;
};

onMounted(() => {
  const events = idEvent();
  if (!events) {
    return;
  }

  // 解绑
  events.removeEventListener("dragover", stopDefualtFun, false);
  events.removeEventListener("dragenter", stopDefualtFun, false);
  events.removeEventListener("dragleave", stopDefualtFun, false);
  events.removeEventListener("drag", stopDefualtFun, false);
  events.removeEventListener("drop", dropOver, false);
  // 绑定
  events.addEventListener("dragover", stopDefualtFun, false);
  events.addEventListener("dragenter", stopDefualtFun, false);
  events.addEventListener("dragleave", stopDefualtFun, false);
  events.addEventListener("drag", stopDefualtFun, false);
  events.addEventListener("drop", dropOver, false);
  setTimeout(() => {
    // 全局
    document.removeEventListener("click", cancelActive, false);
    document.addEventListener("click", cancelActive, false);
    if (document.getElementsByClassName("el-dialog")[0]) {
      document
        .getElementsByClassName("el-dialog")[0]
        .removeEventListener("click", cancelActive, false);
      document
        .getElementsByClassName("el-dialog")[0]
        .addEventListener("click", cancelActive, false);
    }
  });
});
</script>

<style lang="scss">
.multifunctional-image-box {
  // 上传操作
  .upload-box {
    // width: 160px;
    // height: 100px;
    padding: 10px;
    border-radius: 6px;
    border: 1px dashed #9e9e9e4a;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    margin-bottom: 12px;
    user-select: none;
    overflow: hidden;
    position: relative;
    background-color: white;
    box-sizing: border-box;
    width: calc(var(--width) + 2px);
    height: calc(var(--height) + 2px);
    min-height: calc(var(--min-height) + 2px);

    &.is-active {
      border-color: #2196f3;
    }

    .upload-icon {
      font-size: 40px;
      color: #9e9e9e70;
    }
    .tips {
      font-size: 12px;
      color: #9e9e9e;
      line-height: 20px;
      text-align: center;
    }
    .tips-do {
      font-size: 12px;
      color: #2196f3;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .show-image-item {
    // width: 182px;
    // height: 122px;
    box-sizing: border-box;
    position: relative;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    // border: 1px dashed #9e9e9e4a;
    overflow: hidden;
    background-color: white;
    overflow: hidden;

    .el-image {
      width: 100%;
      height: 100%;

      .upload-error-empty {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    //操作栏
    .item-actions {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #00000057;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      font-size: 20px;
      justify-content: space-evenly;
      opacity: 0;
      transition: 300ms;

      span {
        cursor: pointer;
        color: white;
      }
    }

    &:hover {
      .item-actions {
        opacity: 1;
      }
    }
  }

  .el-image__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
</style>
