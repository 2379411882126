<template>
  <!-- 联动数据 -->
  <div class="ml-cascader custom-cascader">
    <el-cascader
      ref="cascaderRef"
      popper-class="ml-cascader-popper"
      clearable
      v-model="changeCascaderValue"
      :options="cascaderOptions"
      :props="useCascaderProps"
      @change="changeCascader"
      :disabled="disabled"
      :placeholder="usePlaceholder"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch, computed } from "vue";

const emits = defineEmits(["change", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: [Array, String],
    default: () => [],
  },
  disabled: {
    type: Boolean,
    defualt: false,
  },
  fetchListName: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  // 缺省占位符
  emptyPlaceholder: {
    type: String,
    default: "暂无",
  },
  // 外部数据
  data: {
    type: Array,
    default: () => [],
  },
  // 是否排除顶级（true:是、false:否）
  notTop: {
    type: Boolean,
    defualt: false,
  },
  cascaderProps: {
    type: Object,
    default: () => ({
      children: "children",
      label: "name",
      value: "id",
      // 父子级不关联
      checkStrictly: false,
    }),
  },
});

const { proxy } = getCurrentInstance();

/* cascader 选择数据 */
// ref
const cascaderRef = ref();
// 选中的数据
const changeCascaderValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    changeCascaderValue.value = nVal;
  },
  { deep: true }
);
// 数据
const cascaderOptions = ref(props.data);
watch(
  () => props.data,
  (nVal) => {
    cascaderOptions.value = nVal;
  },
  {
    deep: true,
  }
);
// 数据结构
const useCascaderProps = computed(() => {
  const {
    children = "children",
    label = "name",
    value = "id",
    checkStrictly = false,
  } = props.cascaderProps;

  return {
    children,
    label,
    value,
    // 父子级不关联
    checkStrictly,
  };
});
// 选择数据数据
const changeCascader = (res) => {
  const [node = {}] = cascaderRef.value.getCheckedNodes();
  const { pathLabels = "" } = node;

  emits("update:modelValue", res);
  emits("change", res, pathLabels);
};

// 占位符显示
const usePlaceholder = computed(() => {
  const { placeholder, disabled, emptyPlaceholder } = props;
  const val = changeCascaderValue.value ? changeCascaderValue.value[0] : "";

  // 显示缺省占位符
  const showEmptyPlaceholder = disabled && proxy.$isEmpty(val);
  return showEmptyPlaceholder ? emptyPlaceholder : placeholder;
});

// 获取 列表 数据
const getCascaderData = () => cascaderOptions.value;

// 设置 列表 数据
const setCascaderData = (callback) => {
  callback(cascaderOptions);
};

// 获取选中的nodes
const getCheckedNodes = () => cascaderRef.value.getCheckedNodes();

// 查询 数据数据
const getNormalList = async () => {
  try {
    const { notTop } = props;
    const res = await proxy.$storeDispatch(props.fetchListName, {
      notTop,
    });
    const result = res.result;
    cascaderOptions.value = result;
  } catch (error) {}
};

// 初始
const init = async () => {
  await getNormalList();
};

defineExpose({
  init,
  getCheckedNodes,
  getCascaderData,
  setCascaderData,
});
</script>

<style lang="scss" scoped></style>
