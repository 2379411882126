<template>
  <el-upload
    ref="uploadRef"
    class="upload-file"
    v-model:file-list="fileData"
    :on-change="changeFile"
    :on-preview="previewFile"
    :before-remove="deleteFile"
    :on-exceed="handleExceed"
    :auto-upload="false"
    :limit="limit"
    :accept="accept"
    :multiple="multiple"
  >
    <template #trigger>
      <el-button
        type="primary"
        :disabled="useDisabled"
        iconSize="18px"
        :icon="UploadFilled"
        >{{ uploadText }}</el-button
      >
    </template>
    <template #tip>
      <div class="el-upload__tip">
        <slot>
          {{ uploadTips }}
        </slot>
      </div>
    </template>
  </el-upload>

  <!-- 预览文件 -->
  <file-preview-dialog ref="previewFileDialogRef"></file-preview-dialog>
</template>
<script setup>
import filePreviewDialog from "@/components/file/preview/index.vue";

import { genFileId } from "element-plus";
import { ref, computed, watch, getCurrentInstance, nextTick } from "vue";
import { UploadFilled } from "@element-plus/icons-vue";
import { useGetMediumFileTypeModule } from "@/utils/mixin";
const emits = defineEmits([
  "update:files",
  "update:fileList",
  "previewFile",
  "deleteFile",
  "onUploaded",
]);

const props = defineProps({
  uploadTips: {
    type: String,
    default: "",
  },
  fileList: {
    type: Array,
    default: () => [],
  },
  limit: {
    type: Number,
    default: 1,
  },
  isRequest: {
    type: Boolean,
    default: true,
  },
  accept: {
    type: String,
    default: "",
  },
  folder: {
    type: String,
    default: "",
  },
  // 多选
  multiple: {
    type: Boolean,
    default: false,
  },
  // 上传模式 replace||push
  uploadMode: {
    type: String,
    default: "replace",
  },
  uploadText: {
    type: String,
    default: "文件上传",
  },
  // 上传拦截
  uploadInterceptCall: {
    type: Function,
    default: () => false,
  },
  // 是否禁用上传
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { getUploadFileScope } = useGetMediumFileTypeModule();

const { proxy } = getCurrentInstance();

/* 属性 */
const useDisabled = computed(() => props.disabled);

/* 上传操作信息 */
// ref
const uploadRef = ref();

/* 打开文件预览 */
// ref
const previewFileDialogRef = ref();

/**
 * 上传文件
 * [{name,url}]
 */
const fileData = ref(props.fileList);
watch(
  () => props.fileList,
  (nVal) => {
    fileData.value = nVal;
  }
);

/* 是否到达上传上限 */
const isUploadMaxStop = computed(() => fileData.value.length >= props.limit);

/* 是否禁用上传 */
const isDisabledUpload = computed(() => isUploadMaxStop.value);

/* 当超出限制时，执行的钩子函数 */
const handleExceed = (files) => {
  uploadRef.value.clearFiles();

  const file = files[0];
  file.uid = genFileId();
  uploadRef.value.handleStart(file);
};

// 操作文件
const changeFile = async (file) => {
  const { uploadInterceptCall } = props;
  const files = file.raw;

  // 上传拦截
  const interceptRes = uploadInterceptCall(files);
  // console.log(interceptRes);
  if (interceptRes) {
    return;
  }

  // 进行数据甄类
  const scopeItem = getUploadFileScope(files);

  // 请求
  if (props.isRequest) {
    proxy
      .$uploadFile([files], {
        folder: props.folder,
      })
      .then((res) => {
        // console.log(res);
        const result = res.map((item) => {
          return {
            name: item.originalName,
            url: item.url,
            relUrl: item.relativePath,
            ...scopeItem,
          };
        });
        // const first = result[0] || {};
        // // 确定本地文件
        // const locaIndex = fileData.value.findIndex(
        //   (item) => item.name == first.originalName
        // );
        // fileData.value.splice(locaIndex, 1, first);
        fileData.value = fileData.value.filter((d) => !d.raw);

        // 替换
        if (props.uploadMode == "reaplce") {
          fileData.value = result;
        } else {
          fileData.value.push(...result);
        }

        // console.log(fileData.value);
        emits("update:fileList", fileData.value);
        emits("onUploaded", fileData.value);
      })
      .catch((res) => {
        // console.log(res);
        // 确定本地文件
        const locaIndex = fileData.value.findIndex(
          (item) => item.name == files.name
        );
        fileData.value.splice(locaIndex, 1);
      });
  } else {
    // 静态
    emits("update:files", files);
  }
};

// 查看文件
const previewFile = (file) => {
  // console.log(file);
  // 进行数据甄类
  const { scopeType } = getUploadFileScope(file);
  const { relUrl, name } = file;

  previewFileDialogRef.value.openFileDialog(
    {
      filePath: relUrl || name,
      fileType: scopeType,
    },
    file
  );

  emits("previewFile", file);
};

// 监听删除文件
const deleteFile = (file, files) => {
  // console.log(props.isRequest);
  // console.log(file, files);
  // fileData.value.splice(index, 1);

  if (props.isRequest) {
    emits("update:fileList", files);
  } else {
    emits("update:files", null);
  }

  emits("deleteFile", file);
  nextTick(() => {
    emits("onUploaded", fileData.value);
  });
};

// 清除文件
const clearFiles = () => {
  uploadRef.value.clearFiles();
};

defineExpose({
  clearFiles,
});
</script>

<style lang="scss">
.upload-file {
  width: 100%;
  .el-upload__tip {
    color: #9e9e9e;
  }

  .el-upload-list__item-status-label {
    line-height: 36px;
  }
  .el-upload-list__item-name {
    padding-right: 25px;
  }

  .el-upload-list__item-file-name {
    word-break: break-all;
    white-space: normal !important;
    display: flex;
    text-align: left;
  }

  .intercept {
    width: 100%;
    height: 100%;
    background-color: red;
  }
}
</style>
