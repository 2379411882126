<template>
  <!-- 下拉选择+底部分页懒加载数据+动态数据搜索 -->
  <div class="select-box">
    <el-select
      ref="selectRef"
      :disabled="disabled"
      :popper-append-to-body="false"
      :filter-method="filterMethod"
      :popper-class="popperClass"
      v-model="value"
      :size="size"
      :placeholder="usePlaceholder"
      :filterable="filterable"
      clearable
      :multiple="multiple"
      @change="changeSelect"
      @visible-change="showPopper"
      @clear="clearFilter"
    >
      <template v-if="hasSlot('prefix')" #prefix>
        <slot
          name="prefix"
          :data="{
            result: options,
            props: masterProps,
          }"
        >
        </slot>
      </template>

      <slot
        :data="{
          result: options,
          props: masterProps,
        }"
      >
        <el-option
          v-for="(item, index) in options"
          :key="'options' + index"
          :label="item[masterProps.labelKey] || '···'"
          :value="item[masterProps.valueKey] || '···'"
        >
          {{ item[masterProps.labelKey] || "···" }}
        </el-option>
      </slot>

      <div class="select-loading" v-if="showLoading != 'ready'">
        <el-icon color="gray" class="loading-icon" v-if="showLoading"
          ><Loading
        /></el-icon>
        <span v-else>已经到底了 ~</span>
      </div>
    </el-select>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  computed,
  watch,
  getCurrentInstance,
  nextTick,
} from "vue";
const { proxy } = getCurrentInstance();

const selectRef = ref();

const emits = defineEmits([
  "change",
  "scrolltolower",
  "update:modelValue",
  "update:updateLabel",
]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Array],
    default: "",
  },
  size: {
    type: String,
    default: "default",
  },
  placeholder: {
    type: String,
    default: "请选择",
  },
  // 缺省占位符
  emptyPlaceholder: {
    type: String,
    default: "暂无",
  },
  // 多选
  multiple: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectProps: {
    type: Object,
    default: () => ({
      labelKey: "label",
      valueKey: "value",
    }),
  },
  filterable: {
    type: Boolean,
    default: true,
  },
  // 唯一class
  popperClass: {
    type: String,
    default: "select-popper",
  },
  // 列表请求函数名
  storeDispatchName: {
    type: String,
    default: "",
  },
  /* 请求方式类型 */
  // 接口/尾后访问
  isPathVariable: {
    type: Boolean,
    default: false,
  },
  // 尾后访问 值
  pathVariableValue: {
    type: [String, Number],
    default: "",
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  // 筛选参数名称
  filterName: {
    type: String,
    default: "name",
  },
  // 筛选参数名称
  filterId: {
    type: String,
    default: "id",
  },
  // 筛选参数中 固定查询
  lastingParams: {
    type: Function,
    default: () => {},
  },
  // label
  updateLabel: {
    type: String,
    default: "",
  },
});

// 使用的语言参数
const masterProps = computed(() => {
  return props.selectProps;
});
// 选择器值
let value = ref(props.modelValue);

// 选择数据
const options = ref([]);

// 赋值 名称ID 筛选参数数据
const updateFilterNameIdParams = () => {
  // 回显时 赋值选中字段 以免分页状态下丢失选项
  const findItem =
    options.value.find(
      (item) => item[masterProps.value.valueKey] == value.value
    ) || {};

  if (props.filterable) {
    params[props.filterName] = findItem[masterProps.value.labelKey] || "";
    params[props.filterId] = value.value || "";
  }
};

// 赋值
watch(
  () => props.modelValue,
  (nVal) => {
    if (props.multiple) {
      value.value = nVal;
    } else {
      value.value = nVal ? String(nVal) : nVal;
    }

    // console.log('回显时 赋值选中字段')
    // console.log(value.value)
    // console.log(params)
    updateFilterNameIdParams();
    proxy.$forceUpdate();
  }
);
// 回显
watch(
  () => value.value,
  (nVal, oldVal) => {
    emits("update:modelValue", nVal);

    if (!props.multiple) {
      const item =
        options.value.find((d) => d[masterProps.value.valueKey] == nVal) || {};
      emits("update:updateLabel", item[props.updateLabel]);
    }
  }
);

// 占位符显示
const usePlaceholder = computed(() => {
  const { placeholder, disabled, emptyPlaceholder } = props;
  const selectValue = value.value || "";
  const val =
    typeof selectValue == "string"
      ? selectValue
      : (selectValue || []).join(",");

  // 显示缺省占位符
  const showEmptyPlaceholder = disabled && proxy.$isEmpty(val);
  return showEmptyPlaceholder ? emptyPlaceholder : placeholder;
});

// 是否进行了change
const actionChange = ref(false);

// 重置懒加载
watch(
  () => options.value,
  (nVal) => {
    showLoading.value = "ready";
  }
);

// 加载参数
const params = reactive({
  pageNum: 1,
  pageSize: 10,
});
// 当前数据
const currList = ref([]);

// 监听加载
watch(
  () => currList.value,
  (nVal) => {
    // console.log(nVal);
    // 暂无数据|当前页无数据
    if (!nVal.length) {
      if (params.pageNum == 1) {
        showLoading.value = "ready";
      } else {
        showLoading.value = false;
      }
    }
  }
);

// 查询下拉列表接口
const getSelectList = (requestType) => {
  if (requestType == "init") {
    params.pageNum = 1;
  }

  for (let i in props.lastingParams()) {
    const val = props.lastingParams()[i];
    if (props.filterable) {
      params[i] = val;
    }
  }

  // 请求参数
  let paramsResult = params;

  // 路径后访问
  if (props.isPathVariable) {
    paramsResult = props.pathVariableValue;
  }

  // console.log(paramsResult);

  return proxy
    .$storeDispatch(props.storeDispatchName, paramsResult)
    .then((res) => {
      const valueKey = masterProps.value.valueKey;

      const result = (res.result || []).map((item) => {
        item[valueKey] = (item[valueKey] || "").toString();
        return item;
      });
      currList.value = result;

      // 无数据&&带值筛选
      const empty1 =
        !result.length && (params[props.filterId] || params[props.filterName]);
      // 初始赋值
      const empty2 = !result.length && requestType == "init";
      if (empty1 || empty2) {
        if (props.multiple) {
          value.value = [];
        } else {
          value.value = "";
        }
      }

      // 处理
      if (value.value && result.length) {
        const isHave = result.find(
          (d) => d[masterProps.value.valueKey] == value.value
        );

        // 虽然赋值项有值 但却没有匹配的数据
        if (!isHave) {
          value.value = "";
        }
      }

      if (requestType == "init") {
        options.value = result;
      } else if (requestType == "update") {
        options.value.push(...result);
      }
    })
    .catch((res) => {
      if (showLoading.value != "ready") {
        params.pageNum -= 1;
        showLoading.value = false;
      }
    });
};

// 计时器
let filterTimeId = ref(null);
// 清除计时
const clearFilterTimeId = () => {
  clearTimeout(filterTimeId.value);
  filterTimeId.value = null;
};
// 筛选操作方法
const filterMethod = (e) => {
  // console.log("触发筛选", e);

  if (props.filterable) {
    params[props.filterName] = e;
  }

  // 空值
  if (!e) {
    if (!actionChange.value) {
      return;
    }
  }

  // 是否在有效的筛选
  const isHasFilter = !!e;

  clearFilterTimeId();

  filterTimeId.value = setTimeout(() => {
    // 重置
    clearFilterTimeId();

    // 在拦截范围内
    if (isHasFilter) {
      if (props.multiple) {
        value.value = [];
      } else {
        value.value = "";
      }
    }

    // 参数不相同
    if (props.filterId !== props.filterName) {
      if (props.filterable) {
        params[props.filterId] = "";
      }
    }

    // console.log(value.value);
    // 关闭懒加载提示
    showLoading.value = false;
    getSelectList("init");
  }, 300);
};
// 清除
const clearFilter = () => {
  resetValue();
  getSelectList("init");
};

// 监听选中项
const changeSelect = (e) => {
  actionChange.value = true;

  resetValue();
  value.value = e;
  emits("change", e);
  // console.log("选中", e);
};
// 重置 筛选数据
const resetValue = () => {
  // 查询整个列表数据
  if (props.filterable) {
    params[props.filterName] = "";
    params[props.filterId] = "";
    // console.log("清除");
  }
};

// 是否显示加载
const showLoading = ref("ready");

// 触碰底部
const scrolltolower = () => {
  // console.log("scrolltolower");
  // console.log(props.popperClass);
  if (currList.value.length) {
    // 显示懒加载
    showLoading.value = true;
    params.pageNum += 1;
    resetValue();
    getSelectList("update");
  } else {
    showLoading.value = false;
  }

  // emits("scrolltolower", showLoading);
};

// 是否已设置监听scroll
let isSetScroll = ref(false);

// 监听popper显隐
const showPopper = (show) => {
  // const filterId = params[props.filterId]
  // const label = params[props.filterName]

  nextTick(() => {
    if (show) {
      // console.log(show)
      // console.log(options.value)
      // console.log(options.value.slice(0,10))
      // 筛选前十个
      const isCurrPage = options.value
        .slice(0, 10)
        .filter((item) => item[masterProps.value.valueKey] == value.value);
      // console.log(value.value)
      // console.log(filterId)
      // console.log(label)
      // console.log(isCurrPage)
      // console.log(isSetScroll)

      // console.log(isCurrPage);

      // 不是当前页
      if (!isCurrPage.length) {
        if (props.filterable) {
          params[props.filterId] = value.value;
        }
      } else {
      }

      // 初始
      if (!isSetScroll.value) {
        const SELECTWRAP_DOM = document.querySelector(
          `.${props.popperClass} .el-select-dropdown__wrap`
        );
        if (SELECTWRAP_DOM) {
          SELECTWRAP_DOM.addEventListener("scroll", function () {
            const CONDITION =
              this.scrollHeight - this.scrollTop <= this.clientHeight;

            // 触底
            if (CONDITION) {
              scrolltolower();
            }
          });
          isSetScroll.value = true;
        }
      }
    } else {
      actionChange.value = false;
      if (!value.value) {
        clearFilter();
      }
    }

    // 关闭懒加载提示
    showLoading.value = "ready";
  });
};

// 判断 是否存在slot
const hasSlot = (name) => {
  return !!proxy.$slots[name];
};

/* 查询选中项 */
const getCurrOption = (key) => {
  return options.value.find((d) => d[masterProps.value.valueKey] == key);
};

defineExpose({
  getSelectList,
  getCurrOption,
  options,
});
</script>

<style lang="scss" scoped>
.select-box {
  width: 100%;

  // 查看select 样式
  ::v-deep(.el-select) {
    width: 100% !important;
    .el-select__tags {
      &.is-disabled {
        cursor: text !important;
      }
      .el-select__input.is-disabled {
        cursor: text !important;
      }
    }
  }

  ::v-deep(.el-input) {
    width: 100% !important;
    &.is-disabled .el-select__caret {
      cursor: text !important;
    }
  }
}
.select-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5px;

  .loading-icon {
    animation: rotate 1s linear infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  span {
    font-size: 12px;
    color: var(--el-color-info);
  }
}
</style>
