<template>
  <div class="select-active-types">
    <ml-select
      :options="options"
      :disabled="disabled"
      :selectProps="selectProps"
      v-model="value"
    >
    </ml-select>
  </div>
</template>

<script setup>
import { computed, ref, watch, getCurrentInstance, onMounted } from "vue";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
  // 使用的 类型
  type: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  selectProps: {
    type: Object,
    default: () => ({
      labelKey: "codeName",
      valueKey: "code",
    }),
  },
});

const { proxy } = getCurrentInstance();

// 当前使用的 类型
const nowUseType = computed(() => props.type);
const value = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    value.value = nVal;
  }
);
watch(
  () => value.value,
  (nVal) => {
    emits("update:modelValue", nVal);
  }
);

/* 查询 */
// 选择项列表
const options = ref([]);

// 查询 获取字典列表
const getDictList = async () => {
  options.value = await proxy.$getActiveTypeNameLists(nowUseType.value);
};

// 获取options
const getOptions = () => options.value;

// 初始
const init = async () => {
  await getDictList();
};

onMounted(() => {
  if (nowUseType.value) {
    init();
  }
});

defineExpose({
  init,
  getOptions,
});
</script>

<style lang="scss" scoped>
.select-active-types {
  width: 100%;
}
</style>
