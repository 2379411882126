<!-- 询问 确认窗口 -->
<template>
  <tool-dialog
    size="small"
    :btnType="useBtnType"
    ref="dalogRef"
    @cancel="cancelBtn"
    @confirm="confirmBtn"
    @dialogClosed="dialogClose"
    :confirmLoadingText="useConfirmLoadingText"
    :title="title"
  >
    <div class="ask-dialog-box">
      <div class="tips-round-box">
        <div class="icon">
          <el-image :src="useIcon"></el-image>
        </div>
        <div class="tips-text">
          <p>{{ askTips[0] }}</p>
          <p v-if="askTips[1]">{{ askTips[1] }}</p>
        </div>
      </div>

      <!-- 插槽 -->
      <slot></slot>
    </div>
  </tool-dialog>
</template>

<script setup>
import { computed, ref } from "vue";
const emits = defineEmits(["confirm", "cancel", "dialogClose"]);

const props = defineProps({
  // 弹窗类型 warning||danger
  type: {
    type: String,
    default: "warning",
  },
  askTips: {
    type: Array,
    default: ["", ""],
  },
  title: {
    type: String,
    default: "",
  },
  // 确认时 加载的提示文字
  confirmLoadingText: {
    type: String,
    default: "",
  },
});

// 弹窗类型
const useType = computed(() => props.type);

// 图标
const useIcon = computed(() => {
  if (useType.value) {
    return require(`@/assets/images/${useType.value}-icon.png`);
  }
});

// 确认按钮文字
const useConfirmLoadingText = computed(() => props.confirmLoadingText);

// 按钮颜色
const useBtnType = computed(() => {
  const types = {
    warning: "primary",
    danger: "danger",
  };
  return types[useType.value];
});

/* 弹窗 */
// ref
const dalogRef = ref();

// 打开
const openDialog = () => {
  dalogRef.value.showDialog = true;
};

// 关闭
const closeDialog = () => {
  dalogRef.value.showDialog = false;
};

// 窗口按钮操作
// 确认
const confirmBtn = (call) => {
  emits("confirm", call);
};
// 取消
const cancelBtn = () => {
  emits("cancel");
};

// 监听窗口关闭
const dialogClose = () => {
  emits("dialogClose");
};

defineExpose({
  openDialog,
  closeDialog,
  confirmBtn,
  cancelBtn,
});
</script>

<style lang="scss" scoped>
// 提示窗口
.ask-dialog-box {
  padding: 0 10px;
  padding-bottom: 10px;
  .tips-round-box {
    padding: 15px 10px 15px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-top: 3px;
    }

    .tips-text {
      line-height: 25px;
      color: #333333;
      font-size: 14px;
    }
  }

  // 删除
  .view-label {
    line-height: 20px;
    margin: 10px 0 3px 0;
  }

  // 拉去文件
  .file-box {
    padding: 20px 10px;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    .file-icon {
      width: 45px;
      height: 50px;
      margin-right: 20px;

      .el-image {
        height: 100%;
        width: 100%;
      }
    }
  }

  // 删除确认表单
  .confirm-form {
    .el-form-item {
      margin: 0;
    }
  }
}
</style>
