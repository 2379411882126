<template>
  <div class="expand-more-container">
    <div class="expand-more-body">
      <div
        :class="`visible-area visible${elementKey}`"
        :style="{ height: useVisibleHeight }"
      >
        <slot></slot>
      </div>
    </div>

    <div class="expand-more-handle" v-if="showExpandHandle">
      <view class="handle-triggle" @click="handleExpand">
        <el-icon class="d-up" v-if="expandStatus"><DArrowRight /></el-icon>
        <el-icon class="d-down" v-else><DArrowRight /></el-icon>
        <span style="font-size: 12px"> {{ expandText }}</span>
      </view>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import { DArrowRight } from "@element-plus/icons-vue";

const props = defineProps({
  visibleHeight: {
    type: String,
    default: "",
  },
  elementKey: {
    type: String,
    default: "",
  },
});

// 监听实体
const observer = ref();

// 属性信息
const useVisibleHeight = ref(props.visibleHeight);
const getVisibleHeight = () => {
  const result = expandStatus.value ? "auto" : props.visibleHeight;
  return result;
};

// 展开状态
const expandStatus = ref(false);
// 展开文本
const expandText = computed(() => {
  return expandStatus.value ? "收起" : "展开";
});
// 展开显示状态
const showExpandHandle = ref(false);
const geShowExpandHandle = () => {
  // 获取可视区域的 scroll高度
  const scrollHeight = getVibleElementDom.value.scrollHeight;
  // 获取可视区域的超出界限高度
  const limitHeight = getVibleLimitHeight();

  // 校验
  if (scrollHeight > limitHeight) {
    // 相差
    const diffHeight = scrollHeight - limitHeight;
    return diffHeight >= 5;
  } else {
    // 隐藏
    return false;
  }
};

// 操作展开状态
const handleExpand = () => {
  expandStatus.value = !expandStatus.value;
  useVisibleHeight.value = getVisibleHeight();
};

// 获取可视区域的dom
const vibleElementDom = ref();
const getVibleElementDom = computed(() => vibleElementDom.value || {});
// 更新 vibleElementDom
const updatevibleElementDom = () => {
  vibleElementDom.value =
    document.getElementsByClassName(`visible${props.elementKey}`)[0] || {};
};
// 更新整体参数
const updateExpandState = () => {
  updatevibleElementDom();
  nextTick(() => {
    setTimeout(() => {
      // 赋值
      showExpandHandle.value = geShowExpandHandle();

      useVisibleHeight.value = showExpandHandle.value
        ? getVisibleHeight()
        : "auto";

      // console.log("更新 expand 整体参数");
    });
  });
};

// 获取可视区域的超出界限高度
const getVibleLimitHeight = () => {
  const { visibleHeight } = props;
  const [height] = visibleHeight.split("px");
  return Number(height || 0);
};

onMounted(() => {
  // 更新状态
  updateExpandState();

  // 设置监听器
  const newObserver = new MutationObserver(updateExpandState);
  newObserver.observe(vibleElementDom.value, {
    childList: true,
    subtree: true,
  });
  observer.value = newObserver;
});

onBeforeUnmount(() => {
  // 关闭 监听器
  observer.value.disconnect();
});
</script>

<style lang="scss" scoped>
.expand-more-container {
  .expand-more-body {
    .visible-area {
      overflow: hidden;
    }
  }

  .expand-more-handle {
    .handle-triggle {
      color: var(--el-color-primary);
      cursor: pointer;
      display: flex;
      align-items: center;

      .el-icon {
        margin-right: 4px;

        &.d-down {
          transform: rotate(90deg);
        }
        &.d-up {
          transform: rotate(270deg);
        }
      }
    }
  }
}
</style>
