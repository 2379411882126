<template>
  <div class="export-btn">
    <auth-button
      type="default"
      menuCode="normal_export"
      @handleClick="reportFile"
      :disabled="disabledReportBtn"
      v-if="!exportBtnShow"
      >导出</auth-button
    >
    <div class="click-btn" @click="terminateRequest" v-if="exportBtnShow">
      <ml-button type="danger" menuCode="normal_export" :loading="true"
        >终止导出</ml-button
      >
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed } from "vue";
import { usePageModule } from "@/utils/mixin";
const { proxy } = getCurrentInstance();

const { mainStore } = usePageModule();

const props = defineProps({
  fetchName: {
    type: String,
    default: "",
  },
  checkTableDataIds: {
    type: Array,
    default: () => [],
  },
  exportFileName: {
    type: String,
    default: "文件",
  },
  exportFileType: {
    type: String,
    default: "",
  },
});

// 导出按钮展示
const exportBtnShow = ref(false);
// 禁用导出
const disabledReportBtn = computed(() => !props.checkTableDataIds.length);

// 导出
const reportFile = async () => {
  exportBtnShow.value = true;
  const { checkTableDataIds, fetchName, exportFileName, exportFileType } =
    props;
  try {
    const result = await proxy.$storeDispatch(fetchName, checkTableDataIds);
    // console.log(result);

    // 已取消请求
    if (result.code == "ERR_CANCELED") {
      exportBtnShow.value = false;
      return;
    }
    // const res = await proxy.$storeDispatch(fetchName, checkTableDataIds);
    // const result = res.result;
    if (result) {
      proxy.$successMsg("文件正在下载中");
      exportBtnShow.value = false;
    }

    // console.log(result);
    // 下载
    // proxy.$downloadFile({
    //   href: result,
    //   downloadType: "report",
    // });

    // 导出文件类型
    const currExportFileType = "." + exportFileType;

    const showTime = proxy.$timeStampToTime(Date.now());
    proxy.$downloadFileBlob(
      result,
      showTime + `${exportFileName}${currExportFileType}`
    );
    exportBtnShow.value = false;
  } catch (error) {
    console.log(error);
    exportBtnShow.value = false;
  }
};

// 终止
const terminateRequest = () => {
  // 终止请求
  if (mainStore.httpSource) {
    mainStore.httpSource.cancel();
  }
  exportBtnShow.value = false;
};
</script>

<style lang="scss" scoped>
.export-btn {
  .click-btn {
    cursor: pointer;
    .el-button {
      background-color: #fc2727;
    }
  }
}
</style>
