// 常规数据处理

import { defineStore } from "pinia";

// 当前本地状态默认数据
const currStateDefaults = () => ({
  
  // 全局页面的显示刷新状态
  globalViewRefresh: true,
});

export default defineStore({
  id: "data",
  state: () => currStateDefaults(),
  actions: {},
  getters: {},
});
