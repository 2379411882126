<template>
  <!-- 页面手机验证 -->
  <div class="page-code" @keydown="onKeyDown">
    <div class="code-form">
      <ml-form
        :showBtn="false"
        ref="formCodeRef"
        :model="formCodeData"
        :rules="formCodeRules"
        label-position="top"
        size="large"
        class="inline-block"
      >
        <div class="text">
          验证码已发送到您的手机{{ currentUserPhone.phonePwd }}，请填写验证码
        </div>
        <el-form-item prop="code">
          <phone-verification-input
            v-model="formCodeData.code"
            placeholder="请输入验证码"
            :phone="currentUserPhone.phone"
            :accessType="accessType"
          />
        </el-form-item>

        <ml-button
          useLoading
          class="login-btn"
          type="primary"
          size="large"
          @submit="codeSubmit"
          >确定</ml-button
        >
      </ml-form>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, reactive } from "vue";
import { ruleNormalPhone } from "@/utils/formRules";
import * as dataDictionary from "@/utils/dataDictionary";
// import { usePageModule } from "@/utils/mixin";

const emits = defineEmits(["resetVerifyCode"]);

const props = defineProps({
  // 访问凭证类型
  accessType: {
    type: String,
    default: "",
  },
});

const { proxy } = getCurrentInstance();
const userInfo = ref({});
// 当前登录用户手机号码
const currentUserPhone = computed(() => {
  const phone = userInfo.value.phone;
  const phonePwd = proxy.$telHide(phone);
  return {
    phone,
    phonePwd,
  };
});

// ****************************************************************************************************
// 表单验证码 START
// ****************************************************************************************************

const formCodeRef = ref();

const formCodeData = reactive({
  phone: "",
  code: "",
  clearCode: true,
  codeType: "",
});
const formCodeRules = {
  phone: ruleNormalPhone(),
  code: [
    {
      message: "请先输入短信验证码",
      required: true,
    },
  ],
};

//  确定
const codeSubmit = proxy.$bypass(async (call) => {
  // 赋值手机号
  formCodeData.phone = currentUserPhone.value.phone;
  formCodeData.codeType = dataDictionary[props.accessType];

  const testRes = await proxy.$testForm(call, formCodeRef.value);
  if (!testRes) {
    return;
  }

  // 校验手机号码
  const testCodeRes = await proxy.$verifySmsVerificationCode(formCodeData);
  if (!testCodeRes) {
    call();
    return;
  }

  setStoreage30mine();

  initReckonTime();
});

// 设置30分钟
const setStoreage30mine = () => {
  // 记录时间节点
  const currentDateTimeRecord = Date.now();

  // 30分钟时间戳
  const halfHourStamp = 1000 * 60 * 30;
  // 30分钟后
  const halfHourDate = currentDateTimeRecord + halfHourStamp;

  // 模拟记录
  proxy.$setItem(props.accessType, halfHourDate);

  // 赋值
  reckonTimeState.dateTimeEndStamp = halfHourDate;
};

// ****************************************************************************************************
// 表单验证码 END
// ****************************************************************************************************

// ****************************************************************************************************
// 数据处理 START
// ****************************************************************************************************

// 计时状态
const reckonTimeDefault = () => ({
  dateTimeId: null, //记录时间ID
  dateTimeEndStamp: 0, //结束时间戳
});
const reckonTimeState = reactive(reckonTimeDefault());

// 初始计时
const initReckonTime = () => {
  const { dateTimeEndStamp } = reckonTimeState;

  // 当前时间戳
  let nowDateStamp = Date.now();

  // 比较
  const outsideDiffResult = nowDateStamp >= dateTimeEndStamp;
  // console.log(outsideDiffResult);

  resetVerifyCode(outsideDiffResult);

  // 调试
  // // 重置
  // closeReckonTime();

  // console.log(dateTimeEndStamp);
  // if (dateTimeEndStamp) {
  //   startReckonTime(dateTimeEndStamp);
  // } else {
  //   resetVerifyCode(false);
  // }
};
// 开始计时
const startReckonTime = (dateTimeEndStamp) => {
  // 当前时间戳
  let nowDateStamp = Date.now();

  // 比较
  const outsideDiffResult = nowDateStamp >= dateTimeEndStamp;

  // 拦截
  if (outsideDiffResult) {
    resetVerifyCode(outsideDiffResult);
    return;
  }

  // 赋值
  reckonTimeState.dateTimeId = setInterval(() => {
    nowDateStamp = Date.now();

    // 比较
    const diffResult = nowDateStamp >= dateTimeEndStamp;

    // 过期
    if (diffResult) {
      // 重置
      closeReckonTime();
    }

    resetVerifyCode(diffResult);
  }, 1000);
};
// 结束计时
const closeReckonTime = () => {
  clearInterval(reckonTimeState.dateTimeId);

  // 重置
  proxy.$updateParams(reckonTimeState, reckonTimeDefault());
};

// 重新验证 短信码 回调
const resetVerifyCode = (result) => {
  emits("resetVerifyCode", result);
};

// ****************************************************************************************************
// 数据处理 END
// ****************************************************************************************************

// 查询结束的时间节点
const getDateTimeEndLine = () => {
  // 模拟读取
  const dateTimeEndLine = proxy.$getItem(props.accessType);

  // 赋值
  reckonTimeState.dateTimeEndStamp = Number(dateTimeEndLine);
  initReckonTime();
};

// 初始
const init = async () => {
  getDateTimeEndLine();
};

// 监听回车
const onKeyDown = (e) => {
  if (e.keyCode == 13) {
    codeSubmit();
  }
};

defineExpose({
  init,
  setStoreage30mine,
});
// *********************************************************************************************************
// 验证码 END
// ***************************************************************************************************************
</script>

<style lang="scss" scoped>
.page-code {
  height: 100%;
  background-color: #f5f7fa;

  display: flex;
  align-items: center;
  justify-content: center;

  .code-form {
    width: 420px;
    // height: 300px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 8px;
    padding: 40px 40px 40px;
    box-sizing: border-box;
    margin-top: -200px;

    .text {
      margin-bottom: 30px;
    }
    .login-btn {
      width: 100%;
      padding: 1px 0;
      height: 38px !important;
      line-height: 38px;
      margin-top: 50px;

      &:hover {
        transition: 300ms;
        background-color: #004dff;
      }
    }
  }
}
</style>
