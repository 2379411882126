<template>
  <div
    class="custom-form ml-form"
    :class="`${!inline ? 'inline-block' : ''} ${
      bold ? 'is-label-bold' : ''
    } ${className}`"
  >
    <el-form
      ref="formRef"
      :model="model"
      :rules="rules"
      :size="formSize"
      :inline="inline"
      :label-position="labelPosition"
      :status-icon="statusIcon"
      :label-width="labelWidth"
      :validate-on-rule-change="validateOnRuleChange"
    >
      <slot></slot>
      <slot name="btn">
        <div class="btn-box" v-if="showBtn">
          <el-button
            :loading="cancelShowLoading"
            @click="cancel"
            v-if="showCancel"
            >{{ cancelText }}</el-button
          >
          <el-button
            type="primary"
            :loading="showLoading"
            :disabled="disabledConfirmBtn"
            @click="confirm"
            v-if="showConfirm"
            >{{ confirmText }}</el-button
          >
          <slot name="btn-append"></slot>
        </div>
      </slot>
    </el-form>
  </div>
</template>

<script setup>
import { ref, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const props = defineProps({
  model: {
    type: Object,
    default: () => ({}),
  },
  rules: {
    type: Object,
    default: () => ({}),
  },
  // 用于控制该表单内组件的尺寸 'large' | 'default' | 'small'
  formSize: {
    type: String,
    default: "default",
  },
  // 	行内表单模式 item独占一行 | item尾后排序
  inline: {
    type: Boolean,
    default: false,
  },
  // 表单域标签的位置 'left' | 'right' | 'top'
  labelPosition: {
    type: String,
    default: "right",
  },
  // 标签的长度
  labelWidth: {
    type: String,
    default: "100px",
  },
  // 是否在输入框中显示校验结果反馈图标
  statusIcon: {
    type: Boolean,
    default: false,
  },
  // 是否显示操作按钮
  showBtn: {
    type: Boolean,
    default: false,
  },
  showConfirm: {
    type: Boolean,
    default: true,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
  cancelText: {
    type: String,
    default: "取消",
  },
  confirmText: {
    type: String,
    default: "确定",
  },
  // 是否在 rules 属性改变后立即触发一次验证
  validateOnRuleChange: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  // 使用取消中的 返回
  isCancelBack: {
    type: Boolean,
    default: true,
  },
  // 使用取消的加载
  useCancelLoading: {
    type: Boolean,
    default: false,
  },
  // 禁用确认按钮
  disabledConfirmBtn: {
    type: Boolean,
    default: false,
  },
  // class名称
  className: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["cancel", "confirm"]);

const { proxy } = getCurrentInstance();

const router = useRouter();

const { model, rules, formSize, labelPosition, statusIcon } = toRefs(props);
// console.log(model, rules)
const formRef = ref();

// 加载状态
const showLoading = ref(false);
const cancelShowLoading = ref(false);

// 操作按钮
const cancel = () => {
  if (props.isCancelBack) {
    router.go(-1);
    return;
  }

  // 使用
  if (props.useCancelLoading) {
    if (cancelShowLoading.value) {
      return;
    }
    cancelShowLoading.value = true;
    emit("cancel", call);
    return;
  }

  emit("cancel");
};
const confirm = proxy.$bypass(() => {
  // 禁用
  if (props.disabledConfirmBtn) {
    return;
  }

  if (showLoading.value) {
    return;
  }
  showLoading.value = true;
  emit("confirm", call);
});
// 操作回调
const call = () => {
  showLoading.value = false;
  cancelShowLoading.value = false;
};

// 对整个表单的内容进行验证。 接收一个回调函数，或返回 Promise。
const validate = (callBack) => {
  formRef.value.validate(callBack);
};
// 重置该表单项，将其值重置为初始值，并移除校验结果
const resetFields = () => {
  formRef.value.resetFields();
};
const validateField = (result, call) => {
  return formRef.value.validateField(result, call);
};
// 清理某个字段的表单验证信息。
const clearValidate = (propsList) => {
  formRef.value.clearValidate(propsList);
};

defineExpose({
  validate,
  resetFields,
  validateField,
  clearValidate,
});
</script>

<style lang="scss">
.custom-form {
  height: 100%;

  &.is-label-bold {
    .el-form-item {
      &__label {
        font-weight: bold;
      }
    }
  }

  &.inline-block {
    .el-select,
    .el-select-v2,
    .el-range-editor {
      width: 100%;
    }
    .el-range-editor {
      min-height: 10px;
      box-sizing: border-box;
    }

    .ml-input {
      width: 100%;
      .el-input {
        width: 100%;
      }
    }

    .ml-select {
      width: 100%;
      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }

  .el-form {
    height: 100%;

    .el-form-item {
      &.is-error {
        .input-generate-tag {
          border-color: var(--el-color-danger);
        }
      }
    }
  }

  .btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
      margin-left: 12px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
