<template>
  <!-- 联动数据 -->
  <div class="tree-select-operate-classify">
    <ml-tree-select
      popperClass="operate-classify-popper"
      ref="mlTreeSelectRef"
      node-key="id"
      v-model="changeValue"
      :data="data"
      :props="treeProps"
      @change="change"
      :checkStrictly="useCheckStrictly"
      :showCheckbox="true"
      multiple
      checkOnClickNode
      clearable
      filterable
      defaultExpandAll
      :collapseTags="collapseTags"
      :maxCollapseTags="maxCollapseTags"
      :disabled="disabled"
    >
    </ml-tree-select>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch, computed, nextTick } from "vue";

const emits = defineEmits(["change", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Array, Object],
    default: () => [],
  },
  // 任何节点都可以被选择
  checkStrictly: {
    type: Boolean,
    default: true,
  },
  // 请求携带参数
  params: {
    type: Object,
    default: () => ({}),
  },
  // 折叠文字
  collapseTags: {
    type: Boolean,
    defualt: true,
  },
  // 最大显示折叠数量
  maxCollapseTags: {
    type: Number,
    defualt: 1,
  },
  // 显示全部/不限
  isShowAll: {
    type: Boolean,
    defualt: true,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

/* tree信息 */
// ref
const mlTreeSelectRef = ref();

/* 不限key */
const allTheKey = computed(() => (props.isShowAll ? data.value[0].id : ""));

/* 不关联父子级 */
const useCheckStrictly = ref(props.checkStrictly);

/* tree 选择 */
// 选中的经营品类
const changeValue = ref(props.modelValue);
watch(
  () => changeValue.value,
  (nVal) => {
    emits("update:modelValue", nVal);
  },
  { deep: true }
);
watch(
  () => props.modelValue,
  (nVal) => {
    changeValue.value = nVal;
  },
  { deep: true }
);
// 数据
const data = ref([]);
// 二级数据
const secondLevelData = computed(() => data.value[0].children);
// 数据结构
const treeProps = {
  children: "children",
  label: "name",
  value: "id",
};
// 选择 经营品类数据
const change = (keys, current, { checkedNodes, checkedKeys }) => {
  nextTick(() => {
    const checked = checkedKeys.includes(current.id);

    // 不限 选择
    if (current.id == allTheKey.value) {
      nextTick(() => {
        console.log("不限 选择", checked);

        // 第一版
        if (checked) {
          changeValue.value = [allTheKey.value];
        } else {
          changeValue.value = [];
        }

        // // 第二版
        // if (checked) {
        //   changeValue.value = [allTheKey.value];
        //   setTimeout(() => {
        //     setCheckboxItemClass("add");
        //   }, 100);
        // } else {
        //   setTimeout(() => {
        //     setCheckboxItemClass("remove");
        //   }, 100);
        // }
      });
    } else {
      console.log("其他 选择", checked);
      // 选中
      const index = checkedKeys.findIndex((d) => d == allTheKey.value);

      // 删除不限
      if (index != -1) {
        const result = proxy.$removeDecontextAttr(changeValue.value);
        result.splice(index, 1);
        changeValue.value = result;

        // // 启动关联
        // useCheckStrictly.value = false;
        // nextTick(() => {
        //   console.log(changeValue.value);
        //   console.log(current);

        //   mlTreeSelectRef.value.setCheckedKeys(
        //     secondLevelData.value.map((d) => d.id)
        //   );
        //   useCheckStrictly.value = true;
        //   // for (let i = 0; i < secondLevelData.value.length; i++) {
        //   //   const item = secondLevelData.value[i];
        //   //   mlTreeSelectRef.value.setChecked(item, true, true);
        //   // }

        //   // mlTreeSelectRef.value.setChecked(current, false, true);
        // });
      }
    }
  });

  emits("update:modelValue", keys);
  emits("change", keys, checkedNodes);
};

/* 静态样式操作勾选效果 */
const setCheckboxItemClass = (type) => {
  const elCheckboxInputs = document
    .getElementsByClassName("operate-classify-popper")[0]
    .getElementsByClassName("el-checkbox__input");

  const elSelectDropdownItems = document
    .getElementsByClassName("operate-classify-popper")[0]
    .getElementsByClassName("el-select-dropdown__item");

  for (let i = 0; i < elCheckboxInputs.length; i++) {
    const item = elCheckboxInputs[i];
    const dropdownItems = elSelectDropdownItems[i];
    item.classList[type]("is-checked");
    dropdownItems.classList[type]("selected");
  }
};

// 获取总数据量
const nodeTotal = ref(0);
const setNodesConfig = () => {
  // 赋值
  // 总数
  nodeTotal.value = getDataLength(data.value, 0);
};
// 递归获取总数据量
const getDataLength = (list, total) => {
  total += list.length;

  if (!list.length) {
    return total;
  }

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const children = item[treeProps.children] || [];

    total = getDataLength(children, total);
  }

  return total;
};

// 查询 经营品类数据
const getList = async () => {
  const { params } = props;
  try {
    const res = await proxy.$storeDispatch(
      "fetchGetAllOperateTypeChooser",
      params
    );
    const result = res.result;
    data.value = props.isShowAll ? result : result[0].children;
    // console.log(data.value)
    // setNodesConfig();
  } catch (error) {}
};

// 初始
const init = async () => {
  await getList();
};

defineExpose({
  init,
});
</script>

<style lang="scss" scoped>
.tree-select-operate-classify {
  width: 100%;

  ::v-deep(.el-select) {
    .el-select__tags.is-disabled {
      cursor: text !important;
    }
    .el-select__input.is-disabled {
      cursor: text !important;
    }
    .el-input.is-disabled .el-select__caret {
      cursor: text !important;
    }
  }
}
</style>
