<template>
  <ml-button
    v-if="isOpenPrevent"
    class="auth-button"
    :class="{ 'is-hide': showCurrentBtn }"
    :useLoading="useLoading"
    :size="size"
    :type="type"
    @handleClick="handleClick"
    @submit="handleClick"
    @click.prevent
    :disabled="authDisabled"
  >
    <slot />
  </ml-button>
  <ml-button
    v-else
    class="auth-button"
    :class="{ 'is-hide': showCurrentBtn }"
    :useLoading="useLoading"
    :size="size"
    :type="type"
    @handleClick="handleClick"
    @submit="handleClick"
    :disabled="authDisabled"
  >
    <slot />
  </ml-button>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";
import { usePageFunctionAuthModule } from "@/utils/mixin";

const emits = defineEmits(["handleClick"]);
const props = defineProps({
  size: {
    type: String,
    default: "default",
  },
  type: {
    type: String,
    default: "primary",
  },
  // 使用加载
  useLoading: {
    type: Boolean,
    default: false,
  },
  menuCode: {
    type: String,
    default: "",
  },
  // 是否使用menuCode
  useMenuCode: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // 阻止事件发生
  isOpenPrevent: {
    type: Boolean,
    default: true,
  },
});

const { isDisabled } = usePageFunctionAuthModule(props);

const { proxy } = getCurrentInstance();

// 无权限禁用 || 按钮禁用
const authDisabled = computed(() => {
  return props.disabled;
});
// 显示按钮
const showCurrentBtn = computed(() => {
  const { useMenuCode } = props;

  return useMenuCode ? isDisabled.value : false;
});

const handleClick = proxy.$bypass((close) => {
  // 是否具有使用权限
  if (authDisabled.value) {
    close ? close() : "";
    return;
  }
  emits("handleClick", close);
});
</script>

<style lang="scss" scoped>
.auth-button {
  &.is-hide {
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    background: transparent;
    opacity: 0 !important;
    display: none !important;
    min-width: 0 !important;
    max-width: 0 !important;
  }
}

.is-hide + .el-button {
  margin-left: 0;
}
.el-button + .is-hide {
  display: block !important;
  margin-right: 12px !important;
}
</style>
