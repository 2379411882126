<template>
  <!-- 联动数据 -->
  <div class="tree-select-choose">
    <ml-tree-select
      v-model="changeValue"
      :data="useData"
      :props="treeProps"
      @change="change"
      :checkStrictly="checkStrictly"
      :disabled="disabled"
      clearable
      filterable
    >
    </ml-tree-select>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch, computed } from "vue";

const emits = defineEmits(["change", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Array, Object, null, undefined],
    default: () => [],
  },
  // 任何节点都可以被选择
  checkStrictly: {
    type: Boolean,
    default: true,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: () => [],
  },
});

const { proxy } = getCurrentInstance();

/* tree 选择 */
// 选中的区域
const changeValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    changeValue.value = nVal;
  },
  { deep: true }
);
// 数据
const useData = computed(() => props.data);
// 数据结构
const treeProps = {
  children: "children",
  label: "name",
  value: "id",
};
// 选择区域数据
const change = (res) => {
  emits("update:modelValue", res);
  emits("change", res);
};

// 初始
const init = async () => {
  await getList();
};

defineExpose({
  init,
});
</script>

<style lang="scss" scoped>
.tree-select-choose {
  width: 100%;
}
</style>
