<template>
  <el-switch
    class="ml-switch"
    v-model="useSwitch"
    inline-prompt
    :style="`--el-switch-on-color: ${activeColor};
      --el-switch-off-color:${inactiveColor} ;`"
    width="65px"
    :active-text="activeText"
    :inactive-text="inactiveText"
    @change="change"
    :disabled="disabled"
    :before-change="nowBeforeChange"
    :loading="nowLoading"
  />
</template>

<script setup>
import { computed, ref, watch } from "vue";

const emits = defineEmits(["update:modelValue", "change"]);

const props = defineProps({
  modelValue: {
    type: [Number, String, Boolean],
    default: "",
  },
  activeText: {
    type: String,
    default: "启用",
  },
  inactiveText: {
    type: String,
    default: "禁用",
  },
  activeColor: {
    type: String,
    default: "rgba(37, 176, 3, 1)",
  },
  inactiveColor: {
    type: String,
    default: "#ff4949",
  },
  activeValue: {
    type: [Number, String, Boolean],
    default: 1,
  },
  inactiveValue: {
    type: [Number, String, Boolean],
    default: 0,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  beforeChange: {
    type: Function,
    default: () => true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const nowBeforeChange = computed(() => props.beforeChange);
const nowLoading = computed(() => props.loading);

const useSwitch = ref(props.modelValue == props.activeValue);

watch(
  () => props.modelValue,
  (nVal, oVal) => {
    useSwitch.value = nVal == props.activeValue;
  },
  {
    immediate: true,
  }
);
watch(
  () => useSwitch.value,
  (nVal) => {
    const { activeValue, inactiveValue } = props;
    const resault = nVal ? activeValue : inactiveValue;
    emits("update:modelValue", resault);
  }
);

const change = () => {
  emits("change", useSwitch.value);
};
</script>

<style lang="scss">
.ml-switch {
  &.el-switch {
    .el-switch__core {
      height: 25px;
      border-radius: 30px;

      .el-switch__action {
        width: 19px;
        height: 19px;
        left: 2px;
      }

      .el-switch__inner {
        padding-bottom: 1px;
      }
    }

    &.is-checked {
      .el-switch__action {
        left: calc(100% - 21px);
      }
    }
    &.is-disabled {
      .el-switch__label,
      .el-switch__core {
        cursor: text;
      }
    }
  }
}
</style>
