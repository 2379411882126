import jQuery from "jquery";

export default {
  install: (app) => {
    app.config.globalProperties.$ = jQuery;
    app.config.globalProperties.jQuery = jQuery;
    app.provide("jQuery", jQuery);
    window.$ = jQuery
  },
};
