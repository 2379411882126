<template>
  <div class="panel-manage-container">
    <div class="navbar-back">
      <div class="text" :style="{ fontSize: titleSize }">{{ panelTitle }}</div>
      <div class="button">
        <ml-button type="default" @handleClick="refreshView">刷新</ml-button>
        <ml-button type="default" @handleClick="goBack" v-if="isShowGoBackBtn"
          >返回</ml-button
        >
      </div>
    </div>

    <div :class="`panel-manage-container__content`">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, getCurrentInstance } from "vue";

const emits = defineEmits(["refresh"]);

const props = defineProps({
  panelTitle: {
    type: String,
    default: "",
  },
  titleSize: {
    type: String,
    default: "24px",
  },
  isShowGoBackBtn: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

const { dataStore } = proxy.$usePiniaModule();

// 刷新routerView
// 监听菜单点击操作
const refreshView = () => {
  dataStore.globalViewRefresh = false;
  setTimeout(() => {
    dataStore.globalViewRefresh = true;
  }, 100);
};

// 返回
const goBack = () => {
  proxy.$router.go(-1);
};

/* 加载状态 */
const isLoading = ref(false);
// 显示加载
const showLoading = () => {
  isLoading.value = true;
};
// 隐藏加载
const hideLoading = () => {
  isLoading.value = false;
};

defineExpose({
  showLoading,
  hideLoading,
});
</script>

<style lang="scss" scoped>
.panel-manage-container {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // padding: 5px;
  box-sizing: border-box;
  // 导航样式
  .navbar-back {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

    &.is-level {
      background-color: white;
      box-shadow: 2px 2px 5px 0px #0000000d;
    }
    .text {
      font-size: 24px;
      font-weight: bold;
      font-family: "Arial Negreta", "Arial Normal", "Arial";
    }
    .icon {
      cursor: pointer;
      background-color: white;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-back {
        font-size: 20px;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow: hidden;
    padding-top: 15px;
    box-sizing: border-box;
  }
}
</style>
