// 入口
export default [
  /* 登陆 */
  //  手机号密码登录
  {
    name: "phonePasswordLogin",
    url: "/user/phonePasswordLogin",
    method: "post",
  },
  //  获取当前系统用户信息
  {
    name: "getCurrentSystemUserInfo",
    url: "/user/getCurrentSystemUserInfo",
    method: "get",
  },
  //  修改系统用户密码
  {
    name: "modifyUserPassword",
    url: "/user/modifyUserPassword",
    method: "put",
  },

  // 菜单
  // 获取系统菜单列表
  { name: "getSystemMenuList", url: "/menu/getSystemMenuList", method: "get" },

  // 文件上传
  { name: "uploadFile", url: "", method: "post" },

  // ****************************************************************
  /* 特殊设置 */

  // 菜单
  // 添加菜单
  { name: "addMenu", url: "/menu/addMenu", method: "post" },
  // 修改菜单
  { name: "modifyMenu", url: "/menu/modifyMenu", method: "put" },
  // 获取菜单列表
  { name: "getMenuList", url: "/menu/getMenuList", method: "get" },
  // 获取当前系统用户角色菜单列表
  {
    name: "getCurrentSystemUserRoleMenuList",
    url: "/user/getCurrentSystemUserRoleMenuList",
    method: "get",
  },
];
