// 自定义混入

import {
  getCurrentInstance,
  computed,
  reactive,
  watch,
  ref,
  nextTick,
  shallowRef,
} from "vue";
import { GLAFT_OPTIONS } from "@/utils/dataDictionary";
import { removeDecontextAttr } from "./module";

// ********************************************************************************************************************************************************
// 页面使用模块 START
// ********************************************************************************************************************************************************

/**
 * 页面使用参数 模块
 */
export const usePageModule = function () {
  const { proxy } = getCurrentInstance();

  const mainStore = proxy.$usePiniaModule("mainStore");

  // 当前登录用户信息
  const currLoginUserInfo = computed(() => mainStore.userInfo);

  /* 系统用户信息 */
  // 当前系统用户类型
  const systemUserType = computed(() => currLoginUserInfo.value.phone);
  // 系统管理员
  const systemUserManage = computed(() => systemUserType.value == "admin");
  // 普通管理员
  const systemUserNormal = computed(() => !systemUserManage.value);

  // 路由携带参数
  const routeParams = reactive(proxy.$route.query);

  return {
    proxy,
    mainStore,
    currLoginUserInfo,
    systemUserManage,
    systemUserNormal,
    routeParams,
  };
};

// ********************************************************************************************************************************************************
// 页面使用模块 END
// ********************************************************************************************************************************************************

// ********************************************************************************************************************************************************
// 功能使用模块 START
// ********************************************************************************************************************************************************

/* 页面 功能使用权限 模块 */
export const usePageFunctionAuthModule = (props) => {
  const { proxy } = usePageModule();
  const { menuCode = "", authPath = "" } = props || {};

  const mainStore = proxy.$usePiniaModule("mainStore");

  // 路由按钮权限
  const routeBtnAuths = computed(() => mainStore.routeBtnAuths);
  // 当前路由地址
  const currRoutePath = authPath || proxy.$route.path;

  // 当前菜单code
  const currMenuCode = computed(() => menuCode);

  // 当前使用权限
  // 当前页面的按钮使用权限
  const currPageUseAuths = computed(
    () => routeBtnAuths.value[currRoutePath] || []
  );

  // 是否具有使用资格
  const isHasUseAuth = computed(() => {
    return currPageUseAuths.value.includes(currMenuCode.value);
  });

  // 是否禁用
  const isDisabled = computed(() => {
    return !isHasUseAuth.value && !!currMenuCode.value;
  });
  // 是否禁用
  // const isDisabled = computed(() => false);

  // 获取本页面菜单权限
  const currentPageAuthParams = computed(() => {
    let pageRole = currPageUseAuths.value.map((d) => {
      return [d, true];
    });

    return Object.fromEntries(pageRole);
  });

  // console.log("当前页面 功能权限");
  // console.log(currPageUseAuths.value);
  // console.log(currRoutePath);
  // console.log(routeBtnAuths.value);
  // console.log(isHasUseAuth.value);
  // console.log(currMenuCode.value);
  // console.log(isDisabled.value);
  return {
    currMenuCode,
    isHasUseAuth,
    isDisabled,
    currPageUseAuths,
    currentPageAuthParams,
  };
};

// 根据本地媒体文件数据获取类型
export const useGetMediumFileTypeModule = () => {
  /* 选项信息 */
  // 文件类型
  const GLAFT_TYPES = GLAFT_OPTIONS();

  /* 数据处理操作 */
  // 类型筛选配置
  const filterScopeConfig = computed(() => {
    // 场景类型
    const [
      IMAGE_TYPE,
      VIDEO_TYPE,
      PDF_TYPE,
      WORD_TYPE,
      EXCEL_TYPE,
      PPT_TYPE,
      TXT_TYPE,
      OTHER_TYPE,
    ] = GLAFT_TYPES;

    return [
      {
        filterTypes: ["jpg", "png", "gif", "jpeg", "bmp"],
        resultItem: IMAGE_TYPE,
      },
      {
        filterTypes: ["mp4"],
        resultItem: VIDEO_TYPE,
      },
      {
        filterTypes: ["pdf"],
        resultItem: PDF_TYPE,
      },
      {
        filterTypes: ["doc", "docx", "rtf"],
        resultItem: WORD_TYPE,
      },
      {
        filterTypes: ["excel", "xls", "xlsx"],
        resultItem: EXCEL_TYPE,
      },
      {
        filterTypes: ["ppt", "pptx"],
        resultItem: PPT_TYPE,
      },
      {
        filterTypes: ["txt"],
        resultItem: TXT_TYPE,
      },
    ];
  });
  // 根据上传文件类型 判断场景值
  const getUploadFileScope = (files) => {
    const { name } = files;
    // console.log(name);

    // 分割 名称与后缀
    const nameAfters = name.split(".");
    const fileType = nameAfters[nameAfters.length - 1];

    // 筛选结果
    const result = filterScopeConfig.value.find((d) => {
      const { filterTypes } = d;
      return filterTypes.includes(fileType.toLowerCase());
    });

    // console.log(result);

    // 在已知类型中筛选到
    if (!!result) {
      const { resultItem } = result;
      return {
        scopeType: resultItem.value,
        describe: resultItem.label,
      };
    } else {
      // 其他
      const resultItem = GLAFT_TYPES[GLAFT_TYPES.length - 1];
      // console.log(resultItem);
      return {
        scopeType: resultItem.value,
        describe: resultItem.label,
      };
    }
  };

  return {
    getUploadFileScope,
    filterScopeConfig,
  };
};

// ********************************************************************************************************************************************************
// 功能使用模块 END
// ********************************************************************************************************************************************************

// **************************************************************************************************
// 表格操作模块 START
// **************************************************************************************************

/**
 * 表格请求响应配置参数 模块
 */
export const useTableParamsModule = function (opts) {
  const {
    fetchTableName = "",
    fetchTableNameCall,
    setCallBackRes,
    defaultPageParams = () => ({
      pageNum: 1,
      pageSize: 100,
    }),
  } = opts || {};
  const { proxy } = getCurrentInstance();

  const tableRef = ref();
  // 查询客户用户表格列表数据
  const tableDataDetault = () => ({
    result: [],
    total: 0,
  });
  const tableData = ref(tableDataDetault());

  // 表格分页请求参数
  const pageParams = reactive(defaultPageParams());
  watch([() => pageParams.pageNum, () => pageParams.pageSize], (nVal) => {
    getTableList();
  });

  // 筛选表单数据
  const filterParams = reactive({});
  // 重置
  const resetParams = async (params) => {
    await fetchFilterData(params);
  };
  // 接收筛选栏的数据
  const fetchFilterData = async (params) => {
    proxy.$updateParams(filterParams, params, {
      updateOriginDataType: "data",
    });
    pageParams.pageNum = 1;

    tableData.value = tableDataDetault();
    await getTableList();
  };

  // 设置筛选参数
  const setFilterParams = (params) => {
    // 更新
    Object.assign(filterParams, params);
  };

  const getTableList = async (requestType = "init") => {
    const params = {
      ...pageParams,
      ...filterParams,
    };

    try {
      // console.log(fetchTableName, params);
      const fetchName = fetchTableNameCall
        ? fetchTableNameCall()
        : fetchTableName;
      // console.log(fetchName);
      const res = await proxy.$storeDispatch(fetchName, params);
      const useRes = setCallBackRes
        ? setCallBackRes(res, {
            requestParams: removeDecontextAttr(params),
          })
        : res;

      // 初始
      if (requestType == "init") {
        tableData.value = useRes;

        setTimeout(() => {
          closeTableLoading();
        }, 300);
      } else if (requestType == "updatePage") {
        // 按分页更新
        return res;
      }
    } catch (error) {
      closeTableLoading();
      console.log(error);
    }
  };

  // 按照分页页数更新数据
  const updatePageData = async (opt) => {
    try {
      const { pageNum = 1 } = opt || {};
      const { pageSize } = pageParams;

      const res = await getTableList("updatePage");
      const result = res.result;

      const startIndex = (pageNum - 1) * pageSize;
      const endIndex = result.length + 1;

      tableData.value.result.splice(startIndex, endIndex, ...result);
    } catch (error) {
      console.log(error);
    }
  };

  // 打开表格加载
  const openTableLoading = () => {
    tableRef.value.openLoading();
  };

  // 关闭表格加载
  const closeTableLoading = () => {
    tableRef.value.closeLoading();
  };

  return {
    setFilterParams,
    resetParams,
    fetchFilterData,
    getTableList,
    openTableLoading,
    updatePageData,
    tableData,
    pageParams,
    tableRef,
    filterParams,
  };
};

/**
 * 表格 勾选数据 配置 模块
 */
export const useTableChangeDataModule = function (tableRef, opts) {
  const {
    tableData = {},
    mode = "normal",
    tableDataChildren = [],
    useTableCheckbox = { value: true },
  } = opts;
  const { proxy } = getCurrentInstance();

  /* 树形子级数据id组 */
  const tableDataChildrenIds = computed(() =>
    tableDataChildren.value.map((d) => d.id)
  );

  /* 勾选 */

  /* 数据 */
  // 选择待确认 的数据
  const checkedTreatDatas = ref([]);
  // ids组
  const checkedTreatDataIds = computed(() =>
    checkedTreatDatas.value.map((d) => (d ? d.id : d))
  );

  // 已勾选 的数据
  const checkedDatas = computed(() => {
    // 选择待确认数据
    const treatData = checkedTreatDatas.value;

    return treatData;
  });
  // 已勾选 的数据 id组
  const checkedDataIds = computed(() =>
    checkedDatas.value.map((d) => (d ? d.id : d))
  );

  // 当前页选中的数据
  const checkedCurrPageDatas = ref([]);
  // id 组
  const checkedCurrPageDataIds = computed(() =>
    checkedCurrPageDatas.value.map((d) => d.id)
  );

  // 当前表格展示的数据
  const currTableShowData = computed(() => {
    const tableResult = tableData.value.result;
    // 常规表格
    if (mode == "normal") {
      return tableResult;
    } else {
      // 树形表格
      return [...tableResult, ...tableDataChildren.value];
    }
  });
  // id组
  const currTableShowDataIds = computed(() =>
    currTableShowData.value.map((d) => d.id)
  );

  /* 操作 */
  // 监听
  const onSelectHandAll = (data) => {
    // console.log("全", data);
    // 拦截 非多选
    if (!useTableCheckbox.value) {
      return;
    }

    checkedCurrPageDatas.value = data;
    setContrastCurrPageData();
  };
  const onSelectHand = (data) => {
    // 拦截 非多选
    if (!useTableCheckbox.value) {
      return;
    }
    console.log("单选", data);
    checkedCurrPageDatas.value = data;

    /* 获取 */
    const childChildrens = proxy.$getChildChildrenList(data);
    const childChildrenIds = childChildrens.map((d) => d.id);

    for (let i = 0; i < childChildrenIds.length; i++) {
      const id = childChildrenIds[i];
      if (!tableDataChildrenIds.value.includes(id)) {
        const item = childChildrens[i];
        tableDataChildren.value.push(item);
      }
    }

    setContrastCurrPageData();
  };
  // 对比当前页的数据，设置待确认 中的数据
  const setContrastCurrPageData = () => {
    // 筛选出需要 取消的数据
    const cancelCheckData = currTableShowData.value.filter(
      (d) => !arrayIsHasIndex(checkedCurrPageDataIds.value, d.id)
    );
    // 筛选出 选中的数据
    const checkData = currTableShowData.value.filter((d) =>
      arrayIsHasIndex(checkedCurrPageDataIds.value, d.id)
    );

    // console.log("取消的数据", cancelCheckData);
    // console.log("选中的数据", checkData);

    // 取消
    for (let i = 0; i < cancelCheckData.length; i++) {
      const curr_item = cancelCheckData[i];
      const curr_id = curr_item.id;

      // 待确认 删除
      const index = checkedTreatDataIds.value.indexOf(curr_id);
      if (index !== -1) {
        checkedTreatDatas.value.splice(index, 1, null);
      }
    }

    // 选中的
    for (let i = 0; i < checkData.length; i++) {
      const curr_item = checkData[i];
      const curr_id = curr_item.id;

      // 是否已添加过
      const isAdded = arrayIsHasIndex(checkedDataIds.value, curr_id);
      if (!isAdded) {
        // 待确认 加item
        checkedTreatDatas.value.push(curr_item);
      }
    }

    // console.log("对比当前页的数据，设置待确认", checkedTreatDatas.value);
    // console.log(tableRef.value.store);
    // console.log(tableRef.value.store.states);
  };
  // 重置选择的数据
  const resetCheck = () => {
    checkedCurrPageDatas.value = [];
  };

  // 重置 选择好的数据 待确认数据
  const resetCheckedData = () => {
    checkedTreatDatas.value = [];
  };

  // 设置勾选数据
  const setCheckedDatas = (data) => {
    checkedTreatDatas.value = data;
  };

  /* 获取最终选中数据 */
  const getLastCheckedDatas = () => {
    // console.log(checkedDatas.value);
    return {
      dataTotal: tableData.value.total,
      data: proxy.$removeDecontextAttr(
        checkedDatas.value.filter((d) => !proxy.$isEmpty(d))
      ),
      ids: proxy.$removeDecontextAttr(
        checkedDataIds.value.filter((d) => !proxy.$isEmpty(d))
      ),
    };
  };

  /* 数组 是否存在对应数据 */
  const arrayIsHasIndex = (list, value) => {
    return list.indexOf(value) !== -1;
  };

  /* 设置表格勾选 */
  const setCheckedTableData = () => {
    // 清除勾选
    tableRef.value.clearSelection();

    setTimeout(() => {
      const result = checkedDatas.value;

      for (let i = 0; i < result.length; i++) {
        const item = result[i];

        if (item) {
          // 勾选设置
          const checkedItem = currTableShowData.value.find(
            (d) => d.id == item.id
          );

          if (checkedItem) {
            tableRef.value.toggleRowSelection(checkedItem, true);
          }
        }
      }
    }, 800);
  };

  return {
    onSelectHandAll,
    onSelectHand,
    resetCheck,
    setCheckedDatas,
    getLastCheckedDatas,
    resetCheckedData,
    setCheckedTableData,
  };
};

/* 树形表格 操作 懒加载 模块 （接口返回全部数据，树形表格仅做静态操作） */
export const useTreeTableLoadActiveModule = ({
  tableGlobalData,
  tableLazyParentStoreData,
  tableRef,
}) => {
  const { proxy } = getCurrentInstance();

  // 本地Map缓存
  const treeTableMapLoaclStore = new Map();

  // 设置map数据
  const setTreeTableMapLoad = (key, data) => {
    treeTableMapLoaclStore.set(key, data);
  };

  // 调用展开加载
  const expandLazyLoad = (parentId, callBack) => {
    // 是否展开过
    const isExpand = treeTableMapLoaclStore.has(parentId);

    if (isExpand) {
      const rowConfig = treeTableMapLoaclStore.get(parentId);
      const { row, treeNode, resolve } = rowConfig;
      // 刷新
      callBack(row, treeNode, resolve);
    }
  };

  // 分类子级 懒加载
  const tableTreeLazyLoad = async (row, treeNode, resolve, callback) => {
    const { id, parentId, level } = row;
    // console.log("tableTreeLazyLoad", row);
    let result = [];
    let currItem = {};

    // 顶级
    if (level == 1) {
      // 全局数据中筛选
      currItem = tableGlobalData.value.find((d) => d.id == id) || {};
    } else {
      // 检索父级项子级列表 已打开过的数据列表
      const useParentChildrens = tableLazyParentStoreData[parentId] || [];

      // 查找
      currItem = useParentChildrens.find((d) => d.id == id) || {};
    }

    // 合并 检索到的数据、本地静态更新的子级数据（静态添加）
    const concatData = [...(currItem.children || []), ...row.children];

    // 去重
    result = concatData.reduce((add, curr) => {
      const id = curr.id.toString();
      const addIds = add.map((d) => d.id.toString());
      if (!addIds.includes(id)) {
        add.push(curr);
      }
      return add;
    }, []);

    // 缓存赋值
    tableLazyParentStoreData[id] = proxy.$removeDecontextAttr(result);

    // 初次展开操作
    setTreeTableMapLoad(id, { row, treeNode, resolve });

    if (!result.length) {
      tableRef.value.store.states.lazyTreeNodeMap.value[id] = [];
    }

    // 懒加载数据
    const lazyResult = result.map((item) => {
      // console.log(item);
      item.hasChildren = !!item.children.length;
      // 清空子级
      item.children = [];
      return item;
    });
    // 剔除上下文绑定
    const lazyResultRemoveDecontext = proxy.$removeDecontextAttr(lazyResult);

    // 赋值表层 静态数据
    row.children = lazyResultRemoveDecontext;

    // 回调
    if (callback) {
      callback(lazyResultRemoveDecontext);
    }

    // console.log("表格 tree懒加载数据", lazyResult);
    resolve(lazyResultRemoveDecontext);
  };

  return {
    setTreeTableMapLoad,
    expandLazyLoad,
    tableTreeLazyLoad,
  };
};

/* 树形表格 操作 懒加载 模块 （数据根据接口动态添加） */
export const useTreeTableActionLoadActiveModule = () => {
  // 本地Map缓存
  const treeTableMapLoaclStore = new Map();

  // 设置map数据
  const setTreeTableMapLoad = (key, data) => {
    treeTableMapLoaclStore.set(key, data);
  };

  // 调用展开加载
  const expandLazyLoad = (parentId, callBack) => {
    // 是否展开过
    const isExpand = treeTableMapLoaclStore.has(parentId);

    if (isExpand) {
      const rowConfig = treeTableMapLoaclStore.get(parentId);
      const { row, treeNode, resolve } = rowConfig;
      // 刷新
      callBack(row, treeNode, resolve);
    }
  };

  return {
    setTreeTableMapLoad,
    expandLazyLoad,
  };
};

/* 表格操作内容的展示 模块 */
export const useTableActiveBtnModule = (props, opt) => {
  const { proxy } = getCurrentInstance();

  const {
    tableActiveWidths = {
      0: 100,
      1: 100,
      2: 140,
    },
  } = opt || {};

  /* 主表格操作按钮 */
  // 显示按钮组
  const showMasterBtns = computed(() => props.masterTableActiveBtns);
  // 布尔类型
  const isMasterBtnTypeBoolean = computed(() => {
    const res = showMasterBtns.value;
    return typeof res === "boolean";
  });

  // 判断处理按钮的显示
  const getMasterBtnResult = (index) => {
    // 布尔
    if (isMasterBtnTypeBoolean.value) {
      // 显示结果
      return showMasterBtns.value;
    } else {
      const result = showMasterBtns.value.find((d) => d == index);
      return !proxy.$isEmpty(result);
    }
  };

  // 使用的按钮宽度
  const useTableActiveWidth = computed(() => {
    let width = 0;

    // 布尔
    if (isMasterBtnTypeBoolean.value) {
      if (showMasterBtns.value) {
        width = Object.keys(tableActiveWidths).reduce((add, name) => {
          const val = tableActiveWidths[name];
          return (add += val);
        }, 0);
      }
    } else {
      width = showMasterBtns.value
        .map((d) => tableActiveWidths[d] || 0)
        .reduce((add, val) => {
          return (add += val);
        }, 0);
    }

    return width;
  });

  return {
    getMasterBtnResult,
    useTableActiveWidth,
  };
};

/**
 * 处理表格组件中 数据的静态操作
 * 对数据的 增 删 改 进行操作与保存
 */
export const useTableStaticDataOperateAndRecord = (opt) => {
  const { proxy } = getCurrentInstance();
  const { tableData, recordData } = opt;

  /* 对表格的添加修改删除进行记录 */
  const tableOperateLogData = reactive({
    addLogs: [],
    updateLogs: [],
    deleteLogs: [],
  });

  /* 获取 表格的添加修改删除 操作记录 */
  const getTableOperateLogData = (opt) => {
    const { itemIdKey = "id" } = opt || {};
    const { addLogs, updateLogs, deleteLogs } =
      proxy.$removeDecontextAttr(tableOperateLogData);

    // 动态数据
    const addLogActions = addLogs.filter((d) => d.isAction);
    const updateLogActions = updateLogs.filter((d) => d.isAction);
    const deleteLogActions = deleteLogs.filter((d) => d.isAction);
    // 非动态数据
    const addLogNotActions = addLogs.filter((d) => !d.isAction);

    //动态数据 id
    const addLogIds = addLogActions.map((d) => d[itemIdKey]);
    const updateIds = updateLogActions.map((d) => d[itemIdKey]);
    const deleteIds = deleteLogActions.map((d) => d[itemIdKey]);

    return {
      addLogNotActions,
      addLogActions,
      updateLogActions,
      deleteLogActions,
      addLogIds,
      updateIds,
      deleteIds,
    };
  };

  /* 设置表格静态数据 */
  const setTableStaticData = (data = [], opt) => {
    // 使用记录数据
    const useRecordData = tableData ? tableData.value.result : recordData.value;

    try {
      /**
       * actionMode 操作类型 push||init||update
       */
      const { actionMode = "", itemIdKey = "id" } = opt || {};

      // 初始
      if (actionMode == "init") {
        // 表单记录数据
        if (tableData) {
          tableData.value.result = data;
          tableData.value.total = data.length;
        }

        // 记录数据
        if (recordData) {
          recordData.value = data;
        }
      } else if (actionMode == "push") {
        if (tableData) {
          // 添加
          tableData.value.result.push(...data);
        }

        // 记录数据
        if (recordData) {
          recordData.value.push(...data);
        }

        // 保存
        tableOperateLogData.addLogs.push(...data);
      } else if (actionMode == "update") {
        // 更新
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          const id = item[itemIdKey];

          const index = useRecordData.findIndex((d) => d[itemIdKey] == id);

          // console.log(item);
          // 更新
          proxy.$updateParams(useRecordData[index], item, {
            ignoreNull: true,
          });

          // 编辑 记录中的检索位置
          const isUpdateIndex = tableOperateLogData.updateLogs.findIndex(
            (d) => d[itemIdKey] == id
          );
          // 添加 记录中的检索位置
          const isAddIndex = tableOperateLogData.addLogs.findIndex(
            (d) => d[itemIdKey] == id
          );
          // 删除 记录中的检索位置
          const isDeleteIndex = tableOperateLogData.deleteLogs.findIndex(
            (d) => d[itemIdKey] == id
          );

          /* 正常编辑中 */
          if (isUpdateIndex == -1) {
            // 保存
            tableOperateLogData.updateLogs.push(item);
          } else {
            // 更新
            proxy.$updateParams(
              tableOperateLogData.updateLogs[isUpdateIndex],
              item,
              {
                ignoreNull: true,
              }
            );
          }

          /* 编辑添加、删除中 */
          if (isAddIndex != -1) {
            proxy.$updateParams(tableOperateLogData.addLogs[isAddIndex], item);
          }
          if (isDeleteIndex != -1) {
            proxy.$updateParams(
              tableOperateLogData.deleteLogs[isDeleteIndex],
              item
            );
          }
        }
      } else if (actionMode == "delete") {
        // 删除
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          const id = item[itemIdKey];

          const index = useRecordData.findIndex(
            (d) => !!d && d[itemIdKey] == id
          );

          if (tableData) {
            tableData.value.result.splice(index, 1, {});
          }
          if (recordData) {
            recordData.value.splice(index, 1, {});
          }

          // 覆盖
          if (tableData) {
            tableData.value.result = tableData.value.result.filter(
              (d) => !!Object.keys(d).length
            );
          }
          if (recordData) {
            recordData.value = recordData.value.filter(
              (d) => !!Object.keys(d).length
            );
          }

          // 保存
          tableOperateLogData.deleteLogs.push(item);

          /* 删除添加、编辑 */
          // 添加记录中的检索位置
          const isAddIndex = tableOperateLogData.addLogs.findIndex(
            (d) => d[itemIdKey] == id
          );
          // 更新记录中的检索位置
          const isUpdateIndex = tableOperateLogData.updateLogs.findIndex(
            (d) => d[itemIdKey] == id
          );
          if (isAddIndex != -1) {
            tableOperateLogData.addLogs.splice(isAddIndex, 1);
          }
          if (isUpdateIndex != -1) {
            tableOperateLogData.updateLogs.splice(isUpdateIndex, 1);
          }
        }
      }

      // console.log(tableOperateLogData);
      // console.log(tableData.value.result);
      // console.log(recordData.value);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    setTableStaticData,
    getTableOperateLogData,
  };
};

// **************************************************************************************************
// 表格操作模块 END
// **************************************************************************************************

// **************************************************************************************************
// 树形功能使用模块 START
// **************************************************************************************************

// 处理 树形中 操作全部与子级关联状态
// 操作子级与全部不关联（取消子级选中会取消全部勾选）
export const useTreeCheckAllStatusModule = (
  props,
  emits,
  callBackChange,
  opt = {}
) => {
  const {
    treeRef,
    treeChangeAllStatus,
    treeData,
    treeProps,
    isCheckStrictly,
    handleCheckAllStatus,
  } = opt;

  const { proxy } = getCurrentInstance();

  // 断开 tree勾选父子级关联
  const breakCheckStrictly = () => {
    if (isCheckStrictly) {
      isCheckStrictly.value = true;
    }
  };
  // 关联 tree勾选父子级
  const associationCheckStrictly = () => {
    if (isCheckStrictly) {
      isCheckStrictly.value = false;
    }
  };

  watch(
    () => props.checkAllStatus,
    (nVal) => {
      treeChangeAllStatus.value = nVal;
    }
  );
  watch(
    () => treeChangeAllStatus.value,
    (nVal) => {
      // 取反 状态
      const result = !nVal ? 1 : 0;

      // 回显 勾选类型状态
      emits("update:changeStatus", result);
    }
  );
  // 监听勾选全部
  const onTreeChangeAll = (res) => {
    // 一级列表
    const first_level_ids = treeData.value.map((d) => d[props.nodeKey]);
    // console.log("onTreeChangeAll", first_level_ids);

    // 提前 启用父子级关联
    associationCheckStrictly();

    if (res) {
      // console.log(first_level_ids);
      // 全选
      // 勾选全部
      treeRef.value.setCheckedKeys(first_level_ids);

      nextTick(() => {
        setTimeout(() => {
          // 断开 父子级关联
          breakCheckStrictly();

          // 原 选中数据
          // const origin_checked_data = treeRef.value.getCheckedKeys();

          // console.log(origin_checked_data);
          // console.log(treeChangeAllStatus.value);

          callBackChange([], [], !treeChangeAllStatus.value);
        }, 200);
      });
    } else {
      // 原 选中数据
      const origin_checked_data = treeRef.value.getCheckedKeys();

      // 全部取消
      for (let i = 0; i < origin_checked_data.length; i++) {
        const id = origin_checked_data[i];
        treeRef.value.setChecked(id, false, true);
      }

      // 断开 父子级关联
      breakCheckStrictly();

      callBackChange([], [], !treeChangeAllStatus.value);
    }
  };

  // 选择
  const checkTreeChange = (data, node) => {
    // 原 选中数据
    const origin_checked_data = treeRef.value.getCheckedKeys();
    const origin_checked_nodes = treeRef.value.getCheckedNodes();

    // 过滤后的数据
    const filter_checked_data = proxy.$removeDecontextAttr(
      origin_checked_data.filter((d) => !!d)
    );
    // 过滤后的 勾选数量
    const filter_checked_data_total = filter_checked_data.length;

    // console.log(filter_checked_data_total);
    // console.log(nodeTotal.value);

    // 断开 完整勾选
    if (filter_checked_data_total != nodeTotal.value) {
      treeChangeAllStatus.value = false;
    }

    callBackChange(
      filter_checked_data,
      origin_checked_nodes,
      !treeChangeAllStatus.value
    );
  };

  // 获取总数据量
  const nodeTotal = ref(0);
  const setNodesConfig = () => {
    // 赋值
    // 总数
    nodeTotal.value = getDataLength(treeData.value, 0);
    // console.log(nodeTotal.value);

    // 第一层的key
    const [first_area] = treeData.value;
    if (first_area) {
      // defaultExpandedKeys.value = [first_area[props.nodeKey]];
    }
  };
  // 递归获取总数据量
  const getDataLength = (list, total) => {
    total += list.length;

    if (!list.length) {
      return total;
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const children = item[treeProps.children] || [];

      total = getDataLength(children, total);
    }

    return total;
  };

  return {
    onTreeChangeAll,
    checkTreeChange,
    setNodesConfig,
  };
};

/* 树形选择 父级归纳合并子级  */
// 当选择一个父级下面所有的子级时 所有选中的子级keys会归纳为一个父级key
// 当正常选择时不改变
export const useTreeInductiveSubclassData = (opt) => {
  const {
    treeRef = {},
    nowCheckAllData,
    nowCheckAllDataIds,
    emits,
    treeProps,
    callBackOnChangeData,
  } = opt || {};

  const { proxy } = getCurrentInstance();

  //处理接口返回数据 设置数组
  const setResultCallBackAddData = (
    result,
    parentItem = { parentId: 0, name: "" }
  ) => {
    for (let i = 0; i < result.length; i++) {
      const item = result[i];
      setResultCallBackAddDataObj(item, parentItem);
    }
    return result;
  };

  // 设置对象
  const setResultCallBackAddDataObj = (item, parentItem) => {
    const children = item.children || [];

    // 设置父级ID
    // 赋值
    item.parentId = parentItem.id;
    item.parentName = parentItem.name;

    // 设置分店 信息
    if (item.type == 1) {
      const {
        accountingStatus = 0,
        managerStatus = 0,
        otherStatus = 0,
        shopOwnerStatus = 0,
      } = item;
      const accountingName = accountingStatus ? "会计" : "";
      const managerName = managerStatus ? "招商经理" : "";
      const shopOwnerName = shopOwnerStatus ? "店长" : "";
      const otherName = otherStatus ? "可见分店" : "";

      // 标签名
      const showBarNames =
        [accountingName, managerName, shopOwnerName, otherName].filter(
          (d) => !!d
        ) || "";

      if (showBarNames.length) {
        item["barLabels"] = showBarNames;
      }
    }

    return setResultCallBackAddData(children, item);
  };

  /* 数据操作 */
  /* 监听树形节点选中状态 */
  const onCheckTreeNode = (checkItem, node) => {
    if (!nowCheckAllData.value) {
      nowCheckAllData.value = [];
    }
    nextTick(() => {
      // 所勾选的总key
      const { checkedKeys, checkedNodes, halfCheckedNodes } = node;
      // console.log(node);

      // 当前节点的勾选状态
      const isCheck = checkedKeys.includes(checkItem.id);

      // 参数
      const { parentId, id } = checkItem;

      // 数据存放
      if (isCheck) {
        console.log(`选中${checkItem.name}`);
        // console.log(nowCheckAllData.value);
        // console.log(nowCheckAllData);
        // console.log(nowCheckAllDataIds);

        // 添加
        const index = nowCheckAllDataIds.value.indexOf(checkItem.id);

        // 拦截
        if (index != -1) {
          nowCheckAllData.value.splice(index, 1, checkItem);
        } else {
          nowCheckAllData.value.push(checkItem);
        }

        clearTreeChildrenNode(
          parentId,
          checkItem,
          checkedKeys,
          checkedNodes,
          halfCheckedNodes
        );
      } else {
        // 取消当前
        console.log(`取消${checkItem.name}`);

        checkTreeChildrenNode(
          id,
          parentId,
          checkItem,
          checkedNodes,
          halfCheckedNodes,
          checkedKeys
        );

        // 检索位置
        const index = nowCheckAllDataIds.value.indexOf(id);

        // 检索位置正确性
        if (index != -1) {
          nowCheckAllData.value.splice(index, 1);
        }
      }

      setTimeout(() => {
        // console.log("数据变动", JSON.stringify(nowCheckAllData.value));
        // console.log("数据变动", nowCheckAllData.value);
        callBackOnChangeData(nowCheckAllData.value, nowCheckAllDataIds.value);
      });
    });
  };

  // 筛选父级 选中父级清除子级 保存父级 （点击选中节点）
  const clearTreeChildrenNode = (
    parentId,
    checkItem,
    checkedKeys,
    checkedNodes,
    halfCheckedNodes
  ) => {
    // 清除当前 子级的..的子级 清除父级的...的子级

    // console.log(checkedNodes)
    // console.log(parentId)
    const checkNodeParnet = checkedNodes.find((d) => d.id == parentId);

    // 获取 半选数据
    const halfCheckedNodeParent = halfCheckedNodes.find(
      (d) => d.id == parentId
    );

    // 顶级
    if (!parentId) {
      console.log("清除子级");
      const currItemChildren = checkItem.children;

      // 清除
      clearChildrensData(currItemChildren);

      // 覆盖
      nowCheckAllData.value = proxy.$removeDecontextAttr(
        nowCheckAllData.value.filter((d) => !!d)
      );
    } else {
      const parentItem = checkNodeParnet || halfCheckedNodeParent;
      // console.log(parentItem);
      if (!parentItem) {
        return;
      }

      // 半选数据状态
      // 不管 父级是 全选 还是 不选 都会清除子级数据
      if (!halfCheckedNodeParent) {
        // 清除
        clearChildrensData(parentItem.children);

        // 去重
        if (!nowCheckAllDataIds.value.includes(parentItem.id)) {
          nowCheckAllData.value.push(parentItem);
        }
      }

      // 覆盖
      nowCheckAllData.value = proxy.$removeDecontextAttr(
        nowCheckAllData.value.filter((d) => !!d)
      );

      // console.log(nowCheckAllData.value);
      clearTreeChildrenNode(
        parentItem.parentId,
        checkItem,
        checkedKeys,
        checkedNodes,
        halfCheckedNodes
      );
    }
  };

  // 筛选父级 取消父级清除子级，半选父级选中子级（取消选中节点）
  const checkTreeChildrenNode = (
    id,
    parentId,
    checkItem,
    checkedNodes,
    halfCheckedNodes,
    checkedKeys
  ) => {
    // console.log(parentId);
    // console.log(checkedNodes);
    // console.log(halfCheckedNodes);
    const checkNodeParnet = checkedNodes.find((d) => d.id == parentId);

    // 顶级
    if (!parentId) {
      const currItemChildren = checkItem.children;

      // 清除
      clearChildrensData(currItemChildren);

      // 覆盖
      nowCheckAllData.value = proxy.$removeDecontextAttr(
        nowCheckAllData.value.filter((d) => !!d)
      );
    } else {
      // 特殊处理

      const halfCheckedNodeParent = halfCheckedNodes.find(
        (d) => d.id == parentId
      );
      // console.log(checkNodeParnet);
      // console.log(halfCheckedNodeParent);
      const parentItem = checkNodeParnet || halfCheckedNodeParent;

      // 当前节点 无选中数据
      if (!parentItem) {
        // console.log(checkItem);
        // console.log(checkedNodes);
        clearCurrNodeParents(checkItem);
        return;
      }

      // 检索位置
      const parentIndex = nowCheckAllData.value.findIndex(
        (d) => d.id == parentId
      );
      // console.log(parentIndex);

      // console.log(id);
      // console.log(nowCheckAllDataIds.value);
      // 提出当前取消的item 获取 剩余子级数据
      const residueChildren = parentItem.children.filter((d) =>
        checkedKeys.includes(d.id)
      );
      // console.log(residueChildren);

      for (let i = 0; i < residueChildren.length; i++) {
        const residueChildrenItem = residueChildren[i];

        // 去重
        if (!nowCheckAllDataIds.value.includes(residueChildrenItem.id)) {
          // 保存父级 剩余的子级数据
          nowCheckAllData.value.push(residueChildrenItem);
        }
      }

      // 拦截
      if (parentIndex != -1) {
        nextTick(() => {
          // 删除父级
          nowCheckAllData.value.splice(parentIndex, 1);
        });
      }

      checkTreeChildrenNode(
        id,
        parentItem.parentId,
        checkItem,
        checkedNodes,
        halfCheckedNodes,
        checkedKeys
      );
    }
  };

  /* 递归清除子级 */
  const clearChildrensData = (list = []) => {
    if (!list.length) {
      return;
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const children = item.children || [];
      const index = nowCheckAllData.value.findIndex(
        (d) => d && d.id == item.id
      );

      if (index != -1) {
        // console.log("删除", index);
        nowCheckAllData.value.splice(index, 1, null);
      }

      clearChildrensData(children);
    }
  };

  // 清除当前节点的父级
  const clearCurrNodeParents = (checkItem) => {
    for (let i = 0; i < nowCheckAllData.value.length; i++) {
      const item = nowCheckAllData.value[i];

      if (checkItem.parentId == item.id) {
        nowCheckAllData.value.splice(i, 1, null);
      }
    }

    nowCheckAllData.value = proxy.$removeDecontextAttr(
      nowCheckAllData.value.filter((d) => !!d)
    );
  };

  return {
    onCheckTreeNode,
    setResultCallBackAddData,
  };
};

// **************************************************************************************************
// 树形功能使用模块 END
// **************************************************************************************************

// **************************************************************************************************
// VXE 第三方树形表格功能使用模块 START
// **************************************************************************************************

/* VXE树形表格的 展开、勾选 操作模块 */
export const useVxeTreeTableExpandAndCheckModule = (opt) => {
  const { checkStrictly, setVxeTableCheckRows } = opt;

  const { proxy } = getCurrentInstance();

  /* 树形表格懒加载 */
  /* 表格 全局数据 */
  const tableGlobalData = ref([]);
  /* 缓存 树形 父级数据数据 */
  const tableLazyParentStoreData = reactive({});

  /* 是否已展开 */
  const recordExpandRows = reactive({});

  /* vxetable 表格 */
  /* 保存表格勾选数据 */
  const vxeTableCheckData = ref([]);
  const vxeTableCheckDataIds = computed(() =>
    vxeTableCheckData.value.filter((d) => !!d).map((d) => d.id)
  );
  // 树形 表格 勾选父级Id组
  const vxeTableCheckDataParentIds = computed(() =>
    vxeTableCheckData.value
      .filter((d) => !!d)
      .map((d) => d.parentId)
      .filter((d) => !!d)
  );

  /* 监听子级加载 */
  const onVxeTableTreeLazyLoad = ({ row }) => {
    return new Promise((resolve, reject) => {
      const { id, parentId } = row;
      // console.log("tableTreeLazyLoad", row);
      let result = [];
      let currItem = {};

      // 顶级
      if (!parentId) {
        // 全局数据中筛选
        currItem = tableGlobalData.value.find((d) => d.id == id) || {};
      } else {
        // 检索父级项子级列表 已打开过的数据列表
        const useParentChildrens = tableLazyParentStoreData[parentId] || [];

        // 查找
        currItem = useParentChildrens.find((d) => d.id == id) || {};
      }

      // console.log(currItem);
      // // 合并 检索到的数据、本地静态更新的子级数据（静态添加）
      // const concatData = [...(currItem.children || []), ...row.children];
      // 合并 检索到的数据、本地静态更新的子级数据（静态添加）
      const concatData = currItem.children || [];

      // 去重
      result = concatData.reduce((add, curr) => {
        const id = curr.id.toString();
        const addIds = add.map((d) => d.id.toString());
        if (!addIds.includes(id)) {
          add.push(curr);
        }
        return add;
      }, []);

      // 缓存赋值
      tableLazyParentStoreData[id] = proxy.$removeDecontextAttr(result);

      // 懒加载数据
      const lazyResult = result.map((item) => {
        item.hasChildren = !!item.children.length;
        // 清空子级
        item.children = [];
        return item;
      });
      // 剔除上下文绑定
      const lazyResultRemoveDecontext = proxy.$removeDecontextAttr(lazyResult);

      // console.log("懒加载子级", lazyResultRemoveDecontext);

      // 未设置 需要进行初始
      if (!recordExpandRows[id]) {
        // 设置勾选项
        if (setVxeTableCheckRows) {
          setVxeTableCheckRows();
          recordExpandRows[id] = true;
        }
      }

      resolve(lazyResultRemoveDecontext);
    });
  };

  // 监听勾选
  const onVxeTabletableCheck = ({ $table, checked, row }) => {
    const { id, parentId } = row;
    // console.log("勾选", checked, row);

    // 用于 type=checkbox，手动清空用户的选择
    //
    // 全部item
    const allTheItem = tableGlobalData.value[0];
    // key
    const allTheKey = allTheItem.id;

    // 不限
    if (allTheKey == id) {
      // console.log("不限 选择");
      // 清除
      $table.clearCheckboxRow();
      // 勾选
      if (checked) {
        nextTick(() => {
          $table.setCheckboxRow(row, true);
          // 当前 获取到的勾选数据
          const nowRecords = $table.getCheckboxRecords();
          vxeTableCheckData.value = nowRecords;
          // console.log(nowRecords);
        });
      }
    } else {
      console.log("普通 选择");
      // 处理不限选项
      $table.setCheckboxRow({ id: allTheKey }, false);

      // 关联子级
      if (!checkStrictly) {
        //当前的 子级数据
        let now_children = [];

        // 当前同级的数据
        const sameLevelData = parentId
          ? tableLazyParentStoreData[parentId] || []
          : tableGlobalData.value;
        // 当前数据
        const sameOneData = sameLevelData.find((d) => d.id == id) || {};
        // console.log("当前同级的数据", sameLevelData);
        // console.log("当前数据", sameOneData);
        // 查找当前数据的子级
        const sameOneChildren = sameOneData.children || [];
        // 赋值
        now_children = sameOneChildren;

        // 子级的子级数据
        const childChildrenData = proxy.$getChildChildrenList(now_children);

        // console.log("现有子级", now_children);
        // console.log("获取子级的子级列表", childChildrenData);

        // 需要操作的数据
        const operateData = [row, ...now_children, ...childChildrenData];

        // 勾选
        if (checked) {
          // 填充数据
          const pushData = proxy.$removeDecontextAttr(operateData);

          // 赋值
          vxeTableCheckData.value.push(...pushData);
        } else {
          // 取消勾选
          const cancelData = proxy.$removeDecontextAttr(operateData);

          // 遍历删除
          for (let i = 0; i < cancelData.length; i++) {
            const item = cancelData[i];
            const id = item.id;

            if (item) {
              const index = vxeTableCheckDataIds.value.findIndex(
                (d) => !!d && d == id
              );
              vxeTableCheckData.value.splice(index, 1, null);
            }
          }

          vxeTableCheckData.value = vxeTableCheckData.value.filter((d) => !!d);
        }
      } else {
        // 当前 获取到的勾选数据
        const nowRecords = $table.getCheckboxRecords();
        vxeTableCheckData.value = nowRecords;
      }
    }

    // 数据
    // console.log(vxeTableCheckData.value);
  };

  /* 设置树形表格中的展开项 */
  const setVxeTableExpand = () => {
    // 设置展开项
    setTimeout(() => {
      // tableRef.value.setTreeExpand(vxeTableCheckData.value, true);
      // 展开项 id组
      const ids = [
        ...vxeTableCheckDataIds.value,
        ...vxeTableCheckDataParentIds.value,
      ];
      // console.log(vxeTableCheckData.value);
      // console.log(ids);

      const rows = document.body.getElementsByClassName("vxe-body--row");

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowId = row.getAttribute("rowid");

        // console.log(ids.includes(rowId));
        // 展开
        if (ids.includes(rowId)) {
          setTimeout(() => {
            const normalTreeNode = row.getElementsByClassName(
              "vxe-tree--node-btn vxe-icon-caret-right"
            )[0];
            const expandTreeNode = row.getElementsByClassName(
              "vxe-tree--node-btn vxe-icon-caret-right rotate90"
            )[0];
            if (normalTreeNode) {
              // 防止 双向切换
              if (!expandTreeNode) {
                normalTreeNode.click();
              }
            }
          }, 100);
        }
      }
    }, 1000);
  };

  return {
    onVxeTabletableCheck,
    onVxeTableTreeLazyLoad,
    tableGlobalData,
    vxeTableCheckData,
    vxeTableCheckDataIds,
    vxeTableCheckDataParentIds,
    setVxeTableExpand,
  };
};

// **************************************************************************************************
// VXE 第三方树形表格功能使用模块 END
// **************************************************************************************************

/**
 *
 * 富文本
 */
export const editPageModule = () => {
  // 编辑器实例，必须用 shallowRef
  const editorRef = shallowRef();
  // 内容 HTML
  const valueHtml = ref("");

  // 模式 默认/简洁
  const mode = ref("default"); //'default',  或 'simple'

  // 工具栏配置
  const toolbarConfig = reactive({
    toolbarKeys: [
      "headerSelect",
      "blockquote",
      "|",
      "bold",
      "underline",
      "italic",
      {
        key: "group-more-style",
        title: "更多",
        iconSvg:
          '<svg viewBox="0 0 1024 1024"><path d="M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path></svg>',
        menuKeys: ["through", "code", "sup", "sub", "clearStyle"],
      },
      "color",
      "bgColor",
      "|",
      "fontSize",
      "fontFamily",
      "lineHeight",
      "|",
      "bulletedList",
      "numberedList",
      "todo",
      {
        key: "group-justify",
        title: "对齐",
        iconSvg:
          '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
        menuKeys: [
          "justifyLeft",
          "justifyRight",
          "justifyCenter",
          "justifyJustify",
        ],
      },
      {
        key: "group-indent",
        title: "缩进",
        iconSvg:
          '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
        menuKeys: ["indent", "delIndent"],
      },
      "|",
      "emotion",
      "insertLink",
      "insertTable",
      "codeBlock",
      "divider",
      "|",
      "undo",
      "redo",
      "|",
      "fullScreen",
    ],
  });

  // 创建编辑器
  const editorConfig = { placeholder: "在这里输入正文..." };

  // 链接
  // 自定义校验链接
  const customCheckLinkFn = (text, url) => {
    // JS 语法
    if (!url) {
      return;
    }
    if (url.indexOf("http") !== 0) {
      return "链接必须以 http/https 开头";
    }

    return true;

    // 返回值有三种选择：
    // 1. 返回 true ，说明检查通过，编辑器将正常插入链接
    // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
    // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
  };

  // 自定义转换链接 url
  const customParseLinkUrl = (url) => {
    // JS 语法

    if (url.indexOf("http") !== 0) {
      return `http://${url}`;
    }
    return url;
  };
  //  菜单配置
  editorConfig.MENU_CONF = reactive({
    // 插入链接
    insertLink: {
      checkLink: customCheckLinkFn, // 也支持 async 函数
      parseLinkUrl: customParseLinkUrl, // 也支持 async 函数
    },
  });

  const handleCreated = (editor) => {
    editorRef.value = editor; // 记录 editor 实例，重要！
    // console.log(editor);
  };

  return {
    editorRef,
    valueHtml,
    mode,
    toolbarConfig,
    editorConfig,
    handleCreated,
  };
};
