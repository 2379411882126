<template>
  <span
    v-if="showCurrentLabel"
    class="auth-label"
    :class="{ 'is-disabled': authDisabled }"
    :type="type"
    @click="handleClick"
  >
    <slot />
  </span>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";
import { usePageFunctionAuthModule } from "@/utils/mixin";

const emits = defineEmits(["handleClick"]);
const props = defineProps({
  type: {
    type: String,
    default: "primary",
  },
  menuCode: {
    type: String,
    default: "",
  },
  // 是否使用menuCode
  useMenuCode: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { isDisabled } = usePageFunctionAuthModule(props);

const { proxy } = getCurrentInstance();

// const authDisabled = computed(() => true);
// 无权限禁用
const authDisabled = computed(() => {
  return props.disabled;
});
// 显示标签
const showCurrentLabel = computed(() => {
  const { useMenuCode } = props;
  return !(useMenuCode ? isDisabled.value : false);
});

const handleClick = proxy.$bypass(() => {
  // 是否具有使用权限
  if (authDisabled.value) {
    return;
  }

  emits("handleClick");
});
</script>

<style lang="scss" scoped>
.auth-label {
  &.is-disabled {
    opacity: 0.5;
    cursor: no-drop !important;
  }
}
</style>
