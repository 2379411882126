import NProgress from "nprogress";
import axiosOrigin from "axios";

// 响应拦截
/*
	@param {object} custom
	returnLogin 是否是需要登录页返回
	showTips 是否是需要响应呢提示
	customUrl 自定义接口（不会添加API的前置）
	isClearStore 是否清除授权
	isResetUseFrozen 是否重置特定拦截
 */

import { ElMessage, ElMessageBox } from "element-plus";

import { userLoginExit } from "@/utils/main";

import { isEmpty, delayHandle, getStoreAgeToken } from "@/utils/module";

//状态 是否已使用
let isUseFrozen = false;

// 提示消息实例
let ELMSG = null;

export const response = (axios) => {
  axios.interceptors.response.use(
    (option) => {
      // let { proxy } = getCurrentInstance()
      // console.log('resolve')
      // console.log(option)

      // 响应数据
      let { config = {}, data = {} } = option || {};

      // 自定义设置
      let {
        returnLogin,
        showTips,
        customApiUrl = "",
        isClearStore,
        isResetUseFrozen,
        isBlob,
      } = config.custom || {};

      // 判断默认
      returnLogin = isEmpty(returnLogin) || returnLogin;
      showTips = isEmpty(showTips) || showTips;
      isClearStore = isEmpty(isClearStore) || isClearStore;

      // 自定义接口路径
      if (customApiUrl) {
        // 文件流
        if (isBlob) {
          NProgress.done();
          return data;
        } else {
          if (data.code != 200) {
            // 清除旧数据
            if (ELMSG) {
              // 关闭弹窗
              ELMSG.close();
            }

            // 是否显示提醒
            if (showTips) {
              ELMSG = ElMessage({
                showClose: true,
                message: data.message,
                type: "warning",
              });
            }
            NProgress.done();
          } else {
            isUseFrozen = false;
          }
        }
      } else {
        // 授权失效
        if (data.code == 401) {
          // 清除旧数据
          if (ELMSG) {
            // 关闭弹窗
            ELMSG.close();
          }

          // 是否显示提醒
          if (showTips) {
            const token = getStoreAgeToken();

            // 验证token
            if (token) {
              // ELMSG = ElMessage({
              //   showClose: true,
              //   message: data.message,
              //   type: "warning",
              // });
            }
          }

          // console.log(returnLogin);
          // 是否返回登录
          if (returnLogin) {
            NProgress.done();
            delayHandle(userLoginExit);
          }
        } else {
          if (data.code != 200) {
            if (data.code == 40001) {
              ElMessageBox({
                title: "提示",
                showCancelButton: false,
                confirmButtonText: "我知道了",
                message: data.message,
              });
            } else {
              // 清除旧数据
              if (ELMSG) {
                // 关闭弹窗
                ELMSG.close();
              }

              // 是否显示提醒
              if (showTips) {
                ELMSG = ElMessage({
                  showClose: true,
                  message: data.message,
                  type: "warning",
                });
              }
              NProgress.done();
            }
          } else {
            isUseFrozen = false;
            NProgress.done();
          }
        }
      }

      NProgress.done();
      // 关于code状态交由 专门处理响应的函数 storeResponse
      return data;
    },
    (option) => {
      // console.log("reject");
      // console.log(option);

      // 取消请求
      if (axiosOrigin.isCancel(option)) {
        ElMessage({
          showClose: true,
          message: "已取消资源请求",
          type: "warning",
        });
      } else {
        // 处理错误
        // 响应
        let { config = {} } = option || {};

        // 自定义设置
        let { showTips } = config.custom || {};

        // 判断默认
        showTips = isEmpty(showTips) || showTips;

        // 清除旧数据
        if (ELMSG) {
          // 关闭弹窗
          ELMSG.close();
        }

        if (!option.data) {
          if (showTips) {
            const { response } = option || {};
            const { data = {} } = response || {};

            const message = data.message || "无法连接服务器，请稍后再试";

            ELMSG = ElMessage({
              showClose: true,
              message,
              type: "error",
            });
          }
        }
      }

      NProgress.done();
      return option;
    }
  );
};
