import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import jqueryjs from '@/utils/jqueryjs'
import { createPinia } from "pinia";

const pinia = createPinia();

// element-plus 样式
import "element-plus/dist/index.css";
import elementPlus from "element-plus";
// fade/zoom
// 内置过渡动画
import "element-plus/theme-chalk/base.css";

// 组件
// 自定义组件
import components from "./components/index";
// 自定义组件
import elCustom from "./el-custom/index";

// 国际化
import zhCn from "element-plus/es/locale/lang/zh-cn";
const app = createApp(App);

// 加载进度条
import "nprogress/nprogress.css";
import NProgress from "nprogress";
// 配置
NProgress.configure({
  show: false,
  showSpinner: false, //关闭进度环
});

/* 表格框架 */
import {
  // 可选组件
  Column,
  Checkbox,
  // 表格
  Table,
} from "vxe-table";
import "vxe-table/lib/style.css";
function useTable(app) {
  app.use(Column).use(Checkbox).use(Table);
}

// 使用
app
  .use(elementPlus, {
    locale: zhCn,
  })
  .use(router)
  .use(elCustom)
  .use(components)
  .use(pinia)
  .use(store)
  .use(useTable)
  .use(jqueryjs)
  .mount("#app");

// 配置API请求设置
import { initApi } from "@/utils/http/index";
initApi(store);

// 全局自定义插件方法设置
import * as mainModule from "@/utils/main";
import * as globalModule from "@/utils/module";
import * as apiConfig from "@/utils/apiConfig";
const globalProperties = {
  ...globalModule,
  ...apiConfig,
  ...mainModule,
};
for (let i in globalProperties) {
  app.config.globalProperties["$" + i] = globalProperties[i];
}

// 图标
import * as icons from "@element-plus/icons-vue";

// 插入
Object.keys(icons).forEach((key) => {
  app.component(key, icons[key]);
});

// 路由卫士
// router.beforeEach((to, from, next) => {
//   NProgress.inc();
//   next();
// });
// router.afterEach((to) => {
//   NProgress.done();
// });
