<template>
  <div class="phone-verification-input" :class="{ 'inline-block': inline }">
    <ml-input
      type="number"
      maxlength="11"
      :placeholder="placeholder"
      :size="size"
      class="is-has-append"
      v-model="useValue"
    >
      <template #append>
        <div class="send-code__wrapper">
          <!-- 发送按钮 -->
          <div
            class="send-button"
            @click="sendCode"
            v-if="!countdownState.isCountdown"
          >
            获取验证码
          </div>

          <!-- 倒计时 -->
          <div class="countdown-time" v-else>{{ countdownText }}</div>
        </div>
      </template>
    </ml-input>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch, getCurrentInstance } from "vue";
import { testPhone } from "@/utils/regExp";
import * as dataDictionary from "@/utils/dataDictionary";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
  phone: {
    type: [String, Number],
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  inline: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: "",
  },
  // 访问凭证类型
  accessType: {
    type: String,
    default: "",
  },
});

const { proxy } = getCurrentInstance();

const useValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    useValue.value = nVal;
  }
);
watch(
  () => useValue.value,
  (nVal) => {
    emits("update:modelValue", nVal);
  }
);

// 需要验证的手机号
const useVerifyPhone = computed(() => props.phone || useValue.value || "");

// ************************************************
/* 校验手机号 */
const inputTestPhone = () => {
  const phone = useVerifyPhone.value.toString().split(" ").join("");
  if (!phone) {
    proxy.$warningMsg("请输入手机号");
    return;
  } else if (!testPhone(phone)) {
    proxy.$warningMsg("请输入正确的手机号");
    return;
  }

  return true;
};

/* 倒计时 */
// 倒计时状态
const countdownDefault = () => ({
  count: 60,
  timeId: null,
  isCountdown: false,
});
const countdownState = reactive(countdownDefault());

// 发送验证码
const sendCode = proxy.$bypass(async () => {
  // 拦截
  const testPhoneRes = inputTestPhone();
  if (!testPhoneRes) {
    return;
  }

  // 是否在计时
  if (countdownState.isCountdown) {
    return;
  }

  // 调用接口
  const sendRes = await sendSmsVerificationCode();
  if (!sendRes) {
    return;
  }

  startCountdown();
});
// 计时器
// 开始
const startCountdown = () => {
  resetCountdown();

  countdownState.isCountdown = true;
  countdownState.timeId = setInterval(() => {
    countdownState.count -= 1;

    if (!countdownState.count) {
      resetCountdown();
    }
  }, 1000);
};
// 关闭
const clearCountdown = () => {
  clearInterval(countdownState.timeId);
  countdownState.timeId = null;
};
// 重置
const resetCountdown = () => {
  clearCountdown();
  proxy.$updateParams(countdownState, countdownDefault());
};
// 计时显示文案
const countdownText = computed(() => {
  return `重新发送 ${proxy.$numberToAll(countdownState.count)}s`;
});

/* 操作 */

// 发送短信
const sendSmsVerificationCode = async () => {
  try {
    const params = {
      codeType: dataDictionary[props.accessType],
      phone: useVerifyPhone.value,
    };
    await proxy.$storeDispatch("fetchSendSmsVerificationCode", params);
    return true;
  } catch (error) {
    return false;
  }
};
</script>

<style lang="scss" scoped>
.phone-verification-input {
  ::v-deep(.ml-input.is-has-append) {
    display: grid;
    grid-template-columns: 1fr auto;

    .el-input {
      .el-input__wrapper {
        box-shadow: none;
        border: 1px solid var(--el-border-color);
        border-right: none;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        box-sizing: border-box;
        padding: 0 11px;
      }
    }

    .ml-input__append {
      background-color: white !important;
      // border-left: none !important;
      // padding-left: 0 !important;
      padding: 0 10px;
    }
    .send-code__wrapper {
      .send-button,
      .countdown-time {
        color: var(--el-color-primary-light-3);
        font-size: var(--c-size-small-3);
      }
      .send-button {
        cursor: pointer;
      }
    }
  }
}
</style>
