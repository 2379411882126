<template>
  <div class="normal-tree">
    <div class="treee-loading" v-show="loadingStatus">
      <el-icon class="is-loading" size="16px"><Loading /></el-icon>
    </div>
    <el-tree
      v-show="!loadingStatus"
      ref="treeRef"
      :data="treeData"
      :node-key="nodeKey"
      :show-checkbox="showCheckbox"
      :props="treeProps"
      :check-strictly="useCheckStrictly"
      :default-expand-all="defaultExpandAll"
      :default-expanded-keys="useDefaultExpandedKeys"
      :default-checked-keys="useDefaultCheckedKeys"
      :filter-node-method="filterNodeMethod"
      :disabled="disabled"
      @check="handCheckTreeNode"
      @check-change="changeCheckTreeNode"
      @node-expand="onNodeExpand"
      @node-collapse="onNodeCollapse"
    >
      <template #default="{ node }" v-if="hasSlot('default')">
        <slot :node="node"></slot>
      </template>
    </el-tree>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, watch, nextTick } from "vue";

const emits = defineEmits([
  "check",
  "check-change",
  "onNodeExpand",
  "onNodeCollapse",
]);

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  nodeKey: {
    type: String,
    default: "id",
  },
  // 显示勾选项
  showCheckbox: {
    type: Boolean,
    default: true,
  },
  props: {
    type: Object,
    default: () => ({}),
  },
  defaultExpandAll: {
    type: Boolean,
    default: false,
  },
  // 默认展开的节点的 key 的数组
  defaultExpandedKeys: {
    type: Array,
    default: () => [],
  },
  // 节点筛选
  filterNodeMethod: {
    type: Function,
    default: () => {},
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  // 父子级不关联
  checkStrictly: {
    type: Boolean,
    default: true,
  },
});

const { proxy } = getCurrentInstance();

/* 加载状态 */
const loadingStatus = ref(true);
// 显示
const showLoading = () => {
  loadingStatus.value = true;
};
// 隐藏
const hideLoading = () => {
  loadingStatus.value = false;
};

/* 属性信息 */
// 默认展开
const useDefaultExpandedKeys = ref(props.defaultExpandedKeys);
watch(
  () => props.defaultExpandedKeys,
  (nVal) => {
    if (nVal.length) {
      useDefaultExpandedKeys.value = nVal;
      onNodeExpand(nVal);
    }
  },
  {
    deep: true,
  }
);
// 默认选中
const useDefaultCheckedKeys = ref([]);
// 父子级不关联
const useCheckStrictly = computed(() => props.checkStrictly);

/* tree 树形结构操作 */
// ref
const treeRef = ref();
// 树形数据
const treeData = ref(props.data);
// 只监听一次 props.data 有效值
const isWatchOneceData = ref(false);
watch(
  () => props.data,
  (nVal) => {
    treeData.value = nVal;

    if (isWatchOneceData.value) {
      return;
    }

    isWatchOneceData.value = true;
  },
  {
    deep: true,
  }
);

// 树形数据结构参数
const treeProps = computed(() => props.props);

// 处理树形数据的接口属性
const setTreeDataProps = (list, opt = {}) => {
  // console.log(treeData.value);
  // console.log(props.data);
  // console.log(props);

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const children = item.children || [];
    // item.disabled = props.disabled;
    Object.assign(item, opt);
    setTreeDataProps(children, opt);
  }
};

// 手动选择
const handCheckTreeNode = (data, node) => {
  hanleCheckTreeNode(data, node);
  emits("check", data, node);
};
const hanleCheckTreeNode = (data, node) => {
  const checkNode = treeRef.value.getNode(data); // 获取当前节点

  // 拦截
  if (!checkNode) {
    return;
  }

  // 获取当前节点是否被选中
  const isChecked = checkNode.checked;

  // 子级
  const children = data.children
    ? data.children
    : checkNode.childNodes.map((d) => d.data);
  // 如果当前节点被选中，则遍历下级子节点并选中，如果当前节点取消选中，
  // 则遍历下级节点并取消
  if (isChecked) {
    // 判断该节点是否有下级节点，如果有那么遍历设置下级节点为选中
    if (children && children.length > 0) {
      setChildreChecked(children, true);
    }
  } else {
    // 如果节点取消选中，则取消该节点下的子节点选中
    if (children && children.length > 0) {
      setChildreChecked(children, false);
    }
  }

  /* 操作node */
  /* 设置父级状态 */
  // 检索 当前节点的状态
  const retrieveCheckNodeState = isParentParentIndeterminates(checkNode);
  // 设置父级半选
  if (retrieveCheckNodeState) {
    // 父级 状态
    checkNode.parent.checked = false;
    checkNode.parent.indeterminate = true;

    // 设置父级的父级的半选、勾选 状态
    setParentChecked(checkNode.parent);
  }

  // 设置子级 勾选
  function setChildreChecked(node, isChecked) {
    node.forEach((item) => {
      item.children &&
        item.children.length > 0 &&
        setChildreChecked(item.children, isChecked);

      // 修改勾选状态
      treeRef.value.setChecked(item.id, isChecked);
    });
  }
};
// 设置父级的父级的半选、勾选 状态
const setParentChecked = (checkNode) => {
  if (!checkNode || !checkNode.parent) {
    return;
  }

  // 检索状态
  const result = isParentParentIndeterminates(checkNode);
  // 设置父级半选
  if (result) {
    // 父级 状态
    checkNode.parent.checked = false;
    checkNode.parent.indeterminate = true;

    setParentChecked(checkNode.parent);
  }
};
// 父级的父级的状态 是否允许 半选
const isParentParentIndeterminates = (checkNode) => {
  const parentChildrenCheckNodes = checkNode.parent.childNodes.filter(
    (d) => d.checked
  );

  return (
    checkNode.parent.childNodes.some((d) => d.checked) ||
    checkNode.indeterminate ||
    !parentChildrenCheckNodes.length
  );
};

// 选择变化
const changeCheckTreeNode = (data, checked, indeterminate) => {
  // 选中全部子节点，父节点也默认选中，但是子节点再次取消勾选或者全部子节点取消勾选也不会影响父节点勾选状态
  const checkNode = treeRef.value.getNode(data); // 获取当前节点
  // console.log(data, checked, indeterminate);

  emits("check-change", data, checked, indeterminate, checkNode);
};

/* 监听伸缩 */
// 节点被展开时触发的事件
const onNodeExpand = (res) => {
  emits("onNodeExpand", res);
};
// 节点被关闭时触发的事件
const onNodeCollapse = (res) => {
  emits("onNodeCollapse", res);
};

/* 节点操作 */
// 设置为禁用
const setTreeNodeDisabled = () => {
  const { disabled, data } = props;
  setTreeDataProps(treeData.value, {
    disabled,
  });
};

// 获取所有选中 node key
const getCheckedKeys = () => {
  // return treeRef.value.getCheckedKeys().filter((d) => !!d);
  return treeRef.value.getCheckedKeys();
};

// 获取tree选中node
const getCheckedNodes = () => {
  // return treeRef.value.getCheckedNodes().filter((d) => !!d[props.nodeKey]);
  return treeRef.value.getCheckedNodes();
};

// 设置选中
const setCheckedKeys = (keys) => {
  // 设置选中
  if (props.disabled) {
    useDefaultCheckedKeys.value = keys;
    setTimeout(() => {
      setTreeNodeDisabled();
      setCheckedKeysIndeterminate(keys);
    });
  } else {
    setTimeout(() => {
      treeRef.value.setCheckedKeys(keys);
      setCheckedKeysIndeterminate(keys);
    });
  }

  // 赋值 展开
  if (!useDefaultExpandedKeys.value.length) {
    // useDefaultExpandedKeys.value = keys;

    setTimeout(() => {
      emits("onNodeExpand", keys);
    }, 300);
  }
};
// 设置勾选时的半选状态
const setCheckedKeysIndeterminate = (keys) => {
  // 半选状态
  setTimeout(() => {
    /* 操作NODE */
    // 获取
    const keyData = keys.map((d) => ({ id: d }));
    // 设置
    for (let i = 0; i < keyData.length; i++) {
      // 操作
      hanleCheckTreeNode(keyData[i]);
    }
  }, 100);
};

// 为节点设置新数据，只有当设置 node-key 属性的时候才可用
const updateKeyChildren = (key, data) => {
  treeRef.value.updateKeyChildren(key, data);
};

// 删除 Tree 中的一个节点，使用此方法必须设置 node-key 属性
const remove = (data) => {
  treeRef.value.remove(data);
};

// 为 Tree 中的一个节点追加一个子节点
const append = (data, parentNode) => {
  treeRef.value.append(data, parentNode);
};

// 根据 data 或者 key 拿到 Tree 组件中的 node
const getNode = (res) => {
  return treeRef.value.getNode(res);
};

// 在 Tree 中给定节点前插入一个节点
const insertBefore = (data, res) => {
  treeRef.value.insertBefore(data, res);
};

// 在 Tree 中给定节点后插入一个节点
const insertAfter = (data, res) => {
  treeRef.value.insertAfter(data, res);
};

// 设置节点是否被选中, 使用此方法必须设置 node-key 属性
const setChecked = (key, checked, isDeep) => {
  treeRef.value.setChecked(key, checked, isDeep);
};

// 通过tree中nodeMap 列表查询对应的数据
const getKeyTreeNdeoMapData = (key) => {
  return treeRef.value.store.nodesMap[key];
};

/* 筛选 */
const filter = (val) => {
  treeRef.value.filter(val);
};

// 是否 存在slot
const hasSlot = (name) => {
  return proxy.$slots[name];
};

defineExpose({
  getCheckedKeys,
  setCheckedKeys,
  getCheckedNodes,
  showLoading,
  hideLoading,
  filter,
  updateKeyChildren,
  remove,
  append,
  getNode,
  insertBefore,
  insertAfter,
  setChecked,
  setTreeNodeDisabled,
  getKeyTreeNdeoMapData,
});
</script>

<style lang="scss" scoped>
.normal-tree {
  height: 100%;
  padding-bottom: 10px;

  .treee-loading {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ::v-deep(.el-tree) {
    background: transparent !important;

    .el-tree-node__content {
      height: 32px;
    }
  }
}
</style>
