<template>
  <div class="panel-main-container" v-loading="isLoading">
    <slot></slot>
  </div>

  <!-- 询问弹出窗 -->
  <ask-confirm
    :type="askConfig.type"
    :title="askConfig.title"
    ref="askDialogRef"
    @confirm="askConfig.confirm"
    :askTips="askConfig.askTips"
    @dialogClose="onAskDialogClose"
  />

  <!-- 危险弹窗 -->
  <danger-confirm
    :title="dangerConfig.title"
    ref="dangerConfirmRef"
    @confirm="dangerConfig.confirm"
    :command="dangerConfig.command"
    :tips="dangerConfig.dangerTips"
    @dialogClose="onDangerDialogClose"
  >
  </danger-confirm>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, nextTick } from "vue";

const { proxy } = getCurrentInstance();

/* 加载状态 */
const isLoading = ref(false);
// 显示加载
const showLoading = () => {
  isLoading.value = true;
};
// 隐藏加载
const hideLoading = () => {
  isLoading.value = false;
};

/* 询问弹出窗口 */
// ref
const askDialogRef = ref();
// 默认
const askDialogDefaultConfig = () => ({
  type: "",
  title: "",
  askTips: [],
});
// 配置
const askConfig = reactive(askDialogDefaultConfig());
// 设置询问弹窗配置
const askDialog = (opts = {}) => {
  askDialogRef.value.openDialog();
  nextTick(() => {
    proxy.$updateParams(askConfig, opts);
    askConfig.confirm = (close) => {
      const call = () => {
        askDialogRef.value.closeDialog();
        close();
      };
      opts.confirm(call);
    };
  });
};
// 监听窗口的关闭
const onAskDialogClose = () => {
  nextTick(() => {
    proxy.$updateParams(askConfig, askDialogDefaultConfig());
  });
};

/* 危险确认弹出窗口 */
// ref
const dangerConfirmRef = ref();
// 默认
const dangerDialogDefaultConfig = () => ({
  title: "",
  command: "",
  dangerTips: [],
});
// 配置
const dangerConfig = reactive(dangerDialogDefaultConfig());
// 设置询问弹窗配置
const dangerDialog = (opts = {}) => {
  dangerConfirmRef.value.openDialog();
  nextTick(() => {
    proxy.$updateParams(dangerConfig, opts);
    dangerConfig.confirm = (close) => {
      const call = () => {
        dangerConfirmRef.value.closeDialog();
        close();
      };
      opts.confirm(call);
    };
  });
};
// 监听窗口的关闭
const onDangerDialogClose = () => {
  nextTick(() => {
    proxy.$updateParams(dangerConfig, dangerDialogDefaultConfig());
  });
};

defineExpose({
  askDialog,
  dangerDialog,
  showLoading,
  hideLoading,
});
</script>

<style lang="scss">
.panel-main-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  //title盒子
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
  }
  .buttom-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0;
    box-sizing: border-box;
    border-top: 1px solid #eee;
  }

  > .table-content {
    border-top: 1px solid #ebeef5;
  }
}
</style>
