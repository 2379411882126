<template>
  <div
    class="tree-normal-area ml-scrollbar"
    :style="{ height, 'max-height': `${maxHeight}` }"
  >
    <!-- 全国 废弃 -->
    <!-- <div class="checkbox-all-item">
      <el-checkbox
        label="全国"
        v-model="treeChangeAllStatus"
        @change="onChangeAllCheckeStatus"
        :disabled="disabled"
      ></el-checkbox>
    </div> -->

    <normal-tree
      @check="onHandleCheckTreeNode"
      @onNodeExpand="onNodeExpand"
      @onNodeCollapse="onNodeCollapse"
      ref="treeRef"
      :data="treeData"
      :nodeKey="nodeKey"
      :showCheckbox="showCheckbox"
      :defaultExpandedKeys="defaultExpandedKeys"
      :props="treeProps"
      :checkStrictly="false"
      :disabled="disabled"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, computed, nextTick } from "vue";
import {
  useTreeCheckAllStatusModule,
  useTreeInductiveSubclassData,
} from "@/utils/mixin";

/* 全部 状态已在本版本废弃，无需通过独立的勾选去处理了 */

const emits = defineEmits([
  "update:checkAll",
  "update:changeStatus",
  "onChangeData",
  "change",
  "onNodeExpand",
  "onNodeCollapse",
]);

const props = defineProps({
  // 区域状态 （0:全国、1:自定义）
  changeStatus: {
    type: [String, Number, Boolean],
    default: 1,
  },
  // 是否勾选全部状态
  checkAllStatus: {
    type: [Boolean, String],
    default: false,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  // 显示勾选项
  showCheckbox: {
    type: Boolean,
    default: true,
  },
  nodeKey: {
    type: String,
    default: "id",
  },
  height: {
    type: String,
    default: "100%",
  },
  maxHeight: {
    type: String,
    default: "400px",
  },
});

const { proxy } = getCurrentInstance();

/* 属性 */

/* 默认展开 */
const defaultExpandedKeys = computed(() =>
  treeData.value.map((d) => d[props.nodeKey])
);

// 勾选全部状态
const treeChangeAllStatus = ref(props.checkAllStatus);
// 只监听一次 勾选全部状态
// 是否阻止
// const isStopAllStatus = ref(false);
// watch(
//   () => props.checkAllStatus,
//   (nVal) => {
//     // console.log(nVal);
//     // 初始
//     initCheckAllStatus(nVal);
//   }
// );

// 初始勾选全部状态
const initCheckAllStatus = (nVal) => {
  if (isStopAllStatus.value) {
    return;
  }
  isStopAllStatus.value = true;

  const status = proxy.$isEmpty(nVal) ? false : nVal;

  treeChangeAllStatus.value = status;

  // 改变全选状态
  changeAllCheckeStatus(status);
};

/* tree 树形结构操作 */
// ref
const treeRef = ref();
// 树形数据
const treeData = ref([]);
// 树形数据结构参数
const treeProps = {
  label: "name",
  children: "children",
};

/* 父级归纳子级 回调 常规勾选数据 已归纳 */
/* 示例： xxx一级 < xx二级 < xx三级 */
const callBackOnChangeData = (checkNodes, checkNodeIds) => {
  // console.log(checkNodes, checkNodeIds);
  emits("onChangeData", { checkNodeIds, checkNodes });
};

/* change 回调勾选数据 */
const callBackChange = (allKeys, checkNodes, status) => {
  emits("change", allKeys, status);
};

/* tree 树形选择 父级归纳子级  */
// 勾选的总数据
const nowCheckAllData = ref([]);
// id组
const nowCheckAllDataIds = computed(() =>
  nowCheckAllData.value.filter((d) => !!d).map((d) => d.id)
);
/*   配置 */
const { setResultCallBackAddData, onCheckTreeNode } =
  useTreeInductiveSubclassData({
    nowCheckAllData,
    nowCheckAllDataIds,
    treeProps,
    emits,
    callBackOnChangeData,
  });

/* 处理 树形中 操作全部与子级关联 操作子级与全部不关联（取消子级选中会取消全部勾选） */
const { onTreeChangeAll, checkTreeChange, setNodesConfig } =
  useTreeCheckAllStatusModule(props, emits, callBackChange, {
    treeRef,
    treeData,
    treeProps,
    treeChangeAllStatus,
  });

/* 监听树形节点选中状态 */
const onHandleCheckTreeNode = (checkItem, node) => {
  checkTreeChange(checkItem, node);
  onCheckTreeNode(checkItem, node);
};

/* 监听全选变动 */
const onChangeAllCheckeStatus = (res) => {
  changeAllCheckeStatus(res);
};

// 全选状态
const changeAllCheckeStatus = (res) => {
  onTreeChangeAll(res);

  changeAllStatusTrigger(res);
};
// 全选、取消
const changeAllStatusTrigger = (res) => {
  // 操作后
  nextTick(() => {
    if (res) {
      // 获取第一层数据
      const keys = proxy
        .$removeDecontextAttr(treeData.value)
        .map((d) => d[props.nodeKey]);
      setCheckedKeys(keys);
    } else {
      setCheckedKeys([]);
    }
  });
};

// 设置tree key选中
const setCheckedKeys = (ids) => {
  // 赋值选中
  treeRef.value.setCheckedKeys(ids);
};
// 获取
const getCheckedKeys = () => {
  return treeRef.value.getCheckedKeys();
};

/* 查询 */
// 查询行政区域
const getDistrictList = async () => {
  try {
    const res = await proxy.$storeDispatch("fetchGetAllDistrictChooser");
    const result = res.result;
    treeData.value = setResultCallBackAddData(result);
    setNodesConfig();

    treeRef.value.hideLoading();
  } catch (error) {
    console.log(error);
    treeRef.value.hideLoading();
  }
};

/* 监听节点伸缩 */
// 展开
const onNodeExpand = (res) => {
  emits("onNodeExpand", res);
};
// 收起
const onNodeCollapse = (res) => {
  emits("onNodeCollapse", res);
};

// 初始
const init = async () => {
  await getDistrictList();
};

defineExpose({
  init,
  setCheckedKeys,
  getCheckedKeys,
  changeAllStatusTrigger,
  changeAllCheckeStatus,
});
</script>

<style lang="scss" scoped>
.tree-normal-area {
  width: 100%;
  height: 100%;
  min-height: 150px;
  overflow-y: auto;
  overscroll-behavior: contain;
}
</style>
