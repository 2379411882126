/**
 * 路由导航数据
 */
import { WEB_MENU_CODE_LIST } from "./usePageHandles";
// *********************************************************************************
// 配置数据 START
// *********************************************************************************
/* 功能组 Lv3 */
const [
  normal_add,
  normal_delete,
  normal_update,
  normal_examine,
  enableDisable,
  normal_reset_password,
  download_attachments,
  add_attachments,
  upload_attachments,
  upload_version,
  fill_remarks,
] = WEB_MENU_CODE_LIST;

// 管理页面中的子级 路由配置 Lv2
const { rolePermissionManagementChilds, equipmentModelManagementChilds } = {
  // 角色权限管理
  rolePermissionManagementChilds: [
    {
      path: "/rolePermissionManagementAction",
      name: "rolePermissionManagementAction",
      meta: {
        title: "添加角色",
      },
      // 不加入到 权限
      isAuth: false,
      component: () => import("../views/rolePermissionManagement/action.vue"),
    },
  ],

  // 设备型号管理
  equipmentModelManagementChilds: [
    {
      path: "/equipmentModelManagementAction",
      name: "equipmentModelManagementAction",
      meta: {
        title: "添加设备型号",
      },
      // 不加入到 权限
      isAuth: false,
      component: () => import("../views/equipmentModelManagement/action.vue"),
    },
    {
      path: "/historyUpgradePackage",
      name: "historyUpgradePackage",
      meta: {
        title: "历史升级包",
      },
      // 不加入到 权限
      isAuth: false,
      component: () => import("../views/historyUpgradePackage/index.vue"),
    },
  ],
};

/* 管理模块 路由  Lv1 */
const [USER_MANAGGERS, DEVICE_MANAGGERS] = [
  // 用户管理
  [
    {
      path: "/backgroundUserManagement",
      name: "backgroundUserManagement",
      meta: {
        title: "后台用户管理",
      },
      functionList: [
        enableDisable,
        normal_update,
        normal_add,
        normal_delete,
        normal_reset_password,
      ],
      component: () => import("../views/backgroundUserManagement/index.vue"),
      children: [],
    },
    {
      path: "/rolePermissionManagement",
      name: "rolePermissionManagement",
      meta: {
        title: "角色权限管理",
      },
      functionList: [enableDisable, normal_update, normal_add, normal_delete],
      component: () => import("../views/rolePermissionManagement/index.vue"),
      children: rolePermissionManagementChilds,
    },
  ],
  // 设备型号管理
  [
    {
      path: "/equipmentModelManagement",
      name: "equipmentModelManagement",
      meta: {
        title: "设备型号管理",
      },
      functionList: [normal_update, normal_add, normal_examine, upload_version],
      component: () => import("../views/equipmentModelManagement/index.vue"),
      children: equipmentModelManagementChilds,
    },
    {
      path: "/deviceLogManagement",
      name: "deviceLogManagement",
      meta: {
        title: "设备型号日志管理",
      },
      // functionList: [],
      component: () => import("../views/deviceLogManagement/index.vue"),
      // children: deviceLogManagementChilds,
    },
  ],
];

// 抛出 需要生成导入菜单的数据
export const exportMenusData = [
  {
    // 一级菜单标题
    meta: {
      title: "用户管理",
    },
    // 图标
    icon: "icon-user",
    children: USER_MANAGGERS,
  },
  {
    // 一级菜单标题
    meta: {
      title: "设备管理",
    },
    // 图标
    icon: "icon-shebei",
    children: DEVICE_MANAGGERS,
  },
];
// *********************************************************************************
// 配置数据 END
// *********************************************************************************

// *********************************************************************************
// 操作层

// 分解 菜单数据 生成 路由数据
const decomposeMenuDataRoutes = (menus, index = 0, result = []) => {
  const item = menus[index];

  // 结尾 抛出
  if (!item) {
    return result;
  }

  const { path, name, meta, component, children } = item;

  const params = {
    path,
    name,
    meta,
    component,
  };

  if (children) {
    // 处理子级
    const afterChilds = decomposeMenuDataRoutes(children, 0, []);

    // 保存 children 处理后的数据
    result.push(
      ...afterChilds.map((d) => {
        // 父级的title
        if (d.meta.parentTitles) {
          d.meta.parentTitles.unshift(meta.title);
        } else {
          d.meta.parentTitles = [meta.title];
        }
        return d;
      })
    );
  }

  // 限制
  if (path) {
    result.push(params);
  }

  return decomposeMenuDataRoutes(menus, index + 1, result);
};

// 抛出 路由数据
export default decomposeMenuDataRoutes(exportMenusData);
