<template>
  <div class="radio-active-types">
    <el-radio-group v-model="value" :disabled="disabled">
      <el-radio
        :label="item[radioProps.valueKey]"
        size="large"
        v-for="(item, index) in options"
        :key="index"
        >{{ item[radioProps.labelKey] }}</el-radio
      >
    </el-radio-group>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from "vue";

const emits = defineEmits(["update:modelValue"]);

const props = defineProps({
  // 使用的 类型
  type: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  radioProps: {
    type: Object,
    default: () => ({
      labelKey: "label",
      valueKey: "value",
    }),
  },
});

// 当前使用的 类型
const nowUseType = computed(() => props.type);
const value = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    value.value = nVal;
  }
);
watch(
  () => value.value,
  (nVal) => {
    // console.log(nVal);
    emits("update:modelValue", nVal);
  }
);

/* 查询 */
// 选择项列表
const options = ref(props.options);
watch(
  () => props.options,
  (nVal) => {
    options.value = nVal;
  }
);

// 查询 获取字典列表
const getDictList = async () => {
  const { radioProps } = props;

  // 赋值初始
  const [first] = options.value;
  if (first) {
    value.value = first[radioProps.valueKey];
  }
};

// 获取options
const getOptions = () => options.value;

// 初始
const init = async () => {
  await getDictList();
};

onMounted(() => {
  if (nowUseType.value) {
    init();
  }
});

defineExpose({
  init,
  getOptions,
});
</script>
<style lang="scss">
.radio-active-types {
  .el-radio__input.is-disabled + span.el-radio__label {
    cursor: text;
    color: #606266;
  }
  .el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: var(--el-color-primary) !important;
    background-color: var(--el-color-primary) !important;
    cursor: text;
  }
  .el-radio__input.is-disabled .el-radio__inner {
    cursor: text;
  }
}
</style>
