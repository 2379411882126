<template>
  <!-- 常规联动数据 -->
  <div class="tree-select-normal">
    <ml-tree-select
      ref="mlTreeSelectRef"
      v-model="changeValue"
      :data="currentData"
      :props="treeProps"
      @change="change"
      :checkStrictly="checkStrictly"
      clearable
      filterable
      :disabled="disabled"
      :placeholder="placeholder"
      :emptyPlaceholder="emptyPlaceholder"
      :showCheckbox="showCheckbox"
    >
    </ml-tree-select>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, watch } from "vue";

const emits = defineEmits(["change", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Array, Object, null],
    default: () => [],
  },
  // 任何节点都可以被选择
  checkStrictly: {
    type: Boolean,
    default: true,
  },
  showCheckbox: {
    type: Boolean,
    default: false,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  // 列表请求fetch名称
  fetchTreeName: {
    type: String,
    default: "",
  },
  // 占位符
  placeholder: {
    type: String,
    default: "请选择",
  },
  // 缺省占位符
  emptyPlaceholder: {
    type: String,
    default: "暂无",
  },
  // 数据结构
  treeProps: {
    type: Object,
    default: () => ({
      children: "children",
      label: "name",
      value: "id",
    }),
  },
  data: {
    type: Array,
    default: () => [],
  },
});

const { proxy } = getCurrentInstance();

/* 树形信息 */
const mlTreeSelectRef = ref();

/* tree 选择 */
// 选中的区域
const changeValue = ref(props.modelValue);
watch(
  () => changeValue.value,
  (nVal) => {
    emits("update:modelValue", nVal);
  },
  { deep: true }
);
watch(
  () => props.modelValue,
  (nVal) => {
    changeValue.value = nVal;
  },
  { deep: true }
);
// 数据
const currentData = ref(props.data);
watch(
  () => props.data,
  (nVal) => {
    currentData.value = nVal;
  },
  {
    deep: true,
  }
);
// 选择区域数据
const change = (res) => {
  // console.log(res);
  // console.log(changeValue.value);
  emits("update:modelValue", res);
  emits("change", res);
};
// 查询 树形数据
const getList = async () => {
  try {
    const res = await proxy.$storeDispatch(props.fetchTreeName);
    const result = res.result;
    currentData.value = result;
  } catch (error) {}
};

// 初始
const init = async () => {
  await getList();
};

/* 树形操作 */

// 获取当前选中节点
const getCurrentNode = () => mlTreeSelectRef.value.getCurrentNode();

defineExpose({
  init,
  getCurrentNode,
});
</script>

<style lang="scss" scoped>
.tree-select-normal {
  width: 100%;
}
</style>
