<template>
  <tool-dialog
    title="文件预览"
    :showBtn="false"
    ref="previewFileRef"
    className="preview-dialog fixed-area"
    :destroyOnClose="true"
    :showDialogClose="true"
    @dialogClosed="onDialogClosed"
  >
    <div class="preview-file-warpper" v-if="isWordType">
      <vue-office-docx
        class="ml-scrollbar"
        :src="`${usePreviewFileUrl}${useFilePath}`"
      />
      <!-- <vue-office-docx
        class="ml-scrollbar"
        src="https://501351981.github.io/vue-office/examples/dist/static/test-files/test.docx"
      /> -->
    </div>

    <div class="preview-file-warpper hide-scrollbar" v-if="isExcelType">
      <vue-office-excel
        class="ml-scrollbar"
        :src="`${usePreviewFileUrl}${useFilePath}`"
      />
      <!-- <vue-office-excel
        class="ml-scrollbar"
        src="https://501351981.github.io/vue-office/examples/dist/static/test-files/test.xlsx"
      /> -->
    </div>

    <div class="preview-file-warpper hide-scrollbar" v-if="isPDFType">
      <vue-office-pdf
        class="ml-scrollbar"
        :src="`${usePreviewFileUrl}${useFilePath}`"
      />
      <!-- <vue-office-pdf
        class="ml-scrollbar"
        src="https://501351981.github.io/vue-office/examples/dist/static/test-files/test.pdf"
      /> -->
      <!-- <vue-office-pdf
        class="ml-scrollbar"
        src="https://xican-flavor.oss-cn-shenzhen.aliyuncs.com/stall_contract_attachment/2023/2023-07-28/PDF_20230728191615971654649.pdf"
      /> -->
    </div>

    <div class="preview-file-warpper video hide-scrollbar" v-if="isVideoType">
      <video play controls :src="`${usePreviewFileUrl}${useFilePath}`"></video>
    </div>

    <div class="preview-file-warpper txt hide-scrollbar" v-if="isTxtType">
      <div class="txt-warpper" v-html="txtContent"></div>
    </div>
  </tool-dialog>

  <!-- 图片查看 -->
  <el-image-viewer
    v-if="showImageViewer"
    :hide-on-click-modal="true"
    :initial-index="0"
    @close="closeImageViewer"
    :url-list="previewImageList"
  ></el-image-viewer>
</template>

<script setup>
// docx文档引入、注册
import VueOfficeDocx from "@vue-office/docx";
import "@vue-office/docx/lib/index.css";

// excel文档引入、注册
import VueOfficeExcel from "@vue-office/excel";
import "@vue-office/excel/lib/index.css";

// pdf文档引入、注册
import VueOfficePdf from "@vue-office/pdf";

import { computed, nextTick, ref, getCurrentInstance } from "vue";
import { GLAFT_OPTIONS } from "@/utils/dataDictionary";
import { useGetMediumFileTypeModule } from "@/utils/mixin";

const emits = defineEmits(["onDialogClosed", "closeImageViewer"]);

const { proxy } = getCurrentInstance();

// 根据本地媒体文件数据获取类型
const { filterScopeConfig } = useGetMediumFileTypeModule();

/* 选项信息 */
// 文件类型
const GLAFT_TYPES = GLAFT_OPTIONS();
// 场景类型
const [
  IMAGE_TYPE,
  VIDEO_TYPE,
  PDF_TYPE,
  WORD_TYPE,
  EXCEL_TYPE,
  PPT_TYPE,
  TXT_TYPE,
  OTHER_TYPE,
] = GLAFT_TYPES;

/* 属性 */
const props = defineProps({
  filePath: {
    type: String,
    defualt: "",
  },
  // 是否使用 预览前缀路径
  isPreviewBeforeUrl: {
    type: Boolean,
    default: true,
  },
});
// 使用的文件路径
const useFilePath = ref();
const useFileName = ref();
// 使用的文件类型
const useFileType = computed(() => {
  const { isPreviewBeforeUrl } = props;

  // 根据是否使用 访问前缀判断
  const textValue = isPreviewBeforeUrl
    ? useFilePath.value || ""
    : useFileName.value;
  const [name, typeName] = textValue.split(".");
  return typeName || "";
});
// 获取标准类型
const fileNormalType = computed(() => {
  const item =
    filterScopeConfig.value.find((d) =>
      d.filterTypes.includes(useFileType.value)
    ) || {};
  return (item.resultItem || {}).value;
});

// 文件预览前缀地址
const usePreviewFileUrl = computed(() =>
  props.isPreviewBeforeUrl ? proxy.$previewFileUrl : ""
);

/* 文件类型 */
// 图片
const isImageType = computed(() => fileNormalType.value == IMAGE_TYPE.value);
// 视频
const isVideoType = computed(() => fileNormalType.value == VIDEO_TYPE.value);
// PDF
const isPDFType = computed(() => fileNormalType.value == PDF_TYPE.value);
// word
const isWordType = computed(() => fileNormalType.value == WORD_TYPE.value);
// excel
const isExcelType = computed(() => fileNormalType.value == EXCEL_TYPE.value);
// ppt
const isPptType = computed(() => fileNormalType.value == PPT_TYPE.value);
// 文本
const isTxtType = computed(() => fileNormalType.value == TXT_TYPE.value);

/* 弹窗 */
// ref
const previewFileRef = ref();
/* 打开文件 */
const openFileDialog = async (opt, file) => {
  // console.log(file);
  // 携带要打开的文件信息
  if (opt) {
    useFilePath.value = opt.filePath;
    useFileName.value = opt.fileName;
  }

  // 未找到本地可预览的文件类型
  if (!fileNormalType.value) {
    return;
  }

  // 图片不打开本地弹窗
  if (isImageType.value) {
    openImagePreview();
    return;
  }

  // txt文件
  if (isTxtType.value) {
    const fileName = opt.fileName || file.name;
    const resFile = await proxy.$getFileFromUrl(
      usePreviewFileUrl.value + opt.filePath,
      fileName,
      "txt"
    );

    var reader = new FileReader();
    // reader.readAsText(file, "GB2312");
    reader.readAsText(resFile, "UTF-8");

    reader.onerror = function () {
      proxy.$message({
        message: proxy.$LANG_TEXT("读取文件失败"),
      });
      loadingState.value = false;
    };
    reader.onload = function (e) {
      const val = e.target.result;
      const html = val.replaceAll("\r\n", "</br>");
      txtContent.value = html;
    };
  }

  nextTick(() => {
    previewFileRef.value.openDialog();
  });
};
/* 关闭文件 */
const closeFileDialog = () => {
  previewFileRef.value.closeDialog();
};

// ******************************************************************************************
// 数据操作 START
// ******************************************************************************************

/* 图片 */
// 图片展示
const showImageViewer = ref(false);
// 图片预览文件地址
const previewImageList = computed(() => [
  usePreviewFileUrl.value + useFilePath.value,
]);
// 打开图片预览
const openImagePreview = () => (showImageViewer.value = true);
// 关闭图片预览
const closeImagePreview = () => (showImageViewer.value = false);
// 监听图片点击关闭
const closeImageViewer = () => {
  closeImagePreview();

  emits("closeImageViewer");
};

// 监听弹窗关闭
const onDialogClosed = () => {
  emits("onDialogClosed");
};

/* txt文件预览 */
// 解析到的TXT文件内容
const txtContent = ref(``);

// ******************************************************************************************
// 数据操作 END
// ******************************************************************************************

defineExpose({
  openFileDialog,
  closeFileDialog,
});
</script>

<style lang="scss">
.preview-dialog {
  .preview-file-warpper {
    width: 1200px;
    height: 80vh;
    overflow-y: auto;

    &.video {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      width: auto;
      height: auto;
      max-width: 50vw;
      max-height: 60vh;

      video {
        width: 100%;
      }
    }

    > div {
      width: 100%;
      height: 100%;
    }

    > video {
    }
  }
}
</style>
