// 集合

// 入口
import mainStore from "./mainModule";
// 数据操作
import dataStore from "./dataModule";

export default {
  mainStore,
  dataStore,
};
