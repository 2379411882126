import introduceInfo from "./introduce-info/index.vue";
import lazySelect from "./lazy-select.vue";
import statusShow from "./status-show.vue";
import tagTips from "./tag-tips.vue";
import toolDialog from "./tool-dialog.vue";
import headSculpture from "./head-sculpture.vue";

/* 授权功能 */
import authButton from "./auth-function/button/index.vue";
import authSwitch from "./auth-function/switch/index.vue";
import authDownload from "./auth-function/download/index.vue";
import authLabel from "./auth-function/label/index.vue";

/* dialog */
import dangerConfirm from "./danger-confirm.vue";
import askConfirm from "./ask-confirm.vue";
import downloadConfirm from "./download-confirm.vue";

/* input */
import phoneVerificationInput from "./input/phone-verification/index.vue";

/* cascader */
import normalActionCascader from "./cascader/normal/index.vue";

/* tree */
import normalTree from "./tree/normal/index.vue";
import treeTemplateOne from "./tree/tree-template-one/index.vue";
import treeBrandClassify from "./tree/brand-classify/index.vue";
import treeBusinessCategory from "./tree/business-category/index.vue";
import treeAuthorityZone from "./tree/authority-zone/index.vue";
import treeNormalArea from "./tree/normal-area/index.vue";

/* tree-select */
import treeSelectOneChoose from "./tree-select/one-choose/index.vue";
import treeSelectOperateClassify from "./tree-select/operate-classify/index.vue";
import treeSelectNormal from "./tree-select/normal/index.vue";

/* image */
import imageMultifunctional from "./image/multifunctional/index.vue";

/* panel */
import panelMain from "./panel/main/index.vue";
import panelAction from "./panel/action/index.vue";
import panelManage from "./panel/manage/index.vue";

/* select */
import selectActiveTypes from "./select/active-types/index.vue";

/* radio */
import radioActiveTypes from "./radio/active-types/index.vue";

/* file */
import uploadFile from "./upload-file.vue";

/* dropdownBtn 展示更多按钮 */
import dropdownBtn from "./dropdown-btn.vue";

// 页面验证
import pageAccessVerification from "./page-access-verification.vue";
// 导出
import exportBtn from "./export-btn.vue";

// 展开更多
import expandMoreArea from "./expand-more-area/index.vue";

// 详情顶部导航
import detailNavbar from "./detail-navbar.vue";

const components = {
  introduceInfo,
  dangerConfirm,
  downloadConfirm,
  lazySelect,
  statusShow,
  tagTips,
  toolDialog,
  panelMain,
  panelAction,
  panelManage,
  authButton,
  authSwitch,
  authDownload,
  authLabel,
  headSculpture,
  phoneVerificationInput,
  normalActionCascader,
  normalTree,
  treeTemplateOne,
  treeNormalArea,
  treeBrandClassify,
  treeBusinessCategory,
  imageMultifunctional,
  askConfirm,
  treeAuthorityZone,
  treeSelectOneChoose,
  treeSelectOperateClassify,
  treeSelectNormal,
  selectActiveTypes,
  radioActiveTypes,
  uploadFile,
  dropdownBtn,
  pageAccessVerification,
  exportBtn,
  expandMoreArea,
  detailNavbar,
};

export default {
  install(Vue) {
    for (let i in components) {
      Vue.component(i, components[i]);
    }
  },
};
