<!-- 多功能弹窗 -->
<template>
  <div class="tool-dialog-container" :class="`${className}`">
    <el-dialog
      v-model="showDialog"
      :title="title"
      :width="useWidth"
      :show-close="showDialogClose"
      :top="marginTop"
      :destroy-on-close="destroyOnClose"
      @close="onClose"
      @closed="onClosed"
      @open="onOpen"
      :append-to-body="appendToBody"
      :draggable="true"
    >
      <!-- 自定义标题 -->
      <template #header>
        <slot name="header">
          <div class="header-box">
            <p class="title">{{ title }}</p>

            <el-image
              class="close-icon"
              :src="closeIcon"
              @click="closeDialog"
            ></el-image>
          </div>
        </slot>
      </template>

      <!-- 内容 -->

      <slot></slot>

      <!-- 按钮 -->
      <template #footer>
        <slot name="btn">
          <div class="btn-box" v-if="showBtn">
            <el-button
              size="default"
              class="tool-btn-white"
              v-if="showCancel"
              @click="cancel"
              >{{ cancelText }}</el-button
            >
            <el-button
              size="default"
              v-if="showConfirm"
              :class="`tool-btn-${btnConfirmType}`"
              :disabled="isDisabledConfirm"
              :type="btnConfirmType"
              @click="confirm"
              :loading="showLoading"
              >{{ showConfirmText }}</el-button
            >
          </div>
        </slot>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, watch, getCurrentInstance, computed } from "vue";
const { proxy } = getCurrentInstance();

const mainStore = proxy.$usePiniaModule("mainStore");

const emits = defineEmits(["cancel", "confirm", "dialogClose", "dialogClosed"]);

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  showTitlte: {
    type: Boolean,
    default: true,
  },
  width: {
    type: [String, Number],
    default: "",
  },
  btnConfirmType: {
    type: String,
    default: "primary",
  },
  confirmText: {
    type: String,
    default: "确认",
  },
  cancelText: {
    type: String,
    default: "取消",
  },
  showBtn: {
    type: Boolean,
    default: true,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
  showConfirm: {
    type: Boolean,
    default: true,
  },
  showDialogClose: {
    type: Boolean,
    default: false,
  },
  destroyOnClose: {
    type: Boolean,
    default: true,
  },
  // Dialog 自身是否插入至 body 元素上。 嵌套的 Dialog 必须指定该属性并赋值为 true
  appendToBody: {
    type: Boolean,
    default: false,
  },
  // 是否禁用确认按钮
  disabledConfirm: {
    type: Boolean,
    default: false,
  },
  // 是否显示标题底部标线
  showTitleBorderBottom: {
    type: Boolean,
    default: true,
  },
  // 是否显示按钮栏顶部标线
  showBtnBorderTop: {
    type: Boolean,
    default: true,
  },
  // 弹窗尺寸 small||normal||large
  size: {
    type: String,
    default: "normal",
  },
  // 确认时 加载提示文字
  confirmLoadingText: {
    type: String,
    default: "",
  },
  className: {
    type: String,
    default: "",
  },
  // dialog CSS 中的 margin-top 值，默认为 15vh
  marginTop:{
    type: String,
    default: "15vh",
  }
});

/* props */
// 尺寸
const sizeAreaWidths = {
  small: "400px",
  normal: "600px",
  large: "1200px",
};
// 使用宽度
const useWidth = computed(() => {
  const { width, size } = props;
  return width ? width : sizeAreaWidths[size];
});

const onClose = () => {
  mainStore.dialogShow = false;
  emits("dialogClose");
};
const onClosed = () => {
  mainStore.dialogShow = false;
  emits("dialogClosed");
};

const onOpen = () => {
  mainStore.dialogShow = true;
};

const showDialog = ref(false);
// 加载状态
const showLoading = ref(false);

const isDisabledConfirm = ref(props.disabledConfirm);
watch(
  () => props.disabledConfirm,
  (nVal) => {
    isDisabledConfirm.value = nVal;
  }
);

// 显示的确认按钮文字
const showConfirmText = computed(() => {
  const { confirmLoadingText, confirmText } = props;
  return showLoading.value ? confirmLoadingText || confirmText : confirmText;
});

// 关闭图标
const closeIcon = require("@/assets/images/close-icon.png");

// 按钮
// 取消
const cancel = () => {
  closeDialog();
  call();
  emits("cancel");
};
// 确认
const confirm = proxy.$bypass(() => {
  if (showLoading.value) {
    return;
  }
  showLoading.value = true;
  emits("confirm", call);
});
// 操作回调
const call = () => {
  showLoading.value = false;
};

// 关闭弹窗
const closeDialog = () => {
  showDialog.value = false;
};
// 打开弹窗
const openDialog = () => {
  showDialog.value = true;
};

// 导出
defineExpose({
  showDialog,
  closeDialog,
  openDialog,
});
</script>

<style lang="scss">
.tool-dialog-container {
  .el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      padding: 15px 0 15px 15px !important;
      border-bottom: 1px solid rgba(243, 243, 243, 1);

      .el-dialog__title {
        font-size: 16px;
      }
      .el-dialog__headerbtn {
        top: 0px;
      }

      .header-box {
        font-weight: 700;
        font-size: 14px;
        // color: #555b61;
        color: var(--el-text-color-regular);
        position: relative;

        .title {
          font-size: 14px !important;
          font-weight: 700 !important;
        }

        .close-icon {
          width: 22px;
          height: 22px;
          position: absolute;
          right: 0;
          top: -1px;
          cursor: pointer;
        }
      }

      .btn-box {
        overflow: hidden;
      }
    }

    .el-dialog__body {
      padding: 10px 20px;
      border-bottom: 1px solid rgba(243, 243, 243, 1);
    }

    .el-dialog__footer {
      padding: 10px !important;
      display: flex;
      flex-direction: row-reverse;
      padding-top: 0;
      // border-top: 1px solid var(--el-color-info-light-8);

      .el-button {
        &.is-loading {
          padding: 0 10px !important;
        }
      }
    }
  }
}
</style>
