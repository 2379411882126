// 用户管理

export default [
  /* 存储桶 */
  // 添加存储桶
  {
    name: "addStorageBucket",
    url: "/bucket/addStorageBucket",
    method: "post",
  },
  // 修改存储桶
  {
    name: "modifyStorageBucket",
    url: "/bucket/modifyStorageBucket",
    method: "put",
  },
  // 获取存储桶分页列表
  {
    name: "getStorageBucketPageList",
    url: "/bucket/getStorageBucketPageList",
    method: "get",
  },
  // 获取存储桶列表
  {
    name: "getStorageBucketList",
    url: "/bucket/getStorageBucketList",
    method: "get",
  },
  // 获取存储桶详情
  {
    name: "getStorageBucketDetails",
    url: "/bucket/getStorageBucketDetails",
    method: "get",
  },
  // 删除存储桶
  {
    name: "removeStorageBucket",
    url: "/bucket/removeStorageBucket",
    method: "delete",
  },

  /* 存储目录 */
  // 添加存储目录
  { name: "addStorageDir", url: "/dir/addStorageDir", method: "post" },
  // 修改存储目录
  {
    name: "modifyStorageDir",
    url: "/dir/modifyStorageDir",
    method: "put",
  },
  // 获取存储目录分页列表
  {
    name: "getStorageDirPageList",
    url: "/dir/getStorageDirPageList",
    method: "get",
  },
  // 获取存储目录列表
  {
    name: "getStorageDirList",
    url: "/dir/getStorageDirList",
    method: "get",
  },
  // 获取存储桶详情
  {
    name: "getStorageDirDetails",
    url: "/dir/getStorageDirDetails",
    method: "get",
  },
  // 删除存储目录
  {
    name: "removeStorageDir",
    url: "/dir/removeStorageDir",
    method: "delete",
  },
  // 获取存储目录选择器
  {
    name: "getStorageDirChooser",
    url: "/dir/getStorageDirChooser",
    method: "get",
  },

  /* 服务文件 */
  // 获取存储文件分页列表
  {
    name: "getStorageFilePageList",
    url: "/file/getStorageFilePageList",
    method: "get",
  },
  // 文件下载
  { name: "download", url: "/aliyun/oss/download", method: "get" },
  // 获取存储文件目录列表
  {
    name: "getStorageFileDirList",
    url: "/file/getStorageFileDirList",
    method: "get",
  },
  // 获取存储文件分页列表
  {
    name: "getStorageFilePageList",
    url: "/file/getStorageFilePageList",
    method: "get",
  },
  // 获取存储文件详情
  {
    name: "getStorageFileDetails",
    url: "/file/getStorageFileDetails",
    method: "get",
  },
  // 删除存储文件
  {
    name: "batchRemoveStorageFile",
    url: "/file/batchRemoveStorageFile",
    method: "delete",
  },
  // 修改存储文件状态
  {
    name: "modifyAccessRightsType",
    url: "/file/modifyAccessRightsType",
    method: "put",
  },
  // 恢复存储文件
  {
    name: "recoveryStorageFile",
    url: "/file/recoveryStorageFile",
    method: "post",
  },
  // 删除回收站文件
  {
    name: "batchDeleteRecycleBinFile",
    url: "/file/batchDeleteRecycleBinFile",
    method: "delete",
  },
  // 清空回收站文件
  {
    name: "cleanRecycleBinFile",
    url: "/file/cleanRecycleBinFile",
    method: "delete",
  },



  /* 统计概览 */
  // 获取系统磁盘容量
  {
    name: "getSystemDiskCapacity",
    url: "/file/getSystemDiskCapacity",
    method: "get",
  },
  // 获取存储文件使用大小
  {
    name: "getStorageFileUsedSpace",
    url: "/file/getStorageFileUsedSpace",
    method: "get",
  },


  /* 文件图标 */
  // 添加存储文件图标
  {
    name: "addStorageFileIcon",
    url: "/file/icon/addStorageFileIcon",
    method: "post",
  },
  // 获取存储文件标签列表
  {
    name: "getStorageFileIconLabelList",
    url: "/file/icon/getStorageFileIconLabelList",
    method: "get",
  },
  // 获取存储文件图标列表
  {
    name: "getStorageFileIconList",
    url: "/file/icon/getStorageFileIconList",
    method: "get",
  },
  // 获取存储文件图标详情
  {
    name: "getStorageFileIconDetails",
    url: "/file/icon/getStorageFileIconDetails",
    method: "get",
  },
  // 修改存储文件图标
  {
    name: "modifyStorageFileIcon",
    url: "/file/icon/modifyStorageFileIcon",
    method: "put",
  },
  // 删除存储文件图标
  {
    name: "removeStorageFileIcon",
    url: "/file/icon/removeStorageFileIcon",
    method: "delete",
  },
];
