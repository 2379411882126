<template>
  <div class="ml-tree-select">
    <el-tree-select
      :placeholder="usePlaceholder"
      :node-key="nodeKey"
      ref="elTreeSelectRef"
      v-model="changeTreeValue"
      :disabled="disabled"
      :data="treeData"
      :filter-node-method="filterNodeMethod"
      :props="treeProps"
      @check-change="checkChange"
      @check="checkSomeChange"
      @change="treeSelectChange"
      :check-strictly="checkStrictly"
      :showCheckbox="showCheckbox"
      :clearable="clearable"
      :filterable="filterable"
      :check-on-click-node="checkOnClickNode"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :default-expand-all="defaultExpandAll"
      :popper-class="popperClass"
      :max-collapse-tags="maxCollapseTags"
      collapse-tags-tooltip
    />
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch, getCurrentInstance } from "vue";

const emits = defineEmits(["change", "checkChange", "", "update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Array, Object, null],
    defualt: () => [],
  },
  props: {
    type: Object,
    default: () => ({
      label: "label",
      children: "children",
    }),
  },
  filterNodeMethod: {
    type: [Function, null],
    defualt: null,
  },
  filterable: {
    type: Boolean,
    defualt: true,
  },
  data: {
    type: Array,
    defualt: () => [],
  },
  checkStrictly: {
    type: Boolean,
    defualt: false,
  },
  showCheckbox: {
    type: Boolean,
    defualt: false,
  },
  clearable: {
    type: Boolean,
    defualt: true,
  },
  checkOnClickNode: {
    type: Boolean,
    defualt: false,
  },
  // 多选
  multiple: {
    type: Boolean,
    defualt: false,
  },
  collapseTags: {
    type: Boolean,
    defualt: false,
  },
  placeholder: {
    type: String,
    defualt: "请选择",
  },
  // 缺省占位符
  emptyPlaceholder: {
    type: String,
    default: "暂无",
  },
  // 最大显示折叠数量
  maxCollapseTags: {
    type: Number,
    defualt: 1,
  },
  nodeKey: {
    type: String,
    defualt: "id",
  },
  defaultExpandAll: {
    type: Boolean,
    defualt: false,
  },
  // 选择器下拉菜单的自定义类名
  popperClass: {
    type: String,
    defualt: "ml-select-tree__popper",
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

/* 属性参数 */
/* 数据 */
const treeData = computed(() => props.data);

const treeProps = computed(() => props.props);

// ref
const elTreeSelectRef = ref();

/* 选中 */
const changeTreeValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    changeTreeValue.value = nVal;
  },
  {
    deep: true,
  }
);

// 占位符显示
const usePlaceholder = computed(() => {
  const { placeholder, disabled, emptyPlaceholder } = props;
  const val = changeTreeValue.value;

  // 显示缺省占位符
  const showEmptyPlaceholder = disabled && proxy.$isEmpty(val);
  return showEmptyPlaceholder ? emptyPlaceholder : placeholder;
});

// 监听选中改变
const checkSomeChange = (
  current,
  { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys }
) => {
  // console.log(checkedKeys);
  // 拦截 勾选
  // if (props.showCheckbox) {
  //   return;
  // }
  const value = checkedKeys;
  let result = value;

  // 修改赋值数据
  // 勾选
  if (props.showCheckbox) {
    if (typeof value === "string") {
      result = [value];
    }
  }

  emits("update:modelValue", result);
  emits("change", result, current, {
    checkedNodes,
    checkedKeys,
    halfCheckedNodes,
    halfCheckedKeys,
  });
};

// 监听 点击勾选改变
const checkChange = (current, checked) => {
  nextTick(() => {
    const checkedNodes = elTreeSelectRef.value.getCheckedNodes();

    // console.log(changeTreeValue.value);
    emits("update:modelValue", changeTreeValue.value);
    emits("checkChange", changeTreeValue.value, checkedNodes, {
      current,
      checked,
    });
  });
};

// 监听选择变化
const treeSelectChange = (res) => {
  // 非多选
  if (!props.showCheckbox) {
    emits("update:modelValue", res);
    emits("change", res);
  }
};

// 监听关闭
const onClose = () => {
  // console.log(1)
};

/* 树形操作 */

// 获取当前选中节点
const getCurrentNode = (node) => elTreeSelectRef.value.getCurrentNode(node);

// 若节点可用被选中 (show-checkbox 为 true), 它将返回当前选中节点 key 的数组
const getCheckedKeys = (leafOnly) =>
  elTreeSelectRef.value.getCheckedKeys(leafOnly);

// 通过keys设置 选中
const setCheckedKeys = (keys, leafOnly) =>
  elTreeSelectRef.value.setCheckedKeys(keys, leafOnly);

// 通过 key 设置某个节点的当前选中状态，使用此方法必须设置 node-key  属性
const setChecked = (res, checked, deep) => {
  elTreeSelectRef.value.setChecked(res, checked, deep);
};

defineExpose({
  getCurrentNode,
  setCheckedKeys,
  setChecked,
  getCheckedKeys,
});
</script>

<style lang="scss">
.ml-tree-select {
  .el-select {
    .select-trigger {
      height: 100%;

      .el-select__tags {
        .el-select-tags-wrapper {
          padding-top: 0;
          // padding-bottom: 2px;
        }
        .el-select__input {
          margin-left: 0;
        }
      }
    }

    .el-input {
      &.el-input--default {
        .el-input__wrapper {
          .el-input__inner {
            min-height: 34px;
          }
        }
      }

      .el-input__wrapper {
        height: 100%;
        box-sizing: border-box;
      }
      .el-select__tags {
        transform: translateY(calc(-50% - -1px));
      }
    }

    &--default {
      $selectHeight: 34px;
      min-height: $selectHeight;

      .el-input {
        // height: calc($selectHeight - 2px);
        min-height: $selectHeight;
      }
    }
  }
}

.inline-block {
  .ml-tree-select {
    width: 100%;
    .el-select,
    .el-input {
      width: 100%;
    }
  }
}
</style>
