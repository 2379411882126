import piniaStore from "@/piniaStore/index";
import { STORE_NAME_AUTH_TOKEN } from "@/utils/storageTables";
import {
  previewFileUrl,
  downloadFileUrl,
  operateReportFileUrl,
} from "./apiConfig";
import { useGetMediumFileTypeModule } from "@/utils/mixin";

// import * as allDataDictionary from "./dataDictionary";
// import { folderOpts } from "@/utils/options";
// ****************************************************************
// 校验 模块 START
// ****************************************************************
// 缺省
export const isEmpty = (val) => ["", undefined, null].includes(val);

// 判断数据是否有值 数组、普通对象、字符值
export const isHasValue = (currData = "") => {
  // 是否存在Object原型对象
  if (currData instanceof Object) {
    // 是否是数组 []
    if (Array.isArray(currData)) {
      return !!currData.length;
    } else {
      // 标准对象 {}
      const objLen = Object.keys(currData);
      return !!objLen.length;
    }
  } else {
    const isHasVal = !isEmpty(currData);
    // 有值
    if (isHasVal) {
      if (typeof currData == "number") {
        return true;
      } else {
        const joinStr = currData.split(" ").join("");
        return !isEmpty(joinStr);
      }
    } else {
      // 字符串、null、undefined
      return false;
    }
  }
};

// 常规 校验表单
export const testForm = (call, refEl) => {
  return new Promise((resolve) => {
    try {
      refEl.validate((valid, fields) => {
        if (!valid) {
          call ? call() : "";
        }
        resolve(valid);
      });
    } catch (error) {
      console.log(error);
    }
  });
};

// 按属性列表 校验表单
export const testFormProps = async (props = {}, call, refEl) => {
  // 属性对象转数组
  const propsArray = Object.keys(props);

  if (!propsArray.length) {
    return true;
  }

  try {
    await refEl.validateField(propsArray);
    return true;
  } catch (error) {
    call ? call() : "";
    return false;
  }
};

// 获取数据 更新后的数据
export const getDataUpdateAfter = (originData, newData, result) => {
  const typeofOriginData = typeof originData;

  // 字符串
  // console.log(typeofOriginData);
  if (["string", "number"].includes(typeofOriginData)) {
    // 有值
    if (!isEmpty(originData) && !isEmpty(newData)) {
      //值 不相等
      if (originData != newData) {
        result = newData;
      }
    } else {
      //值 不相等
      if (originData !== newData) {
        result = newData;
      }
    }
  } else {
    // 数组
    const originDataArrayType = Array.isArray(originData);
    const newDataArrayType = Array.isArray(newData);

    // 常规对象
    const originDataObjectType = !Array.isArray(originData);
    const newDataObjectType = !Array.isArray(newData);

    // 常规对象 对比
    if (originDataObjectType && newDataObjectType) {
      result = {};
      // console.log("常规对象 对比");
      // console.log(originDataObjectType, newDataObjectType);

      /* 处理对象 */
      const originProps = Object.getOwnPropertyNames(originData || {});
      const newProps = Object.getOwnPropertyNames(newData || {});

      // console.log(originProps);
      // console.log(newProps);
      // 遍历新数据
      for (let i = 0; i < newProps.length; i++) {
        //属性名
        const origin_propName = originProps[i];
        const new_propName = newProps[i];

        //值
        const origin_value = originData[new_propName];
        const new_value = newData[new_propName];

        // console.log(new_propName);
        //origin 不存在new的 参数
        if (!originData.hasOwnProperty(new_propName)) {
          result[new_propName] = new_value;
        } else {
          //对象属性
          if (origin_value instanceof Object) {
            const comparisonResult = getDataUpdateAfter(
              origin_value || {},
              new_value || {}
            );

            // console.log(comparisonResult);
            // 非数组
            if (!Array.isArray(comparisonResult)) {
              const resultLength = Object.keys(comparisonResult).length;

              // 如果存在值这说明不相同
              if (resultLength) {
                result[new_propName] = comparisonResult;
              }
            }

            // 数组
            if (Array.isArray(comparisonResult)) {
              // console.log(origin_propName);
              // console.log(new_propName);
              // console.log(origin_value);
              // console.log(new_value);
              // console.log(comparisonResult);
              // 有变化
              if (comparisonResult.length) {
                result[new_propName] = comparisonResult;
              } else {
                // 对比后 无变化

                // 原数据有值 && 新数据无值
                if (origin_value.length && !new_value.length) {
                  result[new_propName] = [];
                }
              }
            }
          } else {
            // 有值
            if (!isEmpty(origin_value) && !isEmpty(new_value)) {
              //值 不相等
              if (origin_value != new_value) {
                result[new_propName] = new_value;
              }
            } else {
              //值 不相等
              if (origin_value !== new_value) {
                result[new_propName] = new_value;
              }
            }
          }
        }
      }
      // console.log(originDataObjectType, newDataObjectType);
      // console.log("常规对象 对比", result);
      // console.log(originData);
      // console.log(newData);
    } else if (originDataArrayType && newDataArrayType) {
      //数组 对比
      result = [];
      // console.log("常规数组 对比");
      // console.log(originDataArrayType, newDataArrayType);

      const originArrayLength = originData.length;
      const newArrayLength = newData.length;

      // console.log(originArrayLength);
      // console.log(newArrayLength);
      // 对比长度
      if (originArrayLength === newArrayLength) {
        let isPass = true;
        for (let i = 0; i < originData.length; i++) {
          let origin_item = originData[i];
          let new_item = newData[i];
          // console.log(origin_item);
          // console.log(new_item);
          const comparisonResult = getDataUpdateAfter(origin_item, new_item);
          if (!isEmpty(comparisonResult)) {
            // 有效值
            if (typeof comparisonResult != "object") {
              isPass = false;
            } else {
              if (!Array.isArray(comparisonResult)) {
                const resultLength = Object.keys(comparisonResult).length;

                // 如果存在值这说明不相同
                if (resultLength) {
                  isPass = false;
                }
              }

              if (Array.isArray(comparisonResult)) {
                if (comparisonResult.length) {
                  isPass = false;
                }
              }
            }
          }
        }

        // 不通过
        if (!isPass) {
          // 赋值对象数据
          result = newData;
          // console.log("常规数组 对比 不通过 赋值替换");
        }
      } else {
        // console.log("常规数组 对比 长度不一致 赋值替换");
        result = newData;
      }
    } else {
      // console.log("常规对象、数组 对比 类型不一致 赋值替换");
      result = newData;
    }
  }

  return result;
};

// ****************************************************************
// 校验 模块 END
// ****************************************************************

// ******************************************************************
// 基础处理 START
// ******************************************************************

// 处理对象转 路径携带参数字符串
export const paramToStr = (data) => {
  if (!data) {
    return "";
  }

  return Object.keys(data)
    .map((item) => `${item}=${data[item]}`)
    .join("&");
};

// 设置缓存
export const setItem = (name, data) => {
  window.localStorage.setItem(name, data);
};

// 获取缓存
export const getItem = (name) => {
  return window.localStorage.getItem(name);
};

// 获取本地 Token
export const getStoreAgeToken = () => getItem(STORE_NAME_AUTH_TOKEN);

// 清除本地 授权Token
export const clearStoreAgeToken = () => {
  setItem(STORE_NAME_AUTH_TOKEN, "");
};

// 处理请求响应数据
export const storeResponse = (res, call, isCustomUrl) => {
  if (res && res.code === 200) {
    if (call) {
      call();
    }
    return res;
  } else {
    // 是否是自定义路径
    if (isCustomUrl) {
      return res;
    } else {
      if (res && res.code) {
        return Promise.reject(res);
      } else {
        return Promise.reject(false);
      }
    }
  }
};

// 提示操作
// 成功提示
export const successMsg = function (msg) {
  this.$message.success(msg);
};
// 异常提示
export const warningMsg = function (msg) {
  this.$message.warning(msg);
};

/* vuex */
// dispatch调用
export const storeDispatch = function () {
  return this.$store.dispatch(...arguments);
};
// commit调用
export const storeCommit = function (name, params) {
  return this.$store.commit(name, params);
};
// getters调用
export const storeGetters = function (name, params) {
  return this.$store.getters[name];
};

// 调用pinia模块
export const usePiniaModule = function (useName) {
  if (useName) {
    return piniaStore[useName]();
  } else {
    const obj = {};
    for (let i in piniaStore) {
      obj[i] = piniaStore[i]();
    }
    return obj;
  }
};

// 处理价格
export const thousandsPrice = (price = 0, opt) => {
  const { isToFixedNum = false, toFixedNum = 2 } = opt || {};
  price = price || 0;
  const number_price = isToFixedNum ? price : Number(price).toFixed(toFixedNum);
  const string_price = number_price.toString();
  const floatIndex = string_price.indexOf(".");
  let integer_num = string_price;
  let float_num = "";
  if (floatIndex !== -1) {
    const floatRes = string_price.slice(0, floatIndex);
    integer_num = floatRes;
    float_num = string_price.slice(floatIndex);
  }

  /**
   * 第一次reverse 把数字排序改为倒序 1234=4321
   * 第二次reverse 把截取分组排序纠正 [[432],[1]] = [[1],[432]]
   * 第三次reverse 把截取分组里面的数字排序纠正 [1][432] = [1][234]
   */
  // 分组
  const map_pirce = integer_num
    .split("")
    .reverse()
    .reduce((add, curr, index) => {
      const addLen = add.length;
      if (index % 3) {
        add[addLen - 1].push(curr);
      } else {
        add.push([curr]);
      }
      return add;
    }, [])
    .reverse()
    .map((d) => d.reverse().join(""));

  const result = map_pirce.join(",") + float_num;
  return result;
};

// 解绑上下文
export const removeDecontextAttr = (data = {}) => {
  return JSON.parse(JSON.stringify(data));
};

// 根据值查询options列表项
export const getOptsItem = function (value, options = [], config) {
  const { valueKey = "value", returnKey = "label" } = config || {};
  const item = options.find((item) => item[valueKey] == value) || {};

  if (returnKey == "object") {
    return item;
  } else {
    return item[returnKey] || "";
  }
};

/* 对字符串或 数组string[] 进行去重操作 并 转为字符串 */
export const getNamesDeleteTepeat = (result, opts) => {
  const { splitBar = ",", joinBar = "、" } = opts || {};

  const result_type = typeof (result || "");

  let str_arr = result;

  // 字符串
  if (result_type === "string") {
    str_arr = (result || "").split(splitBar);
  }

  return str_arr
    .reduce((add, curr, index) => {
      if (!add.includes(curr)) {
        add.push(curr);
      }
      return add;
    }, [])
    .join(joinBar);
};

/**
 * 递归查询关键词索引 操作数据
 * @params {object} originData
 * @params {object} params
 * @params {object} opt
 * @param {object} opt.mode 操作模式 update||add||delete
 *
 */
export const setOperateKeywordSearch = (
  originData,
  params,
  opt,
  callback = () => {}
) => {
  try {
    const {
      tableRef,
      keyword = "id",
      parentKey = "parentId",
      levelKey = "level",
      props = { children: "children" },
      mode = "update",
      tableLazyParentStoreData, //表格懒加载静态缓存
    } = opt || {};

    // console.log(originData);
    // console.log(params);
    // console.log(opt);
    // const originData = tableData.value.result;

    // 检索值
    const keywordValue = params[keyword];
    // 查找 检索查询
    const itemIndex = originData.findIndex((d) => d[keyword] == keywordValue);

    // 修改
    if (mode == "update") {
      // console.log(params);
      // 定位到
      if (itemIndex != -1) {
        const parentId = Number(params[parentKey] || 0);
        //  检索查询 父级所在
        const parentIndex = originData.findIndex((d) => d[keyword] == parentId);

        // delete params[props.children];
        const { children = [], ...otherParam } = params;
        updateParams(originData[itemIndex], otherParam);

        const { id } = originData[itemIndex];

        // 更新当前项
        tableLazyParentStoreData[id] = children;
        originData[itemIndex].children = children;

        // 更新当前在父级的信息
        if (parentIndex != -1) {
          tableLazyParentStoreData[parentId] = originData[itemIndex];
        }
        // console.log(parentId)
        // console.log(originData);
        // console.log(children);
        // console.log(originData[itemIndex]);
        // console.log(tableLazyParentStoreData);
        callback();
        return;
      }
    } else if (mode == "add") {
      const parentId = Number(params[parentKey] || 0);
      // console.log(params);
      // console.log(parentKey);
      // console.log(parentId);

      // 子级
      if (parentId) {
        //  检索查询 父级所在
        const parentIndex = originData.findIndex((d) => d[keyword] == parentId);

        if (parentIndex != -1) {
          // console.log("检索到父级");
          // console.log("父级" + parentIndex);
          // 赋值
          originData[parentIndex].hasChildren = true;
          // console.log(params);
          // console.log(originData);
          // console.log(originData[parentIndex]);
          // console.log(tableLazyParentStoreData);
          if ((originData[parentIndex].children || []).length) {
            originData[parentIndex].children.push(params);
          } else {
            originData[parentIndex].children = [params];
          }
          // console.log(originData[parentIndex]);

          // 同步
          if (tableLazyParentStoreData) {
            const newItem = originData[parentIndex];
            const newChildren = newItem.children;
            const thatParentId = newItem[keyword];
            // console.log(thatParentId);
            // console.log(newChildren);
            tableLazyParentStoreData[thatParentId] = newChildren;

            // 更新ref内的store
            tableRef.value.store.states.lazyTreeNodeMap.value[thatParentId] =
              newChildren;
          }
          // console.log(parentId);
          // console.log(originData[parentIndex]);
          // console.log(tableLazyParentStoreData);

          callback();
          return;
        }
      } else {
        // console.log("父级0");
        // console.log(params);
        // 父级0
        originData.push(params);
        callback();
        return;
      }
    } else if (mode == "delete") {
      // console.log(mode, itemIndex);
      // console.log(originData);
      // 定位到
      if (itemIndex != -1) {
        const delItem = removeDecontextAttr(originData[itemIndex]);
        originData.splice(itemIndex, 1);

        const currParnetId = delItem[parentKey];
        // 处理父级
        // const parentItem = tableLazyParentStoreData[currParnetId];
        // console.log(parentItem);
        // console.log(currParnetId);
        tableLazyParentStoreData[currParnetId] = originData;

        // console.log(originData);
        // console.log(tableLazyParentStoreData);
        setTimeout(() => {
          // // 重置
          // if (!originData.length) {
          //   tableRef.value.store.states.lazyTreeNodeMap.value[currParnetId] =
          //     [];
          // }

          tableRef.value.store.states.lazyTreeNodeMap.value[currParnetId] =
            originData;
        });

        callback();
        return;
      }
    }

    // 遍历
    // console.log("遍历", originData);
    for (let i = 0; i < originData.length; i++) {
      const item = originData[i];
      const children = item[props.children] || [];
      // console.log("递归遍历", children);
      setOperateKeywordSearch(children, params, opt, callback);
    }
  } catch (error) {
    console.log(error);

    return {};
  }
};

// 保留数字小数点后几位小数
export const toFixedSomeNumber = (num = 0, lastIndex = 2, opt) => {
  const num_str = num.toString();
  const reg = new RegExp(`^\\d+(?:\\.\\d{0,${lastIndex}})?`);
  let num_float_str = Number(num_str.match(reg)).toString();

  const {
    // 按需显示
    isDemand = true,
  } = opt || {};

  // 判断小数点
  if (num_float_str.indexOf(".") != -1) {
    let [number = "0", floatNum = "0"] = num_float_str.split(".");
    num_float_str = `${number}.${floatNum.padEnd(2, "0")}`;
  } else {
    const after = new Array(lastIndex).fill(0).join("");
    num_float_str += "." + after;
  }

  if (isDemand) {
    return Number(num_float_str);
  }

  return num_float_str;
};

// ******************************************************************
// 基础处理 END
// ******************************************************************

// ******************************************************************
// 时间处理 START
// ******************************************************************
// 时间

// 转换 补位字符数字
export const numberToAll = (val = "") => {
  const result = val.toString();
  return result.padStart(2, "0");
};

// 时间规则
export const timeRules = (date) => {
  if (isEmpty(date)) {
    return "";
  }

  // 将日期字符串转换为时间戳
  // 指定时间
  const after_time = date; // 兼容ios格式
  const data = new Date(after_time);
  const time = data.getTime(); // 时间戳

  // 获取当前时间戳
  const nowTime = new Date().getTime();

  // 获取年月日时分秒
  const YYYY = data.getFullYear();
  const MM = data.getMonth() + 1;
  const DD = data.getDate();
  const hh = data.getHours();
  const mm = data.getMinutes();
  const ss = data.getSeconds();

  // 计算时间差异（分钟）
  const diff = nowTime - time;

  return {
    YYYY: numberToAll(YYYY),
    MM: numberToAll(MM),
    DD: numberToAll(DD),
    hh: numberToAll(hh),
    mm: numberToAll(mm),
    ss: numberToAll(ss),
    diff,
  };
};

// 根据时间字符转换中文时间字符
export const timeDateToYMDhms = (time) => {
  if (!time) {
    return;
  }
  const [YMD, hms] = time.split(" ");
  const [YYYY, MM, DD] = YMD.split("-");
  return `${YYYY}年${MM}月${DD}日 ${hms}`;
};

// 根据时间格式 转换数据
export const formatArrayToData = (arr = []) => {
  const format_YMD = ["YYYY", "MM", "DD"];
  const YMD = arr
    .filter((item) => format_YMD.includes(item.type))
    .map((item) => item.value)
    .join("-");

  const format_hms = ["hh", "mm", "ss"];
  const hms = arr
    .filter((item) => format_hms.includes(item.type))
    .map((item) => item.value)
    .join(":");

  let result = "";
  if (YMD) {
    result += YMD;
  }
  if (hms) {
    result += hms;
  }

  return result;
};

// 根据时间戳获取时间
export const timeSpToDate = (time, afterType) => {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let result = `${year}-${numberToAll(month)}-${numberToAll(day)}`;

  const nopass = [undefined, null, ""];
  if (!nopass.includes(afterType)) {
    const endAfters = ["00:00:00", "23:59:59"];
    const resAfter = endAfters[afterType];
    result += " " + resAfter;
  }

  return result;
};

// 根据时间戳获取 time时间
export const timeStampToTime = (time, config) => {
  if (isEmpty(time)) {
    return "-";
  }
  const { format = "YYYY-MM-DD hh:mm:ss" } = config || {};

  const { YYYY, MM, DD, hh, mm, ss } = timeRules(time || new Date());

  const params = {
    YYYY,
    MM,
    DD,
    hh,
    mm,
    ss,
  };

  let timeResult = "";

  const [YMD = "", hms = ""] = format.split(" ");

  if (YMD) {
    const formats = YMD.split("-");
    const result = formats.map((item) => numberToAll(params[item]));
    timeResult += result.join("-");
  }

  if (hms) {
    const formats = hms.split(":");
    const result = formats.map((item) => numberToAll(params[item]));
    timeResult += (YMD ? " " : "") + result.join(":");
  }
  return timeResult;
};
// ******************************************************************
// 时间处理 END
// ******************************************************************

// ****************************************************************
// 文件上传 模块 START
// ****************************************************************

// 上传接口
export const uploadFile = function (files, opt, callback) {
  const { isUpload = true, ...residueParams } = opt || {};

  // console.log("上传接口 uploadFile>>>>>>>>>>>>>>>>>>>>");
  return new Promise(async (resolve, reject) => {
    // console.log(files);
    // console.log(opt);
    if (!files.length) {
      resolve([]);
    }

    // 上传
    if (isUpload) {
      try {
        // 文件夹类型
        // 参数
        const formdata = new FormData();

        for (let i = 0; i < files.length; i++) {
          formdata.append("files", files[i]);
        }
        for (const key in residueParams) {
          if (Object.hasOwnProperty.call(residueParams, key)) {
            const val = residueParams[key];
            formdata.append(key, val);
          }
        }

        const data = await this.$storeDispatch("fetchUploadFile", {
          formdata,
          callback,
        });
        const result = data.result.files || [];
        // console.log(result);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    } else {
      const readerFiles = await getInputFileReader(files);
      resolve(readerFiles);
    }
  });
};

// 普通上传图片功能
export const changeImageFile = function (opt) {
  const that = this;
  const { multi } = opt || {};
  const input = document.createElement("input");
  input.type = "file";

  input.accept = ".png,.jpg,.jpeg,.ico,";
  input.multiple = multi;

  // console.log("changeImageFile");
  return getInputFileUploadBefore(input, opt, that);
};

// 普通上传文件功能
export const changeNormalFile = function (opt) {
  const { filterScopeConfig } = useGetMediumFileTypeModule();
  // // 获取所有类型
  // const allFileTypes = filterScopeConfig.value.reduce((acc, curr) => {
  //   const types = curr.filterTypes.map((d) => `.${d}`);
  //   return acc.concat(types);
  // }, []);

  const that = this;
  const { multi } = opt || {};
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "*";
  input.multiple = multi;
  return getInputFileUploadBefore(input, opt, that);
};

// 处理 创建input后的上传前步骤
const getInputFileUploadBefore = (input, opt, that) => {
  const { count = 1 } = opt || {};
  return new Promise((resolve, reject) => {
    input.onchange = async function (res) {
      const files = [];
      const fileList = this.files;
      const fileLength = this.files.length;
      for (let i = 0; i < fileLength; i++) {
        const item = fileList[i];
        files.push(item);
      }
      // console.log("getInputFileUploadBefore");
      // console.log(files);
      const result = await uploadFile.call(that, files.slice(0, count), opt);

      input.remove();
      resolve(result);
    };

    // 监听取消;
    document.body.onfocus = function () {
      setTimeout(() => {
        // console.log(input);
        // console.log(input.value);
        if (!input.value) {
          reject([]);
        }
        input.remove();
        document.body.onfocus = null;
      }, 1000);
    };

    input.click();
  });
};

// 处理input file 静态数据
export const getInputFileReader = (files) => {
  return new Promise((resolve, reject) => {
    try {
      const urls = [];
      for (let i = 0; i < files.length; i++) {
        const readFile = files[i];
        // 静态
        const fileReader = new FileReader();
        fileReader.readAsDataURL(readFile);

        fileReader.onload = (res) => {
          const relativePath = res.target.result;
          const { name } = readFile;
          urls.push({ relativePath, fileName: name, file: readFile });

          // 结束
          if (i === files.length - 1) {
            setTimeout(() => {
              resolve(urls);
            });
          }
        };
      }
    } catch (error) {
      console.log(error);
      resolve([]);
    }
  });
};

// ****************************************************************
// 文件上传 模块 END
// ****************************************************************

// ****************************************************************
// 路由操作 模块 START
// ****************************************************************

// 跳转路由
export const navigateTo = function (path, query = {}) {
  this.$router.push({ path, query });
};

// 回退
export const navigateBack = function (delta = -1) {
  // 页面路由
  this.$router.go(delta);
};

// 延迟 操作
export const delayHandle = (callback, delay = 500) => {
  setTimeout(() => {
    callback ? callback() : "";
  }, delay);
};

// 延迟 回退
export const delayBack = function (callback, delay) {
  delayHandle(() => {
    navigateBack.apply(this);
    callback ? callback() : "";
  }, delay);
};

// 延迟跳转
export const delayNavigateTo = function (path, query = {}, delay = 500) {
  delayHandle(() => {
    navigateTo(path, (query = {}));
  }, delay);
};

// ****************************************************************
// 路由操作 模块 END
// ****************************************************************

// ****************************************************************
// 其他 模块 START
// ****************************************************************

// 复制
export const copy = function (result) {
  var textarea = document.createElement("textarea"); // create input标签

  document.body.appendChild(textarea); // 添加到body中

  textarea.value = result; // 给input设置value属性为需要copy的内容

  textarea.select(); // 选中

  document.execCommand("copy", false); // copy已经选中的内容

  textarea.remove(); // 删除掉这个dom
};

/* 处理计算机文件大小操作 */

// 文件大小单位选项信息
const computeFileSizeUnits = {
  B: {
    result(size) {
      return `${size}`;
    },
  },
  KB: {
    result(size, numUnit = 1) {
      return `${(size / numUnit).toFixed(2)}`;
    },
  },
  MB: {
    result(size, numUnit = 1) {
      return `${(size / Math.pow(numUnit, 2)).toFixed(2)}`;
    },
  },
  GB: {
    result(size, numUnit = 1) {
      return `${(size / Math.pow(numUnit, 3)).toFixed(2)}`;
    },
  },
  TB: {
    result(size, numUnit = 1) {
      return `${(size / Math.pow(numUnit, 4)).toFixed(2)}`;
    },
  },
};

//获取 计算机文件实际大小
export const getComputeFileSize = (size = 0) => {
  // 计算文件大小函数(保留两位小数), Size为字节大小

  size = Number(size);

  if (!size) return "0B";

  var num = 1024.0; //byte
  if (size < num) {
    const unit = "B";
    return computeFileSizeUnits[unit].result(size) + unit;
  } else if (size < Math.pow(num, 2)) {
    const unit = "KB";
    return computeFileSizeUnits[unit].result(size, num) + unit;
  } //kb
  else if (size < Math.pow(num, 3)) {
    const unit = "MB";
    return computeFileSizeUnits[unit].result(size, num) + unit;
  } else if (size < Math.pow(num, 4)) {
    const unit = "GB";
    return computeFileSizeUnits[unit].result(size, num) + unit;
  } else if (size < Math.pow(num, 4)) {
    const unit = "GB";
    return computeFileSizeUnits[unit].result(size, num) + unit;
  } else {
    const unit = "TB";
    return computeFileSizeUnits[unit].result(size, num) + unit;
  }
};

// 获取计算机文件指定单位大小
export const getComputeFileAppointSize = (size = 0, conf) => {
  const { unit = "MB" } = conf || {};

  if (!size) {
    if (!size) return "0B";
  }

  const numUnit = 1024.0;

  return computeFileSizeUnits[unit].result(size, numUnit) + unit;
};

// 根据byte大小获取5个文件容量类型的数值
export const getByteSizeReturnFileCapacitySize = (byteSize = 0) => {
  if (!byteSize) {
    return [];
  }

  const getRealValue = (mul_value, value) => {
    return mul_value - value;
  };

  // 获取TB
  const TB_UNIT_SIZE = Math.pow(1024, 4);
  const TB_MUL = byteSize / TB_UNIT_SIZE;
  const TB_VALUE = Math.floor(TB_MUL);
  const TB_VALUE_DIFF = getRealValue(TB_MUL, TB_VALUE);
  const GM_SIZE = TB_VALUE_DIFF * TB_UNIT_SIZE;

  // 获取GB
  const GB_UNIT_SIZE = Math.pow(1024, 3);
  const GB_MUL = GM_SIZE / GB_UNIT_SIZE;
  const GB_VALUE = Math.floor(GB_MUL);
  const GB_VALUE_DIFF = getRealValue(GB_MUL, GB_VALUE);
  const MB_SIZE = GB_VALUE_DIFF * GB_UNIT_SIZE;

  // 获取MB
  const MB_UNIT_SIZE = Math.pow(1024, 2);
  const MB_MUL = MB_SIZE / MB_UNIT_SIZE;
  const MB_VALUE = Math.floor(MB_MUL);
  const MB_VALUE_DIFF = getRealValue(MB_MUL, MB_VALUE);
  const KB_SIZE = MB_VALUE_DIFF * MB_UNIT_SIZE;

  // 获取KB
  const KB_UNIT_SIZE = Math.pow(1024, 1);
  const KB_MUL = KB_SIZE / KB_UNIT_SIZE;
  const KB_VALUE = Math.floor(KB_MUL);
  const KB_VALUE_DIFF = getRealValue(KB_MUL, KB_VALUE);
  const B_SIZE = KB_VALUE_DIFF * KB_UNIT_SIZE;

  // 获取B
  const B_VALUE = B_SIZE;

  return [
    {
      unit: "TB",
      value: TB_VALUE,
    },
    {
      unit: "GB",
      value: GB_VALUE,
    },
    {
      unit: "MB",
      value: MB_VALUE,
    },
    {
      unit: "KB",
      value: KB_VALUE,
    },
    {
      unit: "B",
      value: B_VALUE,
    },
  ];
};

/**
 * 赋值参数
 * @params {object} origin 原数据（被动更新）弹夹
 * @params {object} data 常规数据（填充原数据）子弹
 * @params {object} config
 * @param {object} config.ignoreNull 不赋值 data 中的 空数据
 * @param {object} config.ignoreOriginNull 不赋值 origin 中的 空数据
 * @param {object} config.updateOriginDataType 更新的原数据 类型 origin|data
 */
export const updateParams = (origin = {}, data = {}, config) => {
  const {
    ignoreNull = false,
    ignoreOriginNull = false,
    updateOriginDataType = "origin",
  } = config || {};
  const jsonData = JSON.parse(JSON.stringify(data));

  // 遍历的数据源
  const useForData = updateOriginDataType == "origin" ? origin : data;

  for (let i in useForData) {
    // 待赋值数据
    const result = jsonData[i];
    // 原数据的 参数数据
    const originResult = useForData[i];

    if (ignoreNull || ignoreOriginNull) {
      // 忽略 data 中的 空值
      if (ignoreNull) {
        // origin[i] = isEmpty(result) ? originResult : result;
        if (isEmpty(result)) {
          origin[i] = originResult;
        } else {
          if (result instanceof Object) {
            if (Array.isArray(result)) {
              origin[i] = result.length ? result : originResult;
            } else {
              origin[i] = Object.keys(result).length ? result : originResult;
            }
          } else {
            origin[i] = result;
          }
        }
      }

      // 忽略 origin 中的 空值
      if (ignoreOriginNull) {
        // origin[i] = isEmpty(origin[i]) ? origin[i] : result;

        if (isEmpty(origin[i])) {
          origin[i] = origin[i];
        } else {
          if (origin[i] instanceof Object) {
            if (Array.isArray(origin[i])) {
              origin[i] = origin[i].length ? result : origin[i];
            } else {
              origin[i] = Object.keys(origin[i]).length ? result : origin[i];
            }
          } else {
            origin[i] = result;
          }
        }
      }
    } else {
      // origin[i] = isEmpty(result) ? "" : result;
      if (isEmpty(result)) {
        origin[i] = "";
      } else {
        if (result instanceof Object) {
          if (Array.isArray(result)) {
            origin[i] = result.length ? result : [];
          } else {
            origin[i] = Object.keys(result).length ? result : {};
          }
        } else {
          origin[i] = result;
        }
      }
    }
  }
  // console.log(origin);
};

// 节流
export const bypass = function (fun, delay = 500) {
  // 延迟时间
  let toTimeId = null;
  // 是否拦截
  let isStop = false;
  return function () {
    if (isStop) {
      return;
    }
    isStop = true;

    toTimeId = setTimeout(() => {
      // 关闭
      clearTimeout(toTimeId);
      toTimeId = null;
      isStop = false;
    }, delay);
    return fun.apply(fun, arguments);
  };
};

// 字符串加密解密
export const strCodeToCode = () => {
  var that = {};

  function encrypt(str, pwd) {
    if (str == "") {
      return "";
    }
    str = encodeURIComponent(str);
    if (!pwd || pwd == "") {
      pwd = "kb1234";
    }
    pwd = encodeURIComponent(pwd);
    if (pwd == "" || pwd.length <= 0) {
      return "";
    }
    var prand = "";
    for (var i = 0, len = pwd.length; i < len; i += 1) {
      prand += pwd.charCodeAt(i).toString();
    }
    var sPos = Math.floor(prand.length / 5);
    var mult = parseInt(
      prand.charAt(sPos) +
        prand.charAt(sPos * 2) +
        prand.charAt(sPos * 3) +
        prand.charAt(sPos * 4) +
        prand.charAt(sPos * 5)
    );
    var incr = Math.ceil(pwd.length / 2);
    var modu = Math.pow(2, 31) - 1;
    if (mult < 2) {
      return "";
    }
    var salt = Math.round(Math.random() * 1000000000) % 100000000;
    prand += salt;
    while (prand.length > 10) {
      prand = (
        parseInt(prand.substring(0, 10)) +
        parseInt(prand.substring(10, prand.length))
      ).toString();
    }
    prand = (mult * prand + incr) % modu;
    var encChr = "";
    var encStr = "";
    for (var i = 0, len = str.length; i < len; i += 1) {
      encChr = parseInt(str.charCodeAt(i) ^ Math.floor((prand / modu) * 255));
      if (encChr < 16) {
        encStr += "0" + encChr.toString(16);
      } else {
        encStr += encChr.toString(16);
      }
      prand = (mult * prand + incr) % modu;
    }
    salt = salt.toString(16);
    while (salt.length < 8) {
      salt = "0" + salt;
    }
    encStr += salt;
    return encStr;
  }

  function decrypt(str, pwd) {
    if (str == "") {
      return "";
    }
    if (!pwd || pwd == "") {
      pwd = "kb1234";
    }
    pwd = encodeURIComponent(pwd);
    if (str == undefined || str.length < 8) {
      return "";
    }
    if (pwd == undefined || pwd.length <= 0) {
      return "";
    }
    var prand = "";
    for (var i = 0, len = pwd.length; i < len; i += 1) {
      prand += pwd.charCodeAt(i).toString();
    }
    var sPos = Math.floor(prand.length / 5);
    var mult = parseInt(
      prand.charAt(sPos) +
        prand.charAt(sPos * 2) +
        prand.charAt(sPos * 3) +
        prand.charAt(sPos * 4) +
        prand.charAt(sPos * 5)
    );
    var incr = Math.round(pwd.length / 2);
    var modu = Math.pow(2, 31) - 1;
    var salt = parseInt(str.substring(str.length - 8, str.length), 16);
    str = str.substring(0, str.length - 8);
    prand += salt;
    while (prand.length > 10) {
      prand = (
        parseInt(prand.substring(0, 10)) +
        parseInt(prand.substring(10, prand.length))
      ).toString();
    }
    prand = (mult * prand + incr) % modu;
    var encChr = "";
    var encStr = "";
    for (var i = 0, len = str.length; i < len; i += 2) {
      encChr = parseInt(
        parseInt(str.substring(i, i + 2), 16) ^ Math.floor((prand / modu) * 255)
      );
      encStr += String.fromCharCode(encChr);
      prand = (mult * prand + incr) % modu;
    }
    return decodeURIComponent(encStr);
  }

  that.encrypt = encrypt;
  that.decrypt = decrypt;
  return that;
};

/* 获取动态数据字典列表 */
export const getActiveTypeNameLists = async function (type) {
  try {
    const dictTypeCode = type;
    // console.log(type);
    // console.log(allDataDictionary);
    const res = await this.$storeDispatch("fetchGetDictList", {
      dictTypeCode,
    });
    const result = res.result;
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
};

// 下载文件
export const downloadFile = function ({
  href = "",
  downloadName = "",
  useUrlBefore = true,
  downloadType = "normal",
}) {
  var a = document.createElement("a");

  if (useUrlBefore) {
    const brforeUrls = {
      normal: downloadFileUrl,
      report: operateReportFileUrl,
    };

    a.href = `${brforeUrls[downloadType] || ""}${href}?${STORE_NAME_AUTH_TOKEN}=${getItem(STORE_NAME_AUTH_TOKEN)}`;
  } else {
    a.href = href;
  }
  a.download = downloadName;
  a.click();
  a.remove();
};

// 下载文件（文件流）
export const downloadFileBlob = function (fileBlobData, name) {
  // 创建一个 Blob 对象
  const blob = new Blob([fileBlobData]);

  // 创建一个下载链接
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = name;

  // 模拟点击下载链接触发下载
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

/* 查找当前上传文件的字典参数 */
export const getFileScopeItem = async function (props) {
  const { scopeName, scopeTypeName } = props;
  // 获取 数据字典参数
  const dataParmas = await getActiveTypeNameLists.apply(this, [
    `${scopeName}_TYPE`,
  ]);
  // 筛选 字典
  const currScope =
    dataParmas.find((d) => `${scopeName}_${scopeTypeName}` === d.code) || {};

  return currScope;
};

// 生成随机key
export const createRandomKey = () => {
  const stamp = new Date().getTime();
  return stamp.toString();
};

/* 获取子级的子级列表 */
export const getChildChildrenList = (list, result = []) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const children = item.children || [];
    result.push(...children);

    getChildChildrenList(children, result);
  }

  return result;
};

/**
 * 根据图片url转为文件对象
 * @param url
 * @param fileName 文件名称
 * @param fileType 文件类型
 * @returns {Promise<unknown>}
 */
export const getFileFromUrl = (url, fileName, fileType) => {
  return new Promise((resolve, reject) => {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("Accept", fileType);
    xhr.responseType = "blob";
    // 加载时处理
    xhr.onload = () => {
      // 获取返回结果
      blob = xhr.response;
      let fileItem = new File([blob], fileName, { type: fileType });
      // 返回结果
      resolve(fileItem);
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    // 发送
    xhr.send();
  });
};

// 综合校验 身份证
export const isIdCardNo = (num) => {
  // 字符串大写
  num = num.toUpperCase();
  //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
  if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
    // alert(
    //   "输入的身份证号长度不对，或者号码不符合规定！\n15位号码应全为数字，18位号码末位可以为数字或X。"
    // );
    return false;
  }

  //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
  //下面分别分析出生日期和校验位
  var len, re;
  len = num.length;
  if (len == 15) {
    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
    var arrSplit = num.match(re);
    //检查生日日期是否正确
    var dtmBirth = new Date(
      "19" + arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
    );
    var bGoodDay;
    bGoodDay =
      dtmBirth.getYear() == Number(arrSplit[2]) &&
      dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
      dtmBirth.getDate() == Number(arrSplit[4]);
    if (!bGoodDay) {
      // alert("输入的身份证号里出生日期不对！");
      return false;
    } else {
      //将15位身份证转成18位
      //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      var arrInt = new Array(
        7,
        9,
        10,
        5,
        8,
        4,
        2,
        1,
        6,
        3,
        7,
        9,
        10,
        5,
        8,
        4,
        2
      );
      var arrCh = new Array(
        "1",
        "0",
        "X",
        "9",
        "8",
        "7",
        "6",
        "5",
        "4",
        "3",
        "2"
      );
      var nTemp = 0,
        i;
      num = num.substr(0, 6) + "19" + num.substr(6, num.length - 6);
      for (i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i];
      }
      num += arrCh[nTemp % 11];
      return true;
    }
  }

  if (len == 18) {
    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
    var arrSplit = num.match(re);
    //检查生日日期是否正确
    var dtmBirth = new Date(
      arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
    );
    var bGoodDay;
    bGoodDay =
      dtmBirth.getFullYear() == Number(arrSplit[2]) &&
      dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
      dtmBirth.getDate() == Number(arrSplit[4]);
    if (!bGoodDay) {
      // alert(dtmBirth.getYear());
      // alert(arrSplit[2]);
      // alert("输入的身份证号里出生日期不对！");
      return false;
    } else {
      //检验18位身份证的校验码是否正确。
      //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      var valnum;
      var arrInt = new Array(
        7,
        9,
        10,
        5,
        8,
        4,
        2,
        1,
        6,
        3,
        7,
        9,
        10,
        5,
        8,
        4,
        2
      );
      var arrCh = new Array(
        "1",
        "0",
        "X",
        "9",
        "8",
        "7",
        "6",
        "5",
        "4",
        "3",
        "2"
      );
      var nTemp = 0,
        i;
      for (i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i];
      }
      valnum = arrCh[nTemp % 11];
      if (valnum != num.substr(17, 1)) {
        // alert("18位身份证的校验码不正确！应该为：" + valnum);
        return false;
      }
      return true;
    }
  }
  return false;
};

// ****************************************************************
// 其他 模块 END
// ****************************************************************

// 手机号隐藏
export const telHide = (data) => {
  if (data) {
    const tel = data.toString();
    let firstNuber = tel.slice(0, 3);
    let lastNumber = tel.slice(tel.length - 4, tel.length);
    return firstNuber + "****" + lastNumber;
  }
};
