<!-- 下载确认窗口 -->
<template >
  <tool-dialog
    size="small"
    ref="downloadDialogRef"
    @confirm="confirmBtn"
    @cancel="cancelBtn"
    title="Maven拉取"
    confirmText="下载"
  >
    <div class="downLoad-dialog-box">
      <!-- 常规提示 -->
      <div class="tips-round-box normal-bg">
        <div class="icon">
          <el-image :src="cDownIcon"></el-image>
        </div>
        <div class="tips-text">
          <p>下载Settings.xml配置文件,并将其放置在以下文件目录：</p>
          <p>IDEA默认Maven路径：${HOME}\.m2\</p>
        </div>
      </div>

      <!-- 文件 -->
      <div class="file-box">
        <div class="file-icon">
          <el-image :src="XMLIcon"></el-image>
        </div>
        <div class="file-name">
          Settings.xml（配置文件：无需更改，即可使用）
        </div>
      </div>
    </div>
  </tool-dialog>
</template>


<script setup>
import { ref } from "vue";
import toolDialog from "./tool-dialog.vue";
const emits = defineEmits(["confirm", "cancel"]);

// 拉去下载
const cDownIcon = require("@/assets/images/download.png");
// XML
const XMLIcon = require("@/assets/images/XML.png");

// 删除
const downloadDialogRef = ref();

// 打开
const openCase = () => {
  downloadDialogRef.value.showDialog = true;
};

// 关闭
const closeCase = () => {
  downloadDialogRef.value.showDialog = false;
};

// 窗口按钮操作
// 确认
const confirmBtn = (call) => {
  emits("confirm", call);
};
// 取消
const cancelBtn = () => {
  emits("cancel");
};

defineExpose({
  openCase,
  closeCase,
  confirmBtn,
  cancelBtn,
});
</script>

<style lang="scss" scoped>
// 提示窗口
.downLoad-dialog-box {
  padding: 0 10px;

  .tips-round-box {
    padding: 15px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      margin-top: 3px;
    }

    .tips-text {
      line-height: 25px;
      color: #333333;
      font-size: 14px;
    }
  }

  // 拉去文件
  .file-box {
    padding: 20px 10px;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    .file-icon {
      width: 45px;
      height: 50px;
      margin-right: 20px;

      .el-image {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>