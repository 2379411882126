<!-- 危险提示 确认窗口 -->
<template>
  <tool-dialog
    size="small"
    width="500px"
    btnConfirmType="danger"
    ref="dangerDialogRef"
    :disabledConfirm="isDisConfirm"
    @cancel="cancelBtn"
    @confirm="confirmBtn"
    @dialogClosed="dialogClose"
    :title="title"
    class="danger-confirm-dialog"
  >
    <div class="danger-dialog-box">
      <!-- 危险提示 -->
      <div class="tips-round-box">
        <div class="icon">
          <el-image :src="dangerIcon"></el-image>
        </div>
        <div class="tips-text">
          <slot> </slot>
          <p
            v-for="(item, index) in tips"
            :key="'tip' + index"
            v-html="item"
          ></p>
        </div>
      </div>

      <p class="view-label">请输入以下内容确认：</p>
      <el-form label-position="top" class="confirm-form">
        <el-form-item>
          <template #label>
            <tag-tips bold @click="copyName">
              {{ confirmCommand }}
            </tag-tips>
          </template>
          <el-input
            placeholder="请输入验证内容"
            v-model="confirmCommandValue"
          />
        </el-form-item>
      </el-form>
    </div>
  </tool-dialog>
</template>

<script setup>
import { ref, watch, computed, getCurrentInstance } from "vue";
const { proxy } = getCurrentInstance();
const emits = defineEmits(["confirm", "cancel", "dialogClose"]);

const props = defineProps({
  tips: {
    type: Array,
    default: ["", ""],
  },
  title: {
    type: String,
    default: "",
  },
  // 确认命令
  command: {
    type: String,
    default: "",
  },
});

// 危险提示
const dangerIcon = require("@/assets/images/danger-icon.png");

// 确认命令
const confirmCommand = ref(props.command);
watch(
  () => props.command,
  (nVal) => {
    confirmCommand.value = nVal;
  }
);
// 确认命令输入数据
const confirmCommandValue = ref("");

// 是否禁用使用确认
const isDisConfirm = computed(() => {
  const value = confirmCommandValue.value;
  if (!value || value !== confirmCommand.value) {
    return true;
  } else {
    return false;
  }
});

// 赋值命令
const copyName = () => {
  // proxy.$copy(confirmCommand.value);
  // proxy.$message({
  //   type: "success",
  //   message: "复制成功",
  // });

  confirmCommandValue.value = confirmCommand.value;
};

// 删除
const dangerDialogRef = ref();

// 打开
const openDialog = () => {
  dangerDialogRef.value.showDialog = true;
  confirmCommandValue.value = "";
};

// 关闭
const closeDialog = () => {
  confirmCommandValue.value = "";
  dangerDialogRef.value.showDialog = false;
};

// 窗口按钮操作
// 确认
const confirmBtn = (call) => {
  emits("confirm", call);
};
// 取消
const cancelBtn = () => {
  emits("cancel");
};

// 监听窗口关闭
const dialogClose = () => {
  emits("dialogClose");
};

defineExpose({
  openDialog,
  closeDialog,
  confirmBtn,
  cancelBtn,
});
</script>

<style lang="scss" scoped>
// 提示窗口
.danger-dialog-box {
  padding: 0 10px;
  padding-bottom: 10px;

  .tips-round-box {
    padding: 15px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    background-color: #fab6b63d;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      margin-top: 3px;
    }

    .tips-text {
      line-height: 25px;
      color: #333333;
      font-size: 14px;
    }
  }

  // 删除
  .view-label {
    line-height: 20px;
    margin: 10px 0 3px 0;
  }

  // 拉去文件
  .file-box {
    padding: 20px 10px;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    .file-icon {
      width: 45px;
      height: 50px;
      margin-right: 20px;

      .el-image {
        height: 100%;
        width: 100%;
      }
    }
  }

  // 删除确认表单
  .confirm-form {
    .el-form-item {
      margin: 0;

      .el-input {
        width: 100%;
      }
    }
  }
}
</style>
