
<template>
  <div v-if="isDragging" :ref="drag" />
  <div
    :ref="drag"
    class="box"
    v-else
    :style="{
      left: left + 'px',
      top: top + 'px',
      height: height + 'px',
      width: width + 'px',
      fontSize: fontSize + 'px',
    }"
  >
    {{ title }}
    <slot></slot>
  </div>
</template>

<script setup>
import { toRefs } from "vue";
import { useDrag } from "vue3-dnd";
import { dragItemType } from "@/utils/enum";

const props = defineProps({
  id: String, //唯一标识
  left: Number, //左边距
  top: Number, //上边距
  height: Number, //元素高度
  width: Number, //元素宽度
  fontSize: Number, //字体大小
  title: String, //文本
});

const [collect, drag] = useDrag(() => ({
  type: "card",
  item: { id: props.id, left: props.left, top: props.top },
  collect: (monitor) => ({
    isDragging: monitor.isDragging,
  }),
}));
const { isDragging } = toRefs(collect);
</script>

<style scoped>
.box {
  position: absolute;
  background-color: white;
  border: 1px dashed gray;
  cursor: move;
  padding: 0;
}
</style>