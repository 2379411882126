<template>
  <div class="introduce-info" @click="handleClick">
    <head-sculpture
      :showOnlineStatus="showOnlineStatus"
      :isUsePreviewUrl="isUsePreviewUrl"
      :preview="false"
      class="show-avator"
      :src="imageUrl"
      :raiuds="raiuds"
      :mode="mode"
      width="38px"
      height="38px"
      :errorEmptyType="errorEmptyType"
      :customErrIcon="customErrIcon"
    ></head-sculpture>

    <div class="line-box">
      <p class="introduce-first oneLineOver">{{ firstLineText }}</p>
      <p class="introduce-second oneLineOver" v-if="!!secondLineText">
        {{ secondLineText }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";

const emits = defineEmits(["handleClick"]);

const props = defineProps({
  avator: {
    type: String,
    default: "",
  },
  firstText: {
    type: String,
    default: "",
  },
  secondText: {
    type: String,
    default: "",
  },
  isUsePreviewUrl: {
    type: Boolean,
    default: false,
  },
  // 弧度
  raiuds: {
    type: [Number, String],
    default: "",
  },
  // 显示模式 normal||circle
  mode: {
    type: String,
    default: "circle",
  },
  errorEmptyType: {
    type: String,
    default: "image",
  },
  customErrIcon: {
    type: String,
    default: "",
  },
});

const imageUrl = computed(() => props.avator);
const firstLineText = computed(() => props.firstText);
const secondLineText = computed(() => props.secondText);
const raiuds = computed(() => props.raiuds);
const mode = computed(() => props.mode);
const showOnlineStatus = computed(() => props.showOnlineStatus);

// 点击
const handleClick = () => {
  emits("handleClick");
};
</script>

<style lang="scss" scoped>
.introduce-info {
  display: grid !important;
  grid-template-columns: auto 1fr;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  .show-avator {
    width: 36px;
    height: 36px;
    border-radius: 50px;
    margin-right: 10px;
  }

  // &:hover {
  //   .introduce-first {
  //     font-weight: 700;
  //   }
  // }

  .line-box {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .introduce-first {
      transition: font 300ms;
      // font-weight: 700;
    }
    .introduce-second {
      font-size: 12px;
      margin-top: 3px;
    }
    > p {
      line-height: 16px;
    }
  }
}
</style>
