// 服务器配置

const config = {
  // 线上
  online() {
    // 请求IP 前置
    // let serveIP = "http://smart-battery.cloudeffective.cn";
    // 【国外（pcb2）】
    let serveIP = "https://pcb2.grepow.vip";
    // 【国内（pcb1）】
    // let serveIP = "https://pcb1.grepow.vip";
    // 本地API端口
    // let port = ":15651";
    let port = "";
    // 接口名 前置
    let prefix = "/system/v1";
    let prefixName = `${port}${prefix}`;

    /* 文件操作 */
    // 本地文件预览
    // let fileOssPrefix = `${serveIP}${prefixName}/aliyun/oss/`;
    let fileOssPrefix = `${serveIP}${prefixName}/aliyun/oss/`;

    // 本地文件上传
    let updateFileUrl = `${fileOssPrefix}upload`;
    // 预览文件
    // const previewFileUrl = `http://oss.grepow.vip/`;
    // 【国外（pcb2）】
    const previewFileUrl = `https://smart-battery-pcb2.oss-us-west-1.aliyuncs.com/`;
    // 【国内（pcb1）】
    // const previewFileUrl = `https://smart-battery-pcb1.oss-cn-shenzhen.aliyuncs.com/`;
    // 下载文件
    const downloadFileUrl = `${fileOssPrefix}download/`;

    return {
      serveIP,
      prefix,
      //接口
      serveUrl: serveIP + `${port}/`,
      // 上传文件
      updateFileUrl,
      // 预览文件
      previewFileUrl,
      // 下载文件
      downloadFileUrl,
    };
  },
  // 测试
  test() {
    // 请求IP 前置
    let serveIP = "http://39.108.9.67";
    // 本地API端口
    // let port = ":8080";
    let port = ":8001";
    // 接口名 前置
    let prefix = "/system/v1";
    let prefixName = `${port}/${prefix}`;

    /* 文件操作 */
    // 本地文件预览
    let fileOssIP = "http://192.168.1.148";
    let fileOssPort = ":5000";
    let fileOssPrefix = `${fileOssIP}${fileOssPort}/local/oss/v1/file/`;

    // 本地文件上传
    let updateFileUrl = `${fileOssPrefix}upload`;
    // 预览文件
    const previewFileUrl = `${fileOssPrefix}preview/`;

    return {
      serveIP,
      prefix,
      //接口
      serveUrl: serveIP + `${port}/`,
      // 上传文件
      updateFileUrl,
      // 预览文件
      previewFileUrl,
    };
  },
  // 本地
  local() {
    // 请求IP 前置
    let serveIP = "http://192.168.1.101";
    // 本地API端口
    let port = ":8001";
    // 接口名 前置
    let prefix = "/system/v1";
    let prefixName = `${port}${prefix}`;

    /* 文件操作 */
    // 本地文件预览
    let fileOssPrefix = `${serveIP}${prefixName}/aliyun/oss/`;

    // 本地文件上传
    let updateFileUrl = `${fileOssPrefix}upload`;
    // 预览文件
    const previewFileUrl = `http://oss.grepow.vip/`;
    // 下载文件
    const downloadFileUrl = `${fileOssPrefix}download/`;

    return {
      serveIP,
      prefix,
      //接口
      serveUrl: serveIP + `${port}/`,
      // 上传文件
      updateFileUrl,
      // 预览文件
      previewFileUrl,
      // 下载文件
      downloadFileUrl,
    };
  },
};

// 使用配置
const useConfig = config["online"]();

// const { operateFileUrl, operateReportFileUrl, updateFileUrl } =
//   config["online"]();
// useConfig.updateFileUrl = updateFileUrl;

// useConfig.operateFileUrl = operateFileUrl;
// useConfig.operateReportFileUrl = operateReportFileUrl;

module.exports = {
  ...useConfig,
};
