<template>
  <div
    class="detail-navbar-box"
    :class="{ 'show-border-bottom': showBorderBottom }"
    :style="{ 'background-color': backgroundColor }"
  >
    <div class="left">
      <slot name="panel-title">{{ title }}</slot>
    </div>
    <div class="right">
      <ml-button type="default" @handleClick="refreshView">刷新</ml-button>
      <ml-button type="default" @handleClick="goBack">返回</ml-button>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, nextTick, computed } from "vue";

const { proxy } = getCurrentInstance();

const emits = defineEmits(["refresh"]);

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  backgroundColor: {
    type: String,
    default: "white",
  },
  showBorderBottom: {
    type: Boolean,
    default: true,
  },
});

// 返回
const goBack = () => {
  proxy.$router.go(-1);
};

// 监听菜单点击操作
const refreshView = () => {
  emits("refresh");
};
</script>

<style lang="scss" scoped>
.detail-navbar-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  z-index: 5;
  border-radius: 6px 6px 0 0;
  flex-wrap: wrap;

  &.show-border-bottom {
    border-bottom: 1px solid #eee;
  }

  .left {
    font-size: 22px;
    font-weight: bold;
  }
}
</style>
