<template>
  <router-view />
</template>

<script setup></script>

<style lang="scss">
@import "@/assets/css/root.scss";
@import "@/assets/css/global.scss";
@import "@/assets/css/element.scss";
@import "@/assets/iconfont/iconfont.scss";
// @import "@/assets/ui-font/index.scss";

* {
  padding: 0;
  margin: 0;
}

// 自定义弹窗内容
.tool-dialog-container {
  &.fixed-area {
    .el-overlay-dialog {
      display: grid;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .el-dialog {
        margin: 0;
        width: fit-content;
      }
    }
  }
}

.manage-box {
  width: 100%;
}

// 下拉dropdown
.el-dropdown-link {
  cursor: pointer;
  color: #9e9e9e;
}
</style>
