// 项目主要操作集合
import { addRouters } from "@/router/index";
import router from "@/router/index";

import {
  usePiniaModule,
  getStoreAgeToken,
  removeDecontextAttr,
} from "@/utils/module";

import store from "@/store/index";
import piniaStore from "@/piniaStore/index";

import { reomveRoutes } from "@/router/index";
import { exportMenusData } from "@/router/pages";
import {
  ACTIONSCOPE_PAGE,
  ACTIONSCOPE_BUTTON,
  SHORT_MESSAGE_TYPE,
} from "./dataDictionary";

// ****************************************************************
// 用户对象的操作处理 START
// ****************************************************************

/* 获取当前登录用户信息 */
export const getCurrLoginInfo = async function () {
  const mainStore = usePiniaModule("mainStore");
  try {
    const res = await this.$storeDispatch("fetchGetCurrentSystemUserInfo");
    const result = res.result;
    mainStore.userInfo = result;
    return result;
  } catch (error) {
    return {};
  }
};

/* 登录退出操作 */
export const userLoginExit = function () {
  const { mainStore } = piniaStore;
  const main = mainStore();
  // 清除当前登录路由
  reomveRoutes();

  // 清除操作
  main.clearLocalData();

  // 退出到登录
  router.replace("/login");
  // window.location.href = "/login";
};

/* 手机号校验 */
export const verifySmsVerificationCode = async function (params) {
  try {
    // params.codeType = SHORT_MESSAGE_TYPE;
    const res = await this.$storeDispatch(
      "fetchVerifySmsVerificationCode",
      params
    );
    const result = res.result;
    return result;
  } catch (error) {
    return false;
  }
};

// ****************************************************************
// 用户对象的操作处理 END
// ****************************************************************

// ****************************************************************
// 路由菜单处理 START
// ****************************************************************

/**
 * 生成 导入 菜单数据
 * @param {*} routes 路由数据
 * @param {*} index 当前索引
 * @param {*} result 保存的结果
 * @param {*} total 总数
 * @param {*} level 级别
 * @param {*} opt 生成器的配置
 * @param {*} opt.dataType 生成数据的类型
 * @returns 不同类型的菜单数据
 */
export const createMenutDatas = (
  routes,
  index = 0,
  result = [],
  total = 0,
  level = 0,
  opt = {}
) => {
  const { dataType = "show-menu" } = opt;

  // 递增
  total += 1;
  const item = routes[index];
  total += index + 1;
  const id = `${index}_${total}`;

  // 条件
  // 预览菜单数据
  const isShowMenu = dataType == "show-menu";
  // 使用权限菜单数据
  const isUseAuthMenu = dataType == "use-auth-menu";

  // 拦截 遍历完毕
  if (!item) {
    return result;
  } else {
    // 展示菜单数据
    if (isShowMenu) {
      if (item.meta) {
        // 拦截
        if (item.isAuth === false) {
          return result;
        }

        // 拦截 页面类型以下的数据
        if (level > 2) {
          return result;
        }
      }
    } else if (isUseAuthMenu) {
      // 使用 权限菜单数据
      // // 不加入到 权限 弃用
      // if (item.isAuth === false) {
      //   return result;
      // }
    }
  }

  // 当前参数item
  const {
    title,
    functionList = [],
    children = [],
    meta = {},
    path = "",
    icon = "",
    menuCode,
    isAuth,
    display = true,
    opend = true,
  } = item;

  // 转换使用参数
  const params = {
    status: 1,
    spread: total ? 1 : 0,
    code: menuCode || "menu_" + id,
    opend,
    id,
    display,
    href: path,
    icon,
    level,
    isMaterTheme: !level,
    isAuth,
  };

  // 处理子级
  params.children = createMenutDatas(children, 0, [], total, level + 1, opt);

  // 赋值
  params.name = meta.title || title;

  // 作用域（0:分组、1:页面、2:按钮）
  params.actionScope = level;

  // 功能列表
  if (functionList.length && isUseAuthMenu) {
    // 功能列表
    params.functionList = createMenutDatas(
      functionList,
      0,
      [],
      total,
      level + 1,
      opt
    );
    // 使用 权限数据
    if (isUseAuthMenu) {
      params.children = createMenutDatas(
        functionList,
        0,
        [],
        total,
        level + 1,
        opt
      );
    }
  }

  // 存储
  result.push(params);
  return createMenutDatas(routes, index + 1, result, total, level, opt);
};

/**
 * 生成 不加入到权限里的 菜单数据
 * @param {*} routes 路由数据
 * @param {*} index 当前索引
 * @param {*} result 保存的结果
 */
export const notJoinAuthMenus = (routes, index = 0, result = []) => {
  const item = routes[index];

  // 拦截 遍历完毕
  if (!item) {
    return result;
  }

  const children = item.children || [];

  // 不加入到 权限
  if (item.isAuth === false) {
    result.push(item);
  }
  notJoinAuthMenus(children, 0, result);

  return notJoinAuthMenus(routes, index + 1, result);
};

// 获取菜单数据中全部节点的有效href
export const getMenutDataHref = (list) => {
  return list
    .reduce((add, curr) => {
      const children = curr.children || [];
      const href = curr.href;
      add.push(href);

      const result = getMenutDataHref(children);

      return add.concat(result);
    }, [])
    .filter((item) => item);
};

/* 获取当前登录用户的菜单权限 */
export const getCurrUserSystemMenuList = async function () {
  try {
    // 查询当前用户权限
    const res = await store.dispatch("fetchGetCurrentSystemUserRoleMenuList");
    const result = res.result;
    // 更新数据item
    const updateMenusItem = (result) => {
      for (let i = 0; i < result.length; i++) {
        const item = result[i];
        const { children, name } = item;
        item.title = name;
        item.children = updateMenusItem(children);
      }
      return result;
    };
    const menuResult = updateMenusItem(result);
    return menuResult;

    // // 使用 本地权限数据
    // return createMenutDatas(exportMenusData, 0, [], 0, 1, {
    //   dataType: "use-auth-menu",
    // });
  } catch (error) {
    return [];
  }
};

/* 获取当前用户 页面中的按钮权限 */
export const getCurrUserRouteBtnAuths = (menuList, index = 0, result = {}) => {
  const item = menuList[index];

  if (!item) {
    return result;
  }

  const { children = [], href } = item;

  // 存在按钮子级
  const hasBtnChildren = children.find((d) => d.actionScope == 2);

  // console.log(children);
  // console.log(href);
  // console.log(hasBtnChildren);
  if (hasBtnChildren && href) {
    // 按钮列表
    const authBtns = children
      .filter((d) => d.actionScope == 2)
      .map((d) => d.code);
    result[href] = authBtns;
  }
  // 遍历
  getCurrUserRouteBtnAuths(children, 0, result);

  return getCurrUserRouteBtnAuths(menuList, index + 1, result);
};

// 处理菜单数据 转变为 左侧菜单展示列表
const getAuthDataToShowMenu = (list, index = 0, result = []) => {
  const curr = list[index];
  if (!curr) {
    return result;
  }
  // level 从 0开始
  if (curr.level == 1) {
    delete curr["children"];
  }

  const children = curr.children || [];

  getAuthDataToShowMenu(children, 0, []);

  result.push(curr);
  return getAuthDataToShowMenu(list, index + 1, result);
};

// 更新用户路由 菜单
export const updateMenuAuths = function () {
  return new Promise((resolve) => {
    // 处理 路由直接跳入
    setTimeout(async () => {
      const mainStore = usePiniaModule("mainStore");

      // // 查询用户信息
      await getCurrLoginInfo();
      const token = getStoreAgeToken();

      // 无token
      if (!token) {
        mainStore.clearLocalData();
        resolve({
          path: "/login",
          query: {},
          href: "/login",
        });
        return;
      }
      // 查询当前用户权限
      const menus = removeDecontextAttr(await getCurrUserSystemMenuList());
      // console.log(menus);

      // 处理后的全路由地址
      const hrefs = getMenutDataHref(removeDecontextAttr(menus));
      // console.log(hrefs);

      // 赋值
      mainStore.userMenuData = removeDecontextAttr(menus);
      mainStore.leftMenuData = getAuthDataToShowMenu(
        removeDecontextAttr(menus)
      );
      // 赋值 全菜单路由地址
      mainStore.menuHrefs = hrefs;
      // 赋值 路由按钮权限
      mainStore.routeBtnAuths = getCurrUserRouteBtnAuths(menus);
      // console.log(mainStore.routeBtnAuths);

      // 不加入到 权限计算中的路由
      const passRoutes = notJoinAuthMenus(exportMenusData);
      const passRoutePaths = passRoutes.map((d) => d.path);

      // 动态添加路由
      addRouters([...hrefs, ...passRoutePaths]);
      setTimeout(() => {
        // 跳转指定路径
        const { path, query, href } = this.$route;
        this.$router.push({ path, query });

        resolve({
          path,
          query,
          href,
        });
      });
    });
  });
};

// ****************************************************************
// 路由菜单处理 END
// ****************************************************************
