<!-- 标准表格 -->
<template>
  <div
    class="table-container"
    :class="{
      'is-table-empty': !listData.length,
      'is-table-tree': tableModed === 'tree',
    }"
  >
    <!-- 表格表头控制项 -->
    <el-popover
      placement="left-start"
      width="auto"
      trigger="click"
      v-if="false"
    >
      <template #reference>
        <div class="menu-icon table-header-handle">
          <el-icon><Menu /></el-icon>
        </div>
      </template>

      <!-- 表格表头配置 -->
      <div class="tablee-header-config">
        <div class="header-template-box">
          <el-checkbox-group
            v-model="tableHeaderShowTemplate"
            @change="onChangeTemplatecheckbox"
          >
            <el-checkbox
              v-for="(item, index) in useTemplateData"
              :key="'template__' + index"
              :label="index"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </el-popover>

    <div class="warpper-table-content">
      <el-table
        v-if="refreshTable"
        ref="tableRef"
        @select-All="selectHandAll"
        @select="selectHand"
        @selection-change="onSelectionChange"
        @cell-click="cellClick"
        @expand-change="expandChange"
        @current-change="currentChange"
        @row-click="onRowClick"
        tooltip-effect="light"
        v-loading="showLoading"
        :data="listData"
        :height="tableHeight"
        :empty-text="tableConfig.emptyText"
        :default-sort="tableConfig.defaultSort"
        style="width: 100%"
        :row-key="rowKey"
        :tree-props="treeProps"
        :lazy="lazy"
        :load="load"
        :indent="indent"
        :highlight-current-row="highlightCurrentRow"
      >
        <!-- :class="`${item.autoWidth ? getAutoTableColumn() : ''}`" -->
        <el-table-column
          v-for="(item, index) in showTemplateData"
          :key="'template' + index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :min-width="item.minWidth"
          :type="item.type"
          :index="item.index"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :resizable="item.resizable"
          :formatter="item.formatter"
          :show-overflow-tooltip="
            $isEmpty(item.showTooltip) ? true : item.showTooltip
          "
          :tooltip-options="{
            'show-arrow': false,
            trigger: 'click',
          }"
          :align="item.align || 'center'"
        >
          <!-- 头部 -->
          <template
            #header="{ column, $index }"
            v-if="hasSlot(`column-header-${item.prop}`)"
          >
            <div class="ml-table__table-column-header">
              <slot
                :column="column"
                :index="$index"
                :name="`column-header-${item.prop}`"
              ></slot>
            </div>
          </template>

          <!--( 是否显示模板|是否开启表格判空)&&非el特定模板 -->
          <template
            v-if="
              (item.showTemplate || tableConfig.openEmptyColumn) && !item.type
            "
            #default="{ row, $index }"
          >
            <div :class="`table-column-${item.prop}-name`">
              <!-- 自定义模板 -->
              <slot
                v-if="item.showTemplate"
                :name="item.prop"
                :row="row"
              ></slot>

              <!-- 是否统一判空显示 -->
              <p class="oneLineOver" v-else>
                <span v-if="tableConfig.emptyColumn.includes(row[item.prop])">
                  {{
                    !$isEmpty(item.emptyResult)
                      ? item.emptyResult
                      : tableConfig.emptyResult
                  }}
                </span>
                <span v-else>
                  {{ row[item.prop] }}
                </span>
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <ml-page
      v-if="showPage && refreshTable"
      :pageConfig="pageConfig"
      :pageParams="pageParams"
      :small="usePageSmall"
    ></ml-page>

    <!-- 上传进度条 -->
    <div class="drag-upload-progress" v-if="progressState.show">
      <div class="progress-line">
        <div class="discontinue" @click="discontinueHandle">
          <i class="iconfont icon-discontinue"></i>
        </div>

        <div class="line-box">
          <el-progress
            :stroke-width="10"
            :percentage="progressState.progress"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  toRefs,
  watch,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  getCurrentInstance,
} from "vue";

const emits = defineEmits([
  "update:pageParams",
  "selectHand",
  "selectHandAll",
  "cellClick",
  "expandChange",
  "onSelectionChange",
  "currentChange",
]);
import { usePageFunctionAuthModule } from "@/utils/mixin";
import { treeProps } from "element-plus/es/components/tree-v2/src/virtual-tree";

// 获取当前页面的权限组
const { currPageUseAuths } = usePageFunctionAuthModule();

const props = defineProps({
  // 表格模式 normal||tree
  tableModed: {
    type: String,
    default: "normal",
  },
  // 是否显示分页
  showPage: {
    type: Boolean,
    default: true,
  },
  // 列表请求响应值
  tableData: {
    type: Object,
    default: () => ({}),
  },
  filterParams: {
    type: Object,
    default: () => ({}),
  },
  tableHeight: {
    type: String,
    default: "762px",
  },
  // 当前表格中 自定义template 的权限集合
  templateAuths: {
    type: Object,
    default: () => ({}),
  },
  tableConfig: {
    type: Object,
    default: () => ({
      emptyText: "暂无数据",
      defaultSort: {},
      // 打开表格判空
      openEmptyColumn: true,
      // 看空条件
      emptyColumn: [null, "", undefined],
      // 判空显示结果
      emptyResult: "-",
    }),
  },
  pageParams: {
    type: Object,
    default: () => ({}),
  },
  // 模板数据
  templateData: {
    type: Array,
    default: () => [],
  },

  /* 树形 配置 */
  // 行key
  rowKey: {
    type: String,
    default: "id",
  },
  // 树形item 格式
  treeProps: {
    type: Object,
    default: () => ({
      children: "children",
      hasChildren: "hasChildren",
    }),
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  load: {
    type: Function,
    default: () => {},
  },
  // 缩进
  indent: {
    type: Number,
    default: 16,
  },
  // 分页使用小型
  pageSmall: {
    type: Boolean,
    default: false,
  },
  // 单元格 跳过点击cell
  cellJumpClick: {
    type: Array,
    default: () => [],
  },
  // 行列 点击跳过row
  rowJumpClick: {
    type: Array,
    default: () => [],
  },
  // 行点击 row是否选中
  useJumpClick: {
    type: Boolean,
    default: true,
  },
  // 选中row是否高亮
  highlightCurrentRow: {
    type: Boolean,
    default: false,
  },
  // 表头设置 key
  headerConfigKey: {
    type: String,
    default: "normal",
  },
});

const { proxy } = getCurrentInstance();

const mainStore = proxy.$usePiniaModule("mainStore");

// 表格中 时间需要去除秒字符的参数名组
const clearSecondParamNames = [
  "createTime",
  "loginTime",
  "operationTime",
  "upgradeTime",
];

/* 当前页面路由名 */
const currentRouteName = computed(() => proxy.$route.name);

// 表头勾选项
const useTemplateData = computed(() => props.templateData);
const tableHeaderShowTemplate = ref(useTemplateData.value.map((d, i) => i));
/* 表格表头配置 数据缓存名称 */
const tableHeaderStoreName = computed(
  () => `${currentRouteName.value}__tableHeader__${props.headerConfigKey}`
);

// 设置动态表格
const setActiveTableHeaderTemplate = () => {
  const tableHeaderName = tableHeaderStoreName.value;
  /* 初始表格表头模板 */
  const storeData = proxy.$getItem(tableHeaderName);
  // 已有缓存值
  if (storeData) {
    // 赋值
    const tableHeaderTemplateCheck = JSON.parse(storeData);
    tableHeaderShowTemplate.value = tableHeaderTemplateCheck;
  } else {
    // 恢复默认
    tableHeaderShowTemplate.value = useTemplateData.value.map((d, i) => i);
  }
};
// 监听 表头配置 key的变化
watch(
  () => tableHeaderStoreName.value,
  (nVal) => {
    setActiveTableHeaderTemplate();
  },
  {
    immediate: true,
  }
);
watch(
  () => props.templateData,
  (nVal) => {
    setActiveTableHeaderTemplate();
  },
  {
    deep: true,
  }
);

/* 属性 */
const { tableConfig, pageParams } = toRefs(props);

// ref
const tableRef = ref();

// 判断 prop名称跳过点击操作
const useCellJumpClick = computed(() => {
  const { cellJumpClick } = props;

  return [...cellJumpClick, "btnActive"];
});
const useRowJumpClick = computed(() => {
  const { rowJumpClick } = props;

  return [...rowJumpClick, "btnActive"];
});

// 加载
const showLoading = ref(true);
// 打开加载
const openLoading = () => {
  showLoading.value = true;
};
// 关闭加载
const closeLoading = () => {
  showLoading.value = false;
};

// 小型分页
const usePageSmall = computed(() => props.pageSmall);

watch(
  () => showLoading.value,
  (nVal) => {
    if (nVal) {
      tableConfig.value.emptyText == "加载中...";
    } else {
      tableConfig.value.emptyText == "暂无数据";
    }
  }
);

/* 权限处理 */
// btnActive 是否显示
const showAuthTemplate = (propName) => {
  const currentCol = props.templateAuths[propName];
  if (currentCol && currentCol.length) {
    const currentAuths = currPageUseAuths.value;
    // 无任何 权限
    const haveAuthBtnNums = currentCol.filter((d) => currentAuths.includes(d));
    return !!haveAuthBtnNums.length;
  } else {
    return true;
  }
};

// 延迟动画，赋值数据
watch(
  () => props.tableData,
  (opt) => {
    setTimeout(() => {
      listData.value = (opt.result || []).map((d) => {
        const paramNames = Object.keys(d);

        // 含有的时间参数
        const hasTimeParams = paramNames.filter((name) =>
          clearSecondParamNames.includes(name)
        );

        for (var i = 0; i < hasTimeParams.length; i++) {
          const timeParamName = hasTimeParams[i];
          const timeValue = d[timeParamName];

          if (timeValue) {
            // 赋值
            d[timeParamName] = proxy.$timeStampToTime(timeValue, {
              format: "YYYY-MM-DD hh:mm",
            });
          }
        }

        return d;
      });
      pageConfig.total = opt.total || 0;

      resetTimeLoading();

      nextTick(() => {
        // getAutoTableColumn(reloadTable);
      });
    }, 200);
  },
  {
    deep: true,
  }
);

watch(pageParams.value, (nVal) => {
  openLoading();
  emits("update:pageParams", nVal);
});

/* 操作表头 */
// 表格模板数据
const showTemplateData = computed(() => {
  const result = useTemplateData.value
    .filter((d, index) => tableHeaderShowTemplate.value.includes(index))
    .filter((d) => {
      /* 判断当前页面 当前表格的模板展示状态 */
      // 需要判断 权限的 column template
      const needAuthProps = Object.keys(props.templateAuths);

      // 存在 需要校验
      if (needAuthProps.includes(d.prop)) {
        return showAuthTemplate(d.prop);
      } else {
        return true;
      }
    });

  // console.log(tableHeaderShowTemplate.value);
  // console.log(useTemplateData.value);
  // console.log(result);
  return result;
  // return useTemplateData.value;
});
// 监听勾选变化
const onChangeTemplatecheckbox = () => {
  nextTick(() => {
    // json数据 勾选值
    const tableTemplateJsonData = JSON.stringify(tableHeaderShowTemplate.value);

    // 缓存到本地
    proxy.$setItem(tableHeaderStoreName.value, tableTemplateJsonData);

    // 重置
    isGetAutoColumnWidthOver.value = false;

    setTimeout(() => {
      // 刷新
      reloadTable();
    });
  });
};

/* 表格 column */
// 是否已查询过 模板 自适应宽度的column
const isGetAutoColumnWidthOver = ref(false);
// 可处理的 模板 自适应宽度
const haveAutoWidthItems = showTemplateData.value.filter((d, i) => {
  d.columnIndex = i;
  return d.autoWidth;
});
// 获取当前column的宽度
const getAutoTableColumn = (reloadTableCall) => {
  setTimeout(() => {
    // 遍历处理
    for (let i = 0; i < haveAutoWidthItems.length; i++) {
      const item = haveAutoWidthItems[i];
      const columnName = `table-column-${item.prop}-name`;
      const columns = proxy.$el.getElementsByClassName(columnName);
      // console.log(columns);

      // 以第一列为基础
      const [first] = columns;
      if (first) {
        // 35 为内边距
        const width = first.clientWidth + 35;
        // console.log(width);
        for (let i = 0; i < columns.length; i++) {
          columns[i].offsetParent.firstChild.style.width = width + "px";
          showTemplateData.value[item.columnIndex].width = width.toString();
        }
      }
    }

    setTimeout(() => {
      // 刷新表格 赋值 遍历的数据
      reloadTableCall ? reloadTableCall() : "";
    });
  }, 100);
};

/* 表格操作 数据 */
const listData = ref(props.tableData.result);

const pageConfig = reactive({
  total: 0,
});
/* 表格操作 */
// 固定15s后加载动画停止
const timeId = ref();

const clear15sTime = () => {
  timeId.value = setTimeout(resetTimeLoading, 15000);
};
// 重置加载
const resetTimeLoading = () => {
  closeLoading();
  clearTimeout(timeId.value);
  timeId.value = null;
};

// 判断插槽是否存在
const hasSlot = (slotName) => {
  return !!proxy.$slots[slotName];
};

/* 监听操作 */
// 监听表格选择
const selectHand = (res) => {
  emits("selectHand", res);
};
const selectHandAll = (res) => {
  emits("selectHandAll", res);
};

// 监听选择变化
const onSelectionChange = (selections) => {
  emits("onSelectionChange", selections);
};

// 单机 行
const onRowClick = (row, column = {}, event) => {
  // 如果useJumpClick  false
  if (!props.useJumpClick) {
    return;
  }
  // 跳过属性单元的 操作
  if (!useRowJumpClick.value.includes((column || {}).property)) {
    tableRef.value.toggleRowSelection(row);
    const rows = tableRef.value.getSelectionRows();
    emits("selectHand", rows);
  }
};

// 单击 单元格
const cellClick = (row, column, cell, event) => {
  // 跳过属性单元的 操作
  if (!useCellJumpClick.value.includes(column.property)) {
    if (props.tableModed == "normal") {
      setCurrentRow(row);
    } else {
      const element = cell.parentElement.getElementsByClassName(
        "el-table__expand-icon"
      )[0];
      // 触发展开、缩放
      if (element) {
        element.click();
      }
    }

    emits("cellClick", { row, column, cell, event });
  }
};
// 展开
const expandChange = (row, isExpanded) => {
  emits("expandChange", { row, isExpanded });
};

/* 单选 */
const currentChange = (currentRow, oldCurrentRow) => {
  if (currentRow) {
    emits("currentChange", currentRow, oldCurrentRow);
  }
};

/* 主动操作 */
// 清除选择
const clearSelection = computed(() => tableRef.value.clearSelection);
// 用于可扩展的表格或树表格
const toggleRowExpansion = computed(() => tableRef.value.toggleRowExpansion);
// 用于单选表格
const setCurrentRow = (row) => {
  tableRef.value.setCurrentRow(row);
};
// 刷新整个表格
const refreshTable = ref(true);
const reloadTable = () => {
  refreshTable.value = false;
  nextTick(() => {
    refreshTable.value = true;
  });
};

// ***********************************************************************************************
// 进度条处理 START
// ***********************************************************************************************

// 进度条状态
const progressState = reactive({
  show: false,
  progress: 0,
});

// 打开进度条
const openProgress = ({ progress }) => {
  progressState.show = true;
  progressState.progress = progress;

  if (progress == 100) {
    closeProgress();
  }
};

// 关闭进度条
const closeProgress = () => {
  progressState.show = false;
  setTimeout(() => {
    progressState.progress = 0;
  }, 100);
};

// 终止上传请求
const discontinueHandle = () => {
  closeProgress();
  closeLoading();
  if (mainStore.httpSource) {
    mainStore.httpSource.cancel();
  }
};

// ***********************************************************************************************
// 进度条处理 END
// ***********************************************************************************************

// 表格 多选 用于多选表格，切换某一行的选中状态， 如果使用了第二个参数，则可直接设置这一行选中与否
const toggleRowSelection = (row, isChecked) => {
  tableRef.value.toggleRowSelection(row, isChecked);
};

// 布局
const doLayout = () => tableRef.value.doLayout;

// store
const tableStore = computed(() => tableRef.value.store);

onMounted(() => {
  // console.log(tableRef.value);
  clear15sTime();
});

defineExpose({
  showLoading,
  clearSelection,
  toggleRowExpansion,
  toggleRowSelection,
  setCurrentRow,
  store: tableStore,
  openLoading,
  closeLoading,
  reloadTable,
  doLayout,

  openProgress,
  closeProgress,
});
</script>

<style lang="scss" scoped>
.table-container {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;

  &.is-table-empty {
    // 隐藏 el-table 自带的 el-scrollbar__bar
    .el-table__body-wrapper {
      width: 101%;
    }
  }

  &.is-table-tree {
    ::v-deep(.el-table) {
      .el-table__cell {
        .cell {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }

  .table-header-handle {
    position: absolute;
    z-index: 10;
    right: 1%;
    top: 10px;
    display: flex;
    padding: 5px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 1px 2px 10px 1px #00000012;
    cursor: pointer;
  }

  .warpper-table-content {
    width: 100%;
    overflow: hidden;

    // // 外部显示样式
    // background-color: white;
    // padding: 0 15px;
    // border: 1px solid #ebeef5;
    // border-radius: 6px;
    // box-sizing: border-box;
  }
}

// 头部模板配置
.header-template-box {
  height: 100%;
  max-height: 60vh;
  overflow-y: auto;
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}

.table-container {
  position: relative;
  .el-table__row {
    .cell {
      &.el-tooltip {
        > div {
          width: 100%;
        }
      }
    }
  }

  .drag-upload-progress {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2010;
    background-color: #ffffffc2;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .progress-line {
      display: flex;
      width: 170px;
      flex-direction: row;
      align-items: center;

      .discontinue {
        margin-right: 5px;
        cursor: pointer;
        .iconfont {
          color: red;
        }
      }

      .line-box {
        width: 100%;
        .el-progress {
          .el-progress__text {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.table-container {
  .warpper-table-content {
    .el-loading-mask {
      background-color: white;
    }
  }

  // 表格的按钮操作项
  .table-column-btnActive-name {
    display: flex;
  }
}
</style>
