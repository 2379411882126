import { createStore } from "vuex";

import mainModules from "./mainModules";
import baseConfigModules from "./baseConfigModules";
import userConfigModules from "./userConfigModules";
import deviceModules from "./deviceModules";

export default createStore({
  state: {
    // 请求接口配置项
    https: {},
  },
  mutations: {
    // 设置请求函数
    setHttps(state, res) {
      state.https = res;
    },
  },
  actions: {},
  getters: {
    getHttps: (state) => state.https,
  },
  modules: {
    mainModules,
    baseConfigModules,
    userConfigModules,
    deviceModules,
  },
});
