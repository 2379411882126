// 用户管理
export default [
  /*后台用户管理 */
  // 添加系统用户
  {
    name: "addSystemUser",
    url: "/user/addSystemUser",
    method: "post",
  },
  // 修改系统用户
  {
    name: "modifySystemUser",
    url: "/user/modifySystemUser",
    method: "put",
  },
  // 获取系统用户分页列表
  {
    name: "getSystemUserPageList",
    url: "/user/getSystemUserPageList",
    method: "get",
  },
  // 修改系统用户状态
  {
    name: "modifySystemUserStatus",
    url: "/user/modifySystemUserStatus",
    method: "put",
  },
  // 批量删除系统用户
  {
    name: "batchRemoveSystemUser",
    url: "/user/batchRemoveSystemUser",
    method: "delete",
  },
  // 重置系统用户密码
  {
    name: "resetSystemUserPassword",
    url: "/user/resetSystemUserPassword",
    method: "put",
  },
  // 获取系统用户详情
  {
    name: "getSystemUserDetailsReporting",
    url: "/user/getSystemUserDetailsReporting",
    method: "get",
  },

  /*角色权限管理 */
  // 获取角色菜单列表
  {
    name: "getRoleMenuList",
    url: "/role/getRoleMenuList",
    method: "get",
  },
  // 获取角色详情
  {
    name: "getRoleDetails",
    url: "/role/getRoleDetails",
    method: "get",
  },
  // 添加角色
  {
    name: "addRole",
    url: "/role/addRole",
    method: "post",
  },
  // 获取角色分页列表
  {
    name: "getRolePageList",
    url: "/role/getRolePageList",
    method: "get",
  },
  // 修改角色
  {
    name: "modifyRole",
    url: "/role/modifyRole",
    method: "put",
  },
  // 删除角色
  {
    name: "removeRole",
    url: "/role/removeRole",
    method: "delete",
  },

  // 获取角色选择器列表
  {
    name: "getRolePageChooser",
    url: "/role/getRolePageChooser",
    method: "get",
  },
];
