<template>
  <div class="page-box">
    <el-pagination
      :style="`grid-template-columns: 1fr repeat(${layouts.length - 1},auto);`"
      background
      :layout="useLayout"
      :total="currTotal"
      :page-sizes="pageSize"
      v-model:page-size="pageParams.pageSize"
      v-model:current-page="pageParams.pageNum"
      :small="useSmall"
    />
  </div>
</template>

<script setup>
import { reactive, watch, computed } from "vue";

let props = defineProps({
  pageConfig: {
    type: Object,
    default: () => ({
      total: 0,
    }),
  },
  pageParams: {
    type: Object,
    default: () => ({
      pageSize: 100,
      pageNum: 1,
    }),
  },
  small: {
    type: Boolean,
    default: false,
  },
});

// 使用的分页布局
const layouts = ["sizes", "total", "prev", "pager", "next", "jumper"];
const useLayout = layouts.join(",");

// 使用小型预览
const useSmall = computed(() => props.small);

// 分页的数据量大小
const pageSize = [100, 500, 1000, 5000, 10000];

// 当前的总数
const currTotal = computed(() => Number(props.pageConfig.total || 0));

const emit = defineEmits(["update:pageParams"]);

let { pageParams } = reactive(props);

watch([() => pageParams.pageSize, () => pageParams.pageNum], () => {
  // console.log(pageParams);
  emit("update:pageParams", pageParams);
});
</script>

<style lang="scss" scoped>
.page-box {
  padding: 15px 0;

  .el-pagination {
    display: grid !important;
  }
}
</style>
