<template>
  <div class="download-line-text">
    <span class="file-name" @click="examineFile">{{
      fileName || "未知文件"
    }}</span>
    <span
      class="download-name"
      v-if="!authDisabled && allowDownload"
      @click="downloadFile"
      >下载文件</span
    >
  </div>

  <!-- 预览文件 -->
  <file-preview-dialog ref="previewFileDialogRef"></file-preview-dialog>
</template>

<script setup>
import filePreviewDialog from "@/components/file/preview/index.vue";

import { computed, getCurrentInstance, ref } from "vue";
import {
  usePageFunctionAuthModule,
  useGetMediumFileTypeModule,
} from "@/utils/mixin";

const props = defineProps({
  fileName: {
    type: String,
    default: "",
  },
  filePath: {
    type: String,
    default: "",
  },
  fileType: {
    type: String,
    default: "",
  },
  menuCode: {
    type: String,
    default: "",
  },
  // 是否使用menuCode
  useMenuCode: {
    type: Boolean,
    default: true,
  },
  // 是否允许下载文件
  allowDownload: {
    type: Boolean,
    default: true,
  },
});

const { getUploadFileScope } = useGetMediumFileTypeModule();

const { proxy } = getCurrentInstance();

/* 权限信息 */
const { isDisabled } = usePageFunctionAuthModule(props);
// const authDisabled = computed(() => true);
// 无权限禁用
const authDisabled = computed(() => {
  const { useMenuCode } = props;
  return useMenuCode ? isDisabled.value : false;
});

// 点击下载
const downloadFile = proxy.$bypass(() => {
  const { fileName, filePath } = props;
  proxy.$downloadFile({
    downloadName: fileName,
    href: filePath,
  });
});

/* 预览文件 */
// ref
const previewFileDialogRef = ref();

// 点击查看
const examineFile = proxy.$bypass(() => {
  const { fileName, filePath } = props;
  const { scopeType } = getUploadFileScope({ name: fileName });

  previewFileDialogRef.value.openFileDialog(
    {
      filePath,
      fileType: scopeType,
    },
    {
      name: fileName,
    }
  );
});
</script>

<style lang="scss">
.download-line-text {
  cursor: pointer;

  .file-name {
    font-size: 13px;
    color: #3363ff;
    word-break: break-all;
  }
  .download-name {
    font-size: 12px;
    color: var(--el-color-primary);
    margin-left: 5px;
  }
}
</style>
