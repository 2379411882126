<template>
  <ml-switch
    class="auth-switch"
    v-model="nowSwitch"
    :activeText="activeText"
    :inactiveText="inactiveText"
    :activeColor="activeColor"
    :inactiveColor="inactiveColor"
    :disabled="authDisabled"
    :loading="loading"
    @change="change"
    @click.stop
    :beforeChange="nowBeforeChange"
  />
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { usePageFunctionAuthModule } from "@/utils/mixin";

const emits = defineEmits(["update:modelValue", "change"]);

const props = defineProps({
  modelValue: {
    type: [Number, String, Boolean],
    default: "",
  },
  activeText: {
    type: String,
    default: "启用",
  },
  inactiveText: {
    type: String,
    default: "禁用",
  },
  activeColor: {
    type: String,
    default: "rgba(37, 176, 3, 1)",
  },
  inactiveColor: {
    type: String,
    default: "#ff4949",
  },
  inactiveValue: {
    type: [Number, String, Boolean],
    default: 0,
  },
  beforeChange: {
    type: [Function, Boolean],
    default: () => true,
  },
  menuCode: {
    type: String,
    default: "",
  },
  // 是否使用menuCode
  useMenuCode: {
    type: Boolean,
    default: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const loading = ref(false);
const loadingTimeId = ref(null);

const { isDisabled } = usePageFunctionAuthModule(props);

// const authDisabled = computed(() => true);
// 无权限禁用
const authDisabled = computed(() => {
  const { useMenuCode } = props;
  return useMenuCode ? isDisabled.value : false;
});

// 关闭加载控制计时
const closeLoadingTime = () => {
  clearTimeout(loadingTimeId.value);
  loadingTimeId.value = null;
};

// 切换之前的校准
const nowBeforeChange = () => {
  const { beforeChange, readonly } = props;
  if (typeof beforeChange == "boolean") {
    return beforeChange;
  } else {
    return new Promise(async (res, rej) => {
      // 禁用
      if (authDisabled.value) {
        rej(false);
      } else {
        loadingTimeId.value = setTimeout(() => {
          loading.value = true;
        }, 500);
        try {
          const result = await beforeChange();
          // console.log(result);
          loading.value = false;
          closeLoadingTime();
          res(result);
        } catch (error) {
          console.log(error);
          loading.value = false;
          closeLoadingTime();
          rej(false);
        }
      }
    });
  }
};

const nowSwitch = ref(props.modelValue);

watch(
  () => props.modelValue,
  (nVal, oVal) => {
    nowSwitch.value = nVal;
  },
  {
    deep: true,
  }
);
watch(
  () => nowSwitch.value,
  (nVal) => {
    emits("update:modelValue", nVal);
  }
);

const change = () => {
  emits("change", nowSwitch.value);
};
</script>

<style lang="scss">
.ml-switch {
  &.el-switch {
    .el-switch__core {
      height: 25px;
      border-radius: 30px;

      .el-switch__action {
        width: 18px;
        height: 18px;
      }
    }

    &.is-checked {
      .el-switch__action {
        left: calc(100% - 20px);
      }
    }
  }
}
</style>
