/* 本地缓存名称列表 */

/**
 * 本地登录 授权凭证名称
 */
export const STORE_NAME_AUTH_TOKEN = "Auth-Token";

/**
 * 通用账户管理 缺少上级用户
 */
// 设置状态 (next-once)稍后设置、(next-login)下次设置
// 显示规则
export const STORE_NAME_SUPERIOR_USER_DIALOG = "superior_user_dialog_show_type";
// 下次设置 是否已重新登录 (yes)已重新登录 (no)为重新登录
export const STORE_NAME_SUPERIOR_USER_NEXT_LOGIN = "superior_user_next_login";

// 记住登录账户 一周内未操作失效
export const STORE_NAME_REMENBER_ACCOUNT = "remenber_account";

// 设置自定义ip端口地址
export const STORE_SERVICE_URL_NAME = "oss-service-url";
