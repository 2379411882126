import { storeResponse } from "@/utils/module";
// 用户管理
export default {
  actions: {
    // ********************************************************************************
    // 后台用户管理
    // ********************************************************************************
    // 添加系统用户
    async fetchAddSystemUser({ getters }, { params, query }) {
      const { addSystemUser } = getters.getHttps;

      const result = await addSystemUser(params, {
        custom: {
          isQuery: true,
          query,
        },
      });

      return storeResponse(result);
    },
    // 修改系统用户
    async fetchModifySystemUser({ getters }, { params, query }) {
      const { modifySystemUser } = getters.getHttps;

      const result = await modifySystemUser(params, {
        custom: {
          isQuery: true,
          query,
        },
      });

      return storeResponse(result);
    },
    // 获取系统用户分页列表
    async fetchGetSystemUserPageList({ getters }, params) {
      const { getSystemUserPageList } = getters.getHttps;

      const result = await getSystemUserPageList(params);

      return storeResponse(result);
    },
    // 修改系统用户状态
    async fetchModifySystemUserStatus({ getters }, params) {
      const { modifySystemUserStatus } = getters.getHttps;

      const result = await modifySystemUserStatus(params);

      return storeResponse(result);
    },
    // 批量删除系统用户
    async fetchBatchRemoveSystemUser({ getters }, params) {
      const { batchRemoveSystemUser } = getters.getHttps;

      const result = await batchRemoveSystemUser(params);

      return storeResponse(result);
    },
    // 重置系统用户密码
    async fetchResetSystemUserPassword({ getters }, params) {
      const { resetSystemUserPassword } = getters.getHttps;

      const result = await resetSystemUserPassword(params);

      return storeResponse(result);
    },
    // 获取系统用户详情
    async fetchGetSystemUserDetailsReporting({ getters }, params) {
      const { getSystemUserDetailsReporting } = getters.getHttps;

      const result = await getSystemUserDetailsReporting(params);

      return storeResponse(result);
    },

    // ********************************************************************************
    // 角色权限管理
    // ********************************************************************************
    // 获取角色菜单列表
    async fetchGetRoleMenuList({ getters }, params) {
      const { getRoleMenuList } = getters.getHttps;

      const result = await getRoleMenuList(params);

      return storeResponse(result);
    },
    // 获取角色详情
    async fetchGetRoleDetails({ getters }, params) {
      const { getRoleDetails } = getters.getHttps;

      const result = await getRoleDetails(params, {
        custom: {
          isPathVariable: true,
        },
      });

      return storeResponse(result);
    },
    // 添加角色
    async fetchAddRole({ getters }, { params, query }) {
      const { addRole } = getters.getHttps;

      const result = await addRole(params, {
        custom: {
          isQuery: true,
          query,
        },
      });

      return storeResponse(result);
    },
    // 获取角色分页列表
    async fetchGetRolePageList({ getters }, params) {
      const { getRolePageList } = getters.getHttps;

      const result = await getRolePageList(params);

      return storeResponse(result);
    },
    // 修改角色
    async fetchModifyRole({ getters }, { params, query }) {
      const { modifyRole } = getters.getHttps;

      const result = await modifyRole(params, {
        custom: {
          isQuery: true,
          query,
        },
      });

      return storeResponse(result);
    },
    // 删除角色
    async fetchRemoveRole({ getters }, params) {
      const { removeRole } = getters.getHttps;

      const result = await removeRole(params, {
        custom: {
          isPathVariable: true,
        },
      });

      return storeResponse(result);
    },

    // 获取角色选择器列表
    async fetchGetRolePageChooser({ getters }, params) {
      const { getRolePageChooser } = getters.getHttps;

      const result = await getRolePageChooser(params);

      return storeResponse(result);
    },
  },
};
