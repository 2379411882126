<template>
  <div
    class="authority-zone-tree ml-scrollbar"
    :style="{ height, 'max-height': `${maxHeight}` }"
  >
    <!-- 全国 废弃 -->
    <!-- <div class="checkbox-all-item">
      <el-checkbox
        label="全国"
        v-model="treeChangeAllStatus"
        @change="onChangeAllCheckeStatus"
        :disabled="disabled"
      ></el-checkbox>
    </div> -->

    <tree-template-one
      @check="onHandleCheckTreeNode"
      @onNodeExpand="onNodeExpand"
      @onNodeCollapse="onNodeCollapse"
      ref="treeRef"
      :data="treeChangeData"
      :nodeKey="nodeKey"
      :showCheckbox="showCheckbox"
      :props="treeProps"
      :defaultExpandedKeys="defaultExpandedKeys"
      :checkStrictly="useCheckStrictly"
      :disabled="disabled"
    >
      <template #default="{ node }" v-if="hasSlot('default')">
        <slot :node="node"></slot>
      </template>
    </tree-template-one>
  </div>
</template>

<script setup>
import treeTemplateOne from "../tree-template-one/index.vue";
import { ref, getCurrentInstance, watch, nextTick, computed } from "vue";
import {
  useTreeCheckAllStatusModule,
  useTreeInductiveSubclassData,
} from "@/utils/mixin";

/* 全部 状态已在本版本废弃，无需通过独立的勾选去处理了 */

const emits = defineEmits([
  "update:checkAll",
  "update:changeStatus",
  "onChangeData",
  "change",
  "onNodeExpand",
  "onNodeCollapse",
]);

const props = defineProps({
  // 区域状态 （0:全国、1:自定义）
  changeStatus: {
    type: [String, Number, Boolean],
    default: 1,
  },
  // 是否勾选全部状态
  checkAllStatus: {
    type: Boolean,
    default: false,
  },
  nodeKey: {
    type: String,
    default: "id",
  },
  // 显示勾选项
  showCheckbox: {
    type: Boolean,
    default: true,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: "100%",
  },
  maxHeight: {
    type: String,
    default: "400px",
  },
  // 树形默认 不限 全选
  defaultAllCheckbox: {
    type: Boolean,
    default: false,
  },
  params: {
    type: Object,
    default: () => ({}),
  },
  fetchName: {
    type: String,
    default: "fetchGetAllDistrictBranchChooser",
  },
});

const { proxy } = getCurrentInstance();

/* 属性 */
// 勾选全部状态
const treeChangeAllStatus = ref(props.checkAllStatus);
// 父子级不关联
const useCheckStrictly = computed(() => !treeChangeAllStatus.value);

// 全选操作状态
const handleCheckAllStatus = ref(true);
// // 全选操作的禁用状态
// const checkAllDisabledStatus = computed(() => {
//   return props.disabled || !handleCheckAllStatus.value;
// });

/* 废弃 */
// 只监听一次 勾选全部状态
// 是否阻止
// const isStopAllStatus = ref(false);
// watch(
//   () => props.checkAllStatus,
//   (nVal) => {
//     if (isStopAllStatus.value) {
//       return;
//     }

//     isStopAllStatus.value = true;

//     const status = proxy.$isEmpty(nVal) ? false : nVal;

//     treeChangeAllStatus.value = status;

//     // 回显状态 非禁用
//     if (!proxy.disabled) {
//       changeAllCheckeStatus(status);
//     }
//   }
// );

/* 默认展开数据 */
const defaultExpandedKeys = computed(() =>
  treeChangeData.value.map((d) => d[props.nodeKey])
);

/* tree 树形结构操作 */
// ref
const treeRef = ref();
// 树形数据
const treeData = ref([]);
const treeChangeData = computed(() => setResultCallBackAddData(treeData.value));
// 树形数据结构参数
const treeProps = {
  label: "name",
  children: "children",
};

/* 解析node数据 */
const getNodeDistrictAndBranch = (checkNodes) => {
  // 区域数据
  const districtNodeData = checkNodes.filter((d) => !d.type);
  // id组
  const districtNodeDataIds = districtNodeData.map((d) => d.id);
  // 名称
  const districtNodeDataNames = districtNodeData.map((d) => {
    if (!d[treeProps.label]) {
      // 获取树形节点中的数据信息
      const node = treeRef.value.getNode(d) || {};
      return (node.data || {})[treeProps.label];
    }
    return d[treeProps.label];
  });

  // console.log(checkNodes);
  // 分店数据
  const branchNodeData = checkNodes.filter((d) => d.type);
  // id组
  const branchNodeDataIds = branchNodeData.map((d) => d.id);
  // 名称
  const branchNodeDataNames = branchNodeData.map((d) => {
    if (!d[treeProps.label]) {
      // 获取树形节点中的数据信息
      const node = treeRef.value.getNode(d) || {};
      return (node.data || {})[treeProps.label];
    }
    return d[treeProps.label];
  });

  // console.log(checkNodes);
  // console.log(districtNodeDataNames);
  // console.log(branchNodeData);
  return {
    districtNodeDataIds,
    branchNodeDataIds,
    branchNodeDataNames,
    districtNodeDataNames,
  };
};
/* 操作全部与子级关联 回调 常规勾选数据 未归纳 */
/* 示例：  xxx省、xx市、xx区、xx县、xx分店 */
const callBackChange = (allKeys, checkNodes, status) => {
  const {
    districtNodeDataIds,
    branchNodeDataIds,
    branchNodeDataNames,
    districtNodeDataNames,
  } = getNodeDistrictAndBranch(checkNodes);
  emits(
    "change",
    {
      districtNodeDataIds,
      branchNodeDataIds,
      branchNodeDataNames,
      districtNodeDataNames,
    },
    status
  );
};
/* 父级归纳子级 回调 常规勾选数据 已归纳 */
/* 示例： xxx省 < xx市 < xx区 < xx县、xx分店 */
const callBackOnChangeData = (checkNodes, checkNodeIds) => {
  const {
    districtNodeDataIds,
    branchNodeDataIds,
    branchNodeDataNames,
    districtNodeDataNames,
  } = getNodeDistrictAndBranch(checkNodes);

  // console.log(checkNodes);
  // console.log(districtNodeDataNames);
  emits(
    "onChangeData",
    {
      districtNodeDataIds,
      branchNodeDataIds,
      branchNodeDataNames,
      districtNodeDataNames,
    },
    checkNodeIds,
    checkNodes
  );
};

/*checkbox 处理 树形中 操作全部与子级关联 操作子级与全部不关联（取消子级选中会取消全部勾选） */
const { onTreeChangeAll, checkTreeChange, setNodesConfig } =
  useTreeCheckAllStatusModule(props, emits, callBackChange, {
    treeRef,
    treeData,
    treeProps,
    treeChangeAllStatus,
    handleCheckAllStatus,
  });
// 设置tree key选中
const setCheckedKeys = (params) => {
  const { districtIds = [], branchIds = [] } = params || {};

  // 创建数据
  const districts = districtIds.map((d) => ({ type: 0, id: d }));
  const branchs = branchIds.map((d) => ({ type: 1, id: d }));

  // 获取区域的node
  const districtNodes = districts.map((d) => treeRef.value.getNode(d));
  // console.log("获取区域的node");
  // console.log(districtNodes);
  // 勾选区域的子级分店数据
  const checkDistrictBranchData = getDistrictChildrenBranch(districtNodes);
  // console.log("勾选区域的子级分店数据");
  // console.log(checkDistrictBranchData);
  // id组
  const checkDistrictBranchIds = checkDistrictBranchData.map((d) => d.id);

  // 通过Id筛选并剔除 区域下 勾选的分店id
  const newBranchIds = checkDistrictBranchIds.filter((d) =>
    branchIds.length ? !branchIds.includes(d) : false
  );
  // console.log("通过Id筛选并剔除 区域下 勾选的分店id");
  // console.log(newBranchIds);
  // 勾选分店结果
  const branchIdsResult = newBranchIds.length ? newBranchIds : branchIds;
  const branchsResult = newBranchIds.length ? checkDistrictBranchData : branchs;
  // const branchIdsResult = newBranchIds;
  // const branchsResult = checkDistrictBranchData;

  const keys = [...districtIds, ...branchIdsResult];
  // 赋值 勾选项
  nowCheckAllData.value = [...districts, ...branchsResult];

  // console.log(nowCheckAllData.value);

  // 赋值选中
  treeRef.value.setCheckedKeys(keys);
};

// 获取区域子级下的分店数据
const getDistrictChildrenBranch = (list = [], result = []) => {
  for (var i = 0; i < list.length; i++) {
    const itemNode = list[i];
    const item = itemNode.data;

    // 分店
    if (item.type) {
      result.push(item);
    }
    const data = getDistrictChildrenBranch(itemNode.childNodes);

    result.push(...data);
  }

  return result;
};

// 获取
const getCheckedKeys = () => {
  return treeRef.value.getCheckedKeys();
};

// 获取选中nodes
const getCheckedNodes = () => treeRef.value.getCheckedNodes();

// 根据 data 或者 key 拿到 Tree 组件中的 node
const getNode = (res) => treeRef.value.getNode(res);

// 禁用树形
const setTreeNodeDisabled = () => {
  treeRef.value.setTreeNodeDisabled();
};

// ****************************************************************************************************
// 数据操作 START
// ****************************************************************************************************

/* 监听全选变动 废弃 */
const onChangeAllCheckeStatus = (res) => {
  changeAllCheckeStatus(res);
};
const changeAllCheckeStatus = (res) => {
  onTreeChangeAll(res);

  // 全选操作
  changeAllStatusTrigger(res);
};

// 全选、取消
const changeAllStatusTrigger = (res) => {
  const { disabled } = props;

  // 操作后
  nextTick(() => {
    if (res) {
      // 获取第一层数据
      nowCheckAllData.value = proxy.$removeDecontextAttr(treeChangeData.value);

      // 禁用状态下
      if (disabled) {
        setTimeout(() => {
          // 获取第一层数据
          const keys = proxy
            .$removeDecontextAttr(nowCheckAllData.value)
            .map((d) => d[props.nodeKey]);

          setCheckedKeys({
            districtIds: keys,
          });
        });
      }
    } else {
      nowCheckAllData.value = [];

      // 禁用状态下
      if (disabled) {
        setCheckedKeys();
      }
    }
    // console.log("数据变动", nowCheckAllData.value);

    emits(
      "onChangeData",
      {
        districtNodeDataIds: [],
        branchNodeDataIds: [],
        branchNodeDataNames: [],
        districtNodeDataNames: [],
      },
      nowCheckAllDataIds.value
    );
  });
};

/* 自定义处理 */
// 勾选的总数据
const nowCheckAllData = ref([]);
// id组
const nowCheckAllDataIds = computed(() =>
  nowCheckAllData.value.filter((d) => !!d).map((d) => d.id)
);

/* 获取勾选总数据 */
const getNowCheckAllData = () =>
  proxy.$removeDecontextAttr(
    nowCheckAllData.value.map((d) => {
      const item = getNode(d) || {};
      const { data } = item;
      return {
        ...data,
        ...d,
      };
    })
  );

/* tree 树形选择 父级归纳子级  配置 */
const { setResultCallBackAddData, onCheckTreeNode } =
  useTreeInductiveSubclassData({
    nowCheckAllData,
    nowCheckAllDataIds,
    treeProps,
    emits,
    callBackOnChangeData,
  });

/* 监听树形节点选中状态 */
const onHandleCheckTreeNode = (checkItem, node) => {
  checkTreeChange(checkItem, node);
  onCheckTreeNode(checkItem, node);
};

// ****************************************************************************************************
// 数据操作 END
// ****************************************************************************************************

/* 查询 */
// 查询行政区域
const getDistrictList = async () => {
  try {
    const res = await proxy.$storeDispatch(props.fetchName, props.params);
    const result = res.result;
    treeData.value = result;
    // console.log(treeData.value);

    // 回显状态
    // onTreeChangeAll(treeChangeAllStatus.value);

    setNodesConfig();

    // console.log(props.defaultAllCheckbox)
    // 默认不限全选
    if (props.defaultAllCheckbox) {
      const item = result[0];
      const id = item[props.nodeKey];
      const keys = [id];

      setCheckedKeys(keys);

      // 触发回调
      callBackOnChangeData([item], keys);
    }

    treeRef.value.hideLoading();
  } catch (error) {
    treeRef.value.hideLoading();
  }
};

/* 监听节点伸缩 */
// 展开
const onNodeExpand = (res) => {
  emits("onNodeExpand", res);
};
// 收起
const onNodeCollapse = (res) => {
  emits("onNodeCollapse", res);
};

// 是否 存在slot
const hasSlot = (name) => {
  return proxy.$slots[name];
};

// 初始
const init = async () => {
  // console.log(props);
  await getDistrictList();
};

defineExpose({
  init,
  setCheckedKeys,
  getCheckedKeys,
  getCheckedNodes,
  getNode,
  changeAllCheckeStatus,
  changeAllStatusTrigger,
  setTreeNodeDisabled,
  getNowCheckAllData,
});
</script>

<style lang="scss" scoped>
.authority-zone-tree {
  width: 100%;
  height: 100%;
  min-height: 150px;
  overflow-y: auto;
  overscroll-behavior: contain;
}
</style>
