<template>
  <div
    class="custom-image"
    :class="`${className} ${size} ${useImgBackground ? 'is-background' : ''}`"
    :style="{ width, height }"
  >
    <el-image
      @load="onImageLoad"
      @error="onImagError"
      :preview-src-list="previewUrls"
      :src="useShowUrl"
      :fit="fit"
      loading="lazy"
      :lazy="true"
      :preview-teleported="true"
      :hide-on-click-modal="true"
      v-loading="isLoading"
      ref="imageRef"
    >
      <template #error>
        <div class="error-slot">
          <el-icon :size="useErrorIconSize" v-if="isEmptyDefault"
            ><Picture
          /></el-icon>

          <img
            :style="`
              width: ${customErrIconWidth};
              height: ${customErrIconHeight};
            `"
            :class="{ 'is-cursor': previewDefault }"
            class="custom-empty-image"
            :src="customErrIcon"
            alt=""
            @click="previewDefaultImage"
            v-if="isEmptyCustom"
          />
        </div>
      </template>
    </el-image>

    <slot></slot>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, nextTick, onMounted, ref } from "vue";

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  fit: {
    type: String,
    default: "cover",
  },
  className: {
    type: String,
    default: "",
  },
  errorIconSize: {
    type: [String, Number],
    default: "",
  },
  // 是否点击查看预览
  preview: {
    type: Boolean,
    default: true,
  },
  // 是否查看更多
  isMore: {
    type: Boolean,
    default: false,
  },
  // 预览列表地址
  previewList: {
    type: Array,
    default: () => [],
  },
  // 是否使用 预览前缀路径
  isPreviewBeforeUrl: {
    type: Boolean,
    default: true,
  },
  // 图片大小 mini||small||normal||large
  size: {
    type: String,
    default: "normal",
  },
  // 缺省类型
  emptyType: {
    type: String,
    default: "default",
  },
  // 图片宽度
  width: {
    type: [String, Object],
    default: null,
  },
  // 图片高度
  height: {
    type: [String, Object],
    default: null,
  },
  // 使用图片背景
  useImgBackground: {
    type: Boolean,
    default: false,
  },
  // 错误展示图标
  customErrIcon: {
    type: String,
    default: "",
  },
  // 错误展示图标 宽度
  customErrIconWidth: {
    type: String,
    default: "64px",
  },
  // 错误展示图标 高度
  customErrIconHeight: {
    type: String,
    default: "64px",
  },
  previewDefault: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

// 使用的 缺省图标大小
const errorIconSizes = {
  small: 16,
  normal: 32,
  large: 64,
};
const useErrorIconSize = computed(() => {
  const { errorIconSize, size } = props;
  return errorIconSize ? errorIconSize : errorIconSizes[size];
});

// 刷新图片
const refresh = ref(true);
const handleRefresh = () => {
  refresh.value = false;
  nextTick(() => {
    refresh.value = true;
  });
};

// 缺省图片
// 默认
const isEmptyDefault = computed(() => props.emptyType == "default");
// 自定义
const isEmptyCustom = computed(() => props.emptyType == "custom");

// 使用的图片地址
const useShowUrl = computed(() => {
  const { isPreviewBeforeUrl, src } = props;

  return isPreviewBeforeUrl ? `${proxy.$previewFileUrl}${src}` : src;
});

// 预览地址
const previewUrls = computed(() => {
  const { preview, isMore, previewList } = props;
  if (preview) {
    if (isMore) {
      return previewList;
    } else {
      // // 其他图片无法预览时，可以预览默认图片
      // if (isPreviewDefault.value) {
      //   return [proxy.customErrIcon];
      // } else {
      return [useShowUrl.value];
      // }
    }
  } else {
    return [];
  }
});

// 点击查看默认图片
const isPreviewDefault = ref(false);
const previewDefaultImage = () => {
  if (!props.previewDefault) {
    return;
  }
  isPreviewDefault.value = true;
};

/* 图片加载状态 */
// 加载状态
const isLoading = ref(true);

// 监听图片的加载
const onImageLoad = (e) => {
  isLoading.value = false;
  setTimeout(() => {
    proxy.$el.children[0].children[0].draggable = false;
  }, 100);
};
// 监听图片的加载失败
const onImagError = (e) => {
  isLoading.value = false;
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
.custom-image {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  // background-color: white;
  border-radius: 6px;
  overflow: hidden;

  &.mini {
    width: 50px;
    height: 50px;
  }

  &.small {
    width: 100px;
    height: 68px;
  }

  &.normal {
    width: 140px;
    height: 120px;
  }

  &.large {
    width: 250px;
    height: 165px;
  }

  &.is-background {
    .el-image {
      background-color: #9e9e9e1c;
    }
  }

  .el-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .error-slot {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #f3f3f3;

      .custom-empty-image {
        object-fit: contain;
      }
    }
  }

  //  加载 遮罩层
  ::v-deep(.el-loading-mask) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #9e9e9e1c;

    .el-loading-spinner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      top: auto;
      margin-top: 0px;

      .circular {
        width: 50%;
        height: 50%;
      }
    }
  }

  .is-cursor {
    cursor: pointer;
  }
}
</style>
