<template>
  <div class="tree-brand-classify">
    <!-- 全部 废弃 -->
    <!-- <div class="checkbox-all-item">
      <el-checkbox
        label="全部"
        @change="onChangeAllCheckeStatus"
        v-model="treeChangeAllStatus"
        :disabled="disabled"
      ></el-checkbox>
    </div> -->

    <tree-template-one
      @check="onHandleCheckTreeNode"
      @onNodeExpand="onNodeExpand"
      @onNodeCollapse="onNodeCollapse"
      ref="treeRef"
      :data="treeData"
      :nodeKey="nodeKey"
      :showCheckbox="showCheckbox"
      :props="treeProps"
      :checkStrictly="isCheckStrictly"
      :defaultExpandedKeys="defaultExpandedKeys"
      :checkOnClickNode="checkOnClickNode"
      :disabled="disabled"
    />
  </div>
</template>

<script setup>
import treeTemplateOne from "../tree-template-one/index.vue";
import { ref, getCurrentInstance, nextTick, computed } from "vue";
import {
  useTreeCheckAllStatusModule,
  useTreeInductiveSubclassData,
} from "@/utils/mixin";

/* 全部 状态已在本版本废弃，无需通过独立的勾选去处理了 */

const emits = defineEmits([
  "change",
  "onChangeData",
  "update:checkAll",
  "update:changeStatus",
  "onNodeExpand",
  "onNodeCollapse",
]);

const props = defineProps({
  // 选择状态 （0:全部、1:自定义）
  changeStatus: {
    type: [String, Number, Boolean],
    default: 1,
  },
  // 是否勾选全部状态
  checkAllStatus: {
    type: Boolean,
    default: false,
  },
  nodeKey: {
    type: String,
    default: "id",
  },
  // 列表请求fetch名
  fetchName: {
    type: String,
    default: "",
  },
  // 显示勾选项
  showCheckbox: {
    type: Boolean,
    default: true,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
  // 父子级不关联
  checkStrictly: {
    type: Boolean,
    default: true,
  },
  // 点击 勾选
  checkOnClickNode: {
    type: Boolean,
    default: false,
  },
  // 树形默认 不限 全选
  defaultAllCheckbox: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

/* 属性 */
// 勾选全部状态
const treeChangeAllStatus = ref(props.checkAllStatus);

/* 父子级是否断开关联 */
const isCheckStrictly = ref(props.checkStrictly);

/* 默认展开数据 */
const defaultExpandedKeys = computed(() =>
  treeData.value.map((d) => d[props.nodeKey])
);

/* tree 树形结构操作 */
// ref
const treeRef = ref();
// 树形数据
const treeData = ref([]);
// 树形数据结构参数
const treeProps = {
  label: "name",
  children: "children",
};

/* 父级归纳子级 回调 常规勾选数据 已归纳 */
/* 示例： xxx一级 < xx二级 < xx三级 */
const callBackOnChangeData = (checkNodes, checkNodeIds) => {
  // console.log(checkNodes, checkNodeIds);
  emits("onChangeData", { checkNodeIds, checkNodes });
};

/* 常规 回调勾选数据 */
const callBackChange = (allKeys, checkNodes, status) => {
  // console.log(allKeys);
  // console.log(checkNodes);
  emits("change", allKeys, status);
};

/* 处理 树形中 操作全部与子级关联 操作子级与全部不关联（取消子级选中会取消全部勾选） */
const { onTreeChangeAll, checkTreeChange, setNodesConfig } =
  useTreeCheckAllStatusModule(props, emits, callBackChange, {
    treeRef,
    treeData,
    treeProps,
    treeChangeAllStatus,
    isCheckStrictly,
  });

/* 监听全选变动 */
const onChangeAllCheckeStatus = (res) => {
  // console.log("onChangeAllCheckeStatus", res);
  changeAllCheckeStatus(res);
};
// 改变全选状态 以及选中数据
const changeAllCheckeStatus = (res) => {
  // console.log("changeAllCheckeStatus", res);

  onTreeChangeAll(res);

  // 全选操作
  changeAllStatusTrigger(res);
};

// 全选、取消
const changeAllStatusTrigger = (res) => {
  const { disabled } = props;

  // 操作后
  nextTick(() => {
    if (res) {
      // 获取第一层数据
      nowCheckAllData.value = proxy.$removeDecontextAttr(treeData.value);

      // 禁用状态下
      if (disabled) {
        setTimeout(() => {
          // 获取第一层数据
          const keys = proxy
            .$removeDecontextAttr(nowCheckAllData.value)
            .map((d) => d[props.nodeKey]);

          setCheckedKeys(keys);
        });
      }
    } else {
      nowCheckAllData.value = [];

      // 禁用状态下
      if (disabled) {
        setCheckedKeys([]);
      }
    }

    emits(
      "onChangeData",
      {
        checkNodeIds: [],
        checkNodes: [],
      },
      nowCheckAllDataIds.value
    );
  });
};

/* tree 树形选择 父级归纳子级  */
// 勾选的总数据
const nowCheckAllData = ref([]);
// id组
const nowCheckAllDataIds = computed(() =>
  nowCheckAllData.value.filter((d) => !!d).map((d) => d.id)
);
/*   配置 */
const { setResultCallBackAddData, onCheckTreeNode } =
  useTreeInductiveSubclassData({
    nowCheckAllData,
    nowCheckAllDataIds,
    treeProps,
    emits,
    callBackOnChangeData,
  });

/* 监听树形节点选中状态 */
const onHandleCheckTreeNode = (checkItem, node) => {
  checkTreeChange(checkItem, node);
  onCheckTreeNode(checkItem, node);
};

// 设置tree key选中
const setCheckedKeys = (ids) => {
  // console.log(ids);
  // 数据
  const brandTypeList = ids.map((d) => {
    const item = treeRef.value.getNode({ [props.nodeKey]: d }) || {};
    const data = item.data || {};

    return {
      [props.nodeKey]: d,
      [treeProps.label]: data[treeProps.label],
    };
  });

  // 赋值 勾选项
  nowCheckAllData.value = [...brandTypeList];

  // 赋值选中
  treeRef.value.setCheckedKeys(ids);
};
// 获取keys
const getCheckedKeys = () => {
  return treeRef.value.getCheckedKeys();
};
// 获取tree选中node
const getCheckedNodes = () => {
  return treeRef.value.getCheckedNodes();
};

/* 查询 */
// 查询品牌分类
const getBrandClassList = async () => {
  try {
    const res = await proxy.$storeDispatch(props.fetchName);
    const result = res.result;
    treeData.value = setResultCallBackAddData(result);
    // console.log(treeData.value);
    // 设置节点配置
    setNodesConfig();

    // 默认不限全选
    if (props.defaultAllCheckbox) {
      const item = result[0];
      const id = item[props.nodeKey];
      const keys = [id];

      setCheckedKeys(keys);

      // 触发回调
      callBackOnChangeData([item], keys);
    }

    treeRef.value.hideLoading();
  } catch (error) {
    treeRef.value.hideLoading();
  }
};

/* 监听节点伸缩 */
// 展开
const onNodeExpand = (res) => {
  emits("onNodeExpand", res);
};
// 收起
const onNodeCollapse = (res) => {
  emits("onNodeCollapse", res);
};
// 初始
const init = async () => {
  await getBrandClassList();
};

defineExpose({
  init,
  setCheckedKeys,
  getCheckedKeys,
  getCheckedNodes,
  changeAllCheckeStatus,
});
</script>

<style lang="scss" scoped>
.tree-brand-classify {
  width: 100%;
  height: 100%;
}
</style>
