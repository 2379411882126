import { storeResponse } from "@/utils/module";
import { updateFileUrl, operateFileUrl } from "@/utils/apiConfig";

export default {
  actions: {
    /* 登陆 */
    //  手机号密码登录
    async fetchPhonePasswordLogin({ getters }, params) {
      const { phonePasswordLogin } = getters.getHttps;

      const result = await phonePasswordLogin(params, {
        custom: {
          isFormData: true,
        },
      });

      return storeResponse(result);
    },
    //  获取当前系统用户信息
    async fetchGetCurrentSystemUserInfo({ getters }, params) {
      const { getCurrentSystemUserInfo } = getters.getHttps;

      const result = await getCurrentSystemUserInfo(params);

      return storeResponse(result);
    },
    //  修改系统用户密码
    async fetchModifyUserPassword({ getters }, params) {
      const { modifyUserPassword } = getters.getHttps;

      const result = await modifyUserPassword(params);

      return storeResponse(result);
    },

    // 获取菜单
    async fetchGetSystemMenuList({ getters }, params) {
      const { getSystemMenuList } = getters.getHttps;

      const result = await getSystemMenuList(params);

      return storeResponse(result);
    },

    // 上传
    async fetchUploadFile({ getters }, { formdata, callback }) {
      const { uploadFile } = getters.getHttps;
      const result = await uploadFile(formdata, {
        custom: {
          customApiUrl: updateFileUrl,
          isFile: true,
          onUploadProgress: callback,
        },
      });

      return storeResponse(result);
    },

    /* 菜单管理 */
    // 添加菜单
    async fetchAddMenu({ getters }, params) {
      const { addMenu } = getters.getHttps;

      const result = await addMenu(params);

      return storeResponse(result);
    },
    // 修改菜单
    async fetchModifyMenu({ getters }, params) {
      const { modifyMenu } = getters.getHttps;

      const result = await modifyMenu(params, {
        custom: {
          isFormData: true,
        },
      });

      return storeResponse(result);
    },
    // 获取菜单列表
    async fetchGetMenuList({ getters }, params) {
      const { getMenuList } = getters.getHttps;

      const result = await getMenuList(params);

      return storeResponse(result);
    },
    // 获取当前系统用户角色菜单列表
    async fetchGetCurrentSystemUserRoleMenuList({ getters }, params) {
      const { getCurrentSystemUserRoleMenuList } = getters.getHttps;

      const result = await getCurrentSystemUserRoleMenuList(params);

      return storeResponse(result);
    },
  },
};
