import mlDialog from "./ml-dialog";
import mlForm from "./ml-form.vue";
import mlTable from "./ml-table.vue";
import mlPage from "./ml-page.vue";
import mlSwitch from "./ml-switch.vue";
import mlSelect from "./ml-select.vue";
import mlButton from "./ml-button.vue";
import mlImage from "./ml-image.vue";
import mlInput from "./ml-input.vue";
import mlDarg from "./ml-darg.vue";
import mlDateTime from "./ml-date-time.vue";
import mlTreeSelect from "./ml-tree-select.vue";
import mlTabs from "./ml-tabs.vue";
import mlCollapsePanel from "./ml-collapse-panel.vue";
import mlDrawer from "./ml-drawer.vue";

const comps = {
  mlDialog,
  mlForm,
  mlTable,
  mlPage,
  mlSwitch,
  mlSelect,
  mlButton,
  mlImage,
  mlInput,
  mlDarg,
  mlDateTime,
  mlTreeSelect,
  mlTabs,
  mlCollapsePanel,
  mlDrawer,
};

export default {
  install(Vue) {
    for (let i in comps) {
      Vue.component(i, comps[i]);
    }
  },
};
