import { storeResponse } from "@/utils/module";
// 用户管理
export default {
  actions: {
    // ********************************************************************************
    // 设备管理
    // ********************************************************************************
    // 添加设备
    async fetchAddDevicer({ getters }, params) {
      const { addDevice } = getters.getHttps;
      const result = await addDevice(params, {
        custom: {
          isFormData: true,
        },
      });
      return storeResponse(result);
    },
    // 修改设备
    async fetchModifyDevicer({ getters }, params) {
      const { modifyDevice } = getters.getHttps;
      const result = await modifyDevice(params, {
        custom: {
          isFormData: true,
        },
      });
      return storeResponse(result);
    },
    // 获取设备分页列表
    async fetchGetDevicePageList({ getters }, params) {
      const { getDevicePageList } = getters.getHttps;
      const result = await getDevicePageList(params);
      return storeResponse(result);
    },
    // 获取设备示意图分页列表
    async fetchGetDeviceCoverImagePageList({ getters }, params) {
      const { getDeviceCoverImagePageList } = getters.getHttps;
      const result = await getDeviceCoverImagePageList(params);
      return storeResponse(result);
    },
    // 获取设备详情
    async fetchGetDeviceDetails({ getters }, params) {
      const { getDeviceDetails } = getters.getHttps;
      const result = await getDeviceDetails(params);
      return storeResponse(result);
    },
    // 上传设备固件
    async fetchUploadFirmware({ getters }, params) {
      const { uploadFirmware } = getters.getHttps;
      const result = await uploadFirmware(params);
      return storeResponse(result);
    },
    // 上传设备固件
    async fetchDownloadFirmware({ getters }, params) {
      const { downloadFirmware } = getters.getHttps;

      const result = await downloadFirmware(params, {
        custom: {
          isBlob: true,
          showTips: false,
        },
      });

      return result;
    },
    // 获取设备固件分页列表
    async fetchGetDeviceFirmwarePageList({ getters }, params) {
      const { getDeviceFirmwarePageList } = getters.getHttps;
      const result = await getDeviceFirmwarePageList(params);
      return storeResponse(result);
    },
    // 修改设备固件备注
    async fetchModifyDeviceFirmwareNote({ getters }, params) {
      const { modifyDeviceFirmwareNote } = getters.getHttps;
      const result = await modifyDeviceFirmwareNote(params);
      return storeResponse(result);
    },

    /* 设备型号日志管理 */
    // 获取设备操作日志分页列表
    async fetchGetDeviceOperationLogPageList({ getters }, params) {
      const { getDeviceOperationLogPageList } = getters.getHttps;
      const result = await getDeviceOperationLogPageList(params);
      return storeResponse(result);
    },
  },
};
