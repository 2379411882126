// 用户管理
export default [
  /*设备管理 */
  // 添加设备
  {
    name: "addDevice",
    url: "/device/addDevice",
    method: "post",
  },
  // 修改设备
  {
    name: "modifyDevice",
    url: "/device/modifyDevice",
    method: "put",
  },
  // 获取设备分页列表
  {
    name: "getDevicePageList",
    url: "/device/getDevicePageList",
    method: "get",
  },
  // 获取设备示意图分页列表
  {
    name: "getDeviceCoverImagePageList",
    url: "/device/getDeviceCoverImagePageList",
    method: "get",
  },
  // 获取设备详情
  {
    name: "getDeviceDetails",
    url: "/device/getDeviceDetails",
    method: "get",
  },
  // 上传设备固件
  {
    name: "uploadFirmware",
    url: "/device/firmware/uploadFirmware",
    method: "post",
  },
  // 下载设备固件
  {
    name: "downloadFirmware",
    url: "/device/firmware/downloadFirmware",
    method: "get",
  },
  //获取设备固件分页列表
  {
    name: "getDeviceFirmwarePageList",
    url: "/device/firmware/getDeviceFirmwarePageList",
    method: "get",
  },
  //修改设备固件备注
  {
    name: "modifyDeviceFirmwareNote",
    url: "/device/firmware/modifyDeviceFirmwareNote",
    method: "put",
  },

  /* 设备型号日志管理 */
  // 获取设备操作日志分页列表
  {
    name: "getDeviceOperationLogPageList",
    url: "/device/operation/log/getDeviceOperationLogPageList",
    method: "get",
  },
];
