const isEmpty = ["", undefined, null];

// 验证手机号
export const testPhone = (value) => {
  const reg = /^1[3456789]\d{9}$/;
  return reg.test(value);
};

// 验证密码
export const testPwd = (value) => {
  const reg = /^[0-9a-zA-Z]{6,20}$/;
  return reg.test(value);
};

// 字母或数字
export const testLettersOrNumbers = (value) => {
  const reg = /^[0-9A-Za-z]{1,15}$/;
  return reg.test(value);
};

// 验证正整数
export const testPositiveInteger = (value) => {
  if (isEmpty.includes(value)) {
    return false;
  }
  const reg = /^[+]{0,1}(\d+)$/;
  return reg.test(value);
};

// 验证 正数含小数点
export const testPositiveNumberFloat = (value) => {
  if (isEmpty.includes(value)) {
    return false;
  }
  const reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
  return reg.test(value);
};

// 验证非负数数字
export const testPositiveNumber = (value) => {
  if (isEmpty.includes(value)) {
    return false;
  }
  const reg = /^([1-9][0-9]*|0)$/;
  return reg.test(value);
};
//正整数 不含0
export const testNormalIntegerNumber = (value) => {
  if (isEmpty.includes(value)) {
    return false;
  }
  const reg = new RegExp("^[0-9]*[1-9][0-9]*$");
  return reg.test(value);
};

//正则校验 中文英文数字混合
export const testNormalCharacterMix = (value) => {
  if (isEmpty.includes(value)) {
    return false;
  }
  const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/;
  return reg.test(value);
};

// 版本号正则
export const versionNumber = (value) => {
  if (isEmpty.includes(value)) {
    return false;
  }
  const reg = /^([0-9]\d|[0-9])(\.([0-9]){1,2}){1,2}$/;
  return reg.test(value);
};

// //校验上传文件名 GBMS000000v0.0.1
// export const testVersionUploadFileName = (value) => {
//   if (isEmpty.includes(value)) {
//     return false;
//   }
//   const reg = /^GBMS\d{6}v([0-9]\d|[0-9])(\.([0-9]){1,2}){1,2}$/;
//   return reg.test(value);
// };
