<template>
  <el-button
    :disabled="useDisabled"
    :size="size || 'default'"
    :type="type"
    :loading="showLoading"
    @click="submit"
    class="ml-buttom"
  >
    <slot />
  </el-button>
</template>

<script setup>
import { ref, getCurrentInstance, computed, onMounted } from "vue";
const props = defineProps({
  size: {
    type: String,
    default: "default",
  },
  type: {
    type: String,
    default: "primary",
  },
  // 使用加载
  useLoading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // 节流 延迟
  bypassDelay: {
    type: Number,
    default: 500,
  },
});

const { proxy } = getCurrentInstance();

/* 属性 */
const useDisabled = computed(() => props.disabled);

// 加载状态
const showLoading = ref(false);

const emits = defineEmits(["submit", "handleClick"]);

const submit = proxy.$bypass(() => {
  // 使用
  if (props.useLoading) {
    if (showLoading.value) {
      return;
    }

    showLoading.value = true;

    emits("submit", () => {
      showLoading.value = false;
    });
  } else {
    emits("handleClick");
  }
}, props.bypassDelay);
</script>

<style lang="scss">
.ml-buttom {
  &.el-button {
    &--primary {
    }
  }
}
</style>
