<template>
	<div class="tag-box">
		<div class="label" :class="{bold}">
			<slot></slot>
		</div>
		<div class="tips" v-if="showTips">
			<el-tooltip placement="top" :content="tipsText">
				<el-image class="tips-icon" fit="cover" :src="tipsIcon"></el-image>
			</el-tooltip>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	showTips: {
		type: Boolean,
		default: false,
	},
	tipsText: {
		type: String,
		default: "",
	},
  bold:{
		type: Boolean,
		default:false
  }
});

const tipsIcon = require("@/assets/images/tips.png");
</script>

<style lang="scss" scoped>
.tag-box {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

  .label{
		color:#333333;
    &.bold{
      font-weight: bold;
    }
  }

	.tips {
		width: 15px;
		height: 15px;
		margin-left: 10px;

		.el-image {
			width: 100%;
			height: 100%;
			vertical-align: super;
		}
	}
}
</style>